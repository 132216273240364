import React, { useEffect, useRef, useState } from 'react';
import style from './style.module.scss';
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import {
  httpClient_get,
  httpClient_post,
} from '../../../helpers/api';
import { default as Loader } from '../../../components/Loader';
import { useDispatch } from 'react-redux';
import { reactFormatter } from 'react-tabulator';
import TableList, { formatDate } from '../../../components/TableList';
import { Dropdown, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { activeRestaurantService, desactiveRestaurantService, disableFreeTailRestaurantService } from '../../../redux/PointOfSale/service';

export default function Spots({}) {
  const [loading, setLoading] = useState(false);
  const [spots, setSpots] = useState([]);

  const [loadData, setLoadData] = useState(false);

  useEffect(() => {
    setLoading(true);
    httpClient_get(`/spots`)
      .then((response) => {
        setSpots(response?.data?.spots);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [loadData]);

  return (
    <div className={style.container}>
      {loading && <Loader />}

      <Row>
        <Col>
          <Card>
            <CardBody className="p-0">
              <DataTable
                pointOfSales={spots}
                loadData={loadData}
                setLoadData={setLoadData}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const DataTable = ({ pointOfSales, loadData, setLoadData }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [restourant, setRestourant] = useState({});
  const [showInfo, setShowInfo] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showQrCodeData, setShowQrCodeData] = useState(false);
  const [showQrCodeForm, setShowQrCodeForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const Menu = ({ cell, setShowQrCodeData }) => {
    const rowData = cell._cell.row.data;

    if (cell._cell.element)
      cell._cell.element.style.overflow = 'visible';

    return (
      <Dropdown drop={'left'}>
        <Dropdown.Toggle className="btn-not-background">
          <i className=" fas fa-cogs"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setShowQrCodeForm(true);
              setShowQrCodeData(rowData);
            }}
            eventKey="1"
          >
            <i className=" fas fa-qrcode"></i> Qr Code affectation
          </Dropdown.Item>


{
    rowData?.verified ?


          <Dropdown.Item
            onClick={async () => {
              try {
                setLoading(true);
                await desactiveRestaurantService(rowData?._id);
                setLoading(false);
              } catch (e) {
                setLoading(false);
              }
            }}
            eventKey="1"
          >
            <i className=" fas fa-check-square"></i> Desactiver la restaurant
          </Dropdown.Item> :
          <Dropdown.Item
            onClick={async () => {
              try {
                setLoading(true);
                await activeRestaurantService(rowData?._id);
                setLoading(false);
              } catch (e) {
                setLoading(false);
              }
            }}
            eventKey="1"
          >
            <i className=" fas fa-check-square"></i> Activer la restaurant
          </Dropdown.Item> 


}

{
    
    rowData?.isFreeTrail &&  rowData?.verified &&
          <Dropdown.Item
            onClick={async () => {
              try {
                setLoading(true);
                await disableFreeTailRestaurantService(rowData?._id);
                setLoading(false);
              } catch (e) {
                setLoading(false);
              }
            }}
            eventKey="1"
          >
            <i className=" fas fa-check-square"></i> désactiver l'essai gratuit
          </Dropdown.Item>
}

        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const ref_tabl = useRef({});

  const columns = [
    {
      title: '',
      field: 'title',
      width: 300,
      resizable: false,
      hozAlign: 'left',
      headerHozAlign: 'left',
      headerFilter: 'input',
      headerFilterPlaceholder: 'Enseigne',
      type: 'Enseigne',
      frozen: true,
      formatter: (cell) => {
        const value = cell.getValue();
        return capitalize(value);
      },
    },
    {
      title: '',
      field: 'phone',
      width: 200,
      headerHozAlign: 'left',
      headerFilter: 'input',
      headerFilterPlaceholder: 'Phone',
      type: 'Phone',
      cellClick: function (e, cell) {
        window.open(`tel:${cell.getValue()}`);
      },
      formatter: (cell) => {
        const value = cell.getValue();
        return capitalize(value);
      },
    },
    {
      title: '',
      field: 'email',
      width: 200,
      headerHozAlign: 'left',
      headerFilter: 'input',
      headerFilterPlaceholder: 'Email',
      type: 'Email',
      formatter: (cell) => {
        const value = cell.getValue();
        return capitalize(value);
      },
    },
    {
      title: '',
      field: 'category',
      width: 200,
      headerHozAlign: 'left',
      headerFilter: 'input',
      headerFilterPlaceholder: 'category',
      type: 'Category',
      formatter: (cell) => {
        const value = cell.getValue();
        return capitalize(value);
      },
    },
    {
      title: '',
      field: 'country',
      width: 200,
      headerHozAlign: 'left',
      headerFilter: 'input',
      headerFilterPlaceholder: 'country',
      type: 'Country',
      formatter: (cell) => {
        const value = cell.getValue();
        return capitalize(value);
      },
    },
    {
      title: '',
      field: 'city',
      width: 200,
      headerHozAlign: 'left',
      headerFilter: 'input',
      headerFilterPlaceholder: 'city',
      type: 'City',
      formatter: (cell) => {
        const value = cell.getValue();
        return capitalize(value);
      },
    },

    {
      title: '',
      field: 'createdAt',
      width: 200,
      formatter: formatDate,
      headerHozAlign: 'left',
      headerFilter: 'input',
      headerFilterPlaceholder: 'Create',
      type: 'Create',
    },

    {
      title: 'action',
      field: '',
      width: 86,
      formatter: reactFormatter(
        <Menu setShowQrCodeData={setShowQrCodeData} />,
      ),
      headerHozAlign: 'left',
      frozen: true,
    },
  ];

  const capitalize = (s) =>
    s && s[0].toUpperCase() + s.slice(1).toLowerCase();

  const ShowQrCodeAffectation = ({
    show,
    handleClose,
    onEdite,
    showQrCodeData = {},
  }) => {
    const dispatch = useDispatch();
    const handleClose_ = () => handleClose(false);
    const [qrCodes, setQrCodes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(null);

    useEffect(() => {
      if (show) {
        if (showQrCodeData?.qrCodeSpot)
          setValue({
            value: showQrCodeData?.qrCodeSpot._id,
            label: `${showQrCodeData?.qrCodeSpot.number} - ${showQrCodeData?.qrCodeSpot.hash}`,
          });

        setLoading(true);
        httpClient_get(`/openflow-spots/qr-codes-blank`)
          .then((response) => {
            setQrCodes(response?.data?.qrCodes);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    }, [show]);

    return (
      <Modal show={show} onHide={handleClose_}>
        {loading && <Loader />}

        <Modal.Header closeButton>
          <Modal.Title>Spot {showQrCodeData?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="addUSerF">
            <FormGroup className="col-md-9">
              <Label for="exampleEmail"> Qr Code Spot :</Label>

              <Select
                options={qrCodes?.map((item) => {
                  return {
                    value: item._id,
                    label: `${item.number} - ${item.hash}`,
                  };
                })}
                name="productDelivery"
                className="basic-multi-select"
                classNamePrefix="select"
                value={value}
                onChange={(val) => {
                  setValue(val);
                }}
              />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              if (value === null) alert('select qr code is required');
              else {
                setLoading(true);
                httpClient_post(`/openflow-spots/set-qr-codes`, {
                  spot: showQrCodeData?._id,
                  qrCode: value.value,
                })
                  .then((response) => {
                    setQrCodes(response?.data?.qrCodes);
                    setLoading(false);
                    handleClose(false);

                    setLoadData(!loadData);
                  })
                  .catch((error) => {
                    setLoading(false);
                  });
              }
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      {loading && <Loader />}
      {showQrCodeForm && (
        <ShowQrCodeAffectation
          show={showQrCodeForm}
          handleClose={() => {
            setShowQrCodeForm(false);
          }}
          onEdite={() => {}}
          showQrCodeData={showQrCodeData}
        />
      )}

      <div className="row w-100">
        <div
          className="col mb-3 col-12 text-center"
          style={{ height: 'calc(100vh - 270px)' }}
        >
          <TableList
            ref={ref_tabl}
            columns_p={columns}
            data_p={pointOfSales}
          />
        </div>
      </div>
    </>
  );
};
