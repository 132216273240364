// @flow
import {
  ADD_DEVICES_TO_RESUTRANT,
  ADD_DEVICES_TO_RESUTRANT_SUCCESS,
  CALLRESUTRANTDEVICES,
  GET_DEVICES_NOT_RESUTRANT,
  GET_DEVICES_NOT_RESUTRANT_SUCCESS,
  SETRESTURANTDEVICES,
  AFFECTATION_MENU_DEVICE,
  AFFECTATION_MENU_DEVICE_SUCCESS,
  AFFECTATION_MENU_DEVICE_FAILED,
} from '../../constants/actionTypes';

const initialState = {
  Devices: [],
  loading: false,
};

const DevicesReducers = (state = initialState, action) => {
  let devices;
  switch (action.type) {
    case CALLRESUTRANTDEVICES:
      return { ...state, loading: true };
    case SETRESTURANTDEVICES:
      return { ...state, Devices: action.payload, loading: false };

    case GET_DEVICES_NOT_RESUTRANT:
      return { ...state, loading: true };
    case GET_DEVICES_NOT_RESUTRANT_SUCCESS:
      return { ...state, Devices: action.payload, loading: false };

    case ADD_DEVICES_TO_RESUTRANT:
      return { ...state, loading: true };
    case AFFECTATION_MENU_DEVICE:
      return { ...state, loading: true };
    case AFFECTATION_MENU_DEVICE_SUCCESS:
      devices = state.Devices.filter(
        (d) => d._id !== action.payload._id,
      );
      return {
        ...state,
        Devices: [...devices, action.payload],
        loading: false,
      };

    case AFFECTATION_MENU_DEVICE_FAILED:
      return { ...state, loading: true };

    case ADD_DEVICES_TO_RESUTRANT_SUCCESS:
      const list = state.Devices.filter((value, index) => {
        return value._id !== action.payload._id;
      });

      return { ...state, Devices: list, loading: false };

    default:
      return state;
  }
};

export default DevicesReducers;
