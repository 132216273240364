import { httpClient_get, httpClient_post } from '../../helpers/api';

const getAffiliationIdService = (pointOfSaleId) => {
  return httpClient_post(`/pointofsale/affiliation-id`, {
    pointOfSaleId,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export { getAffiliationIdService };
