import QRCode from 'qrcode.react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Container, Modal, Tab, Tabs } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';

import profile from '../../src/assets/images/profile.jpg';
import avatarAdmin from '../assets/images/1.png';
import profilePic from '../assets/images/profile.jpg';
import Can from '../components/Can';
import { baseURL, httpClient_put } from '../helpers/api';
import { i18n } from '../helpers/i18n';
import {
  loginUserSuccess,
  UserUpdateSuccess,
} from '../redux/actions';
import Loader from './Loader';

const ModalQr = ({ setShow = () => null, show = false, id }) => {
  const downloadQR = () => {
    const cln = document.getElementById('123456');
    var canvas = cloneCanvas(cln, 400, 400);

    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = '123456.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const cloneCanvas = (oldCanvas, width = 400, height = 400) => {
    //create a new canvas
    var newCanvas = document.createElement('canvas');
    var context = newCanvas.getContext('2d');

    //set dimensions
    newCanvas.width = width;
    newCanvas.height = height;

    //apply the old canvas to the new one
    context.drawImage(oldCanvas, 0, 0);

    //return the new canvas
    return newCanvas;
  };

  // useEffect(() => {
  //   console.log('------', id);
  // }, [id]);

  // console.log('------', id);

  return (
    <Modal
      dialogClassName="my-ReservationModal"
      size="medium"
      show={show}
      onHide={() => {
        setShow(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Container>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <QRCode
              id="123456"
              value={`${window.location.origin}/book-table-client/restaurant/menu/${id}`}
              className="thumb-img img-fluid"
              size={200}
              level={'L'}
              includeMargin={true}
              style={{
                margin: '0 auto',
                display: 'block',
              }}
            />
            <div
              className="  toolsMenu"
              onClick={downloadQR}
              style={{
                // flex: 1,
                width: '100px',
                paddingBottom: 10,
                margin: '0px auto',
              }}
            >
              <i
                className="fas fa-download icon"
                style={{
                  fontSize: '2rem',
                }}
              ></i>
            </div>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};



const ModalUserProfile = ({
  setShow = () => null,
  show = false,
  user,
}) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const [img, setImg] = useState(() => {
    if (user.avatar) {
      console.log('user', user);
      return baseURL + '/' + user.avatar.path;
    }

    return profilePic;
  });
  const [avatar, setAvatar] = useState();

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
  } = useForm();

  const onSubmit = (data) => {
    console.log('data', data);
    setLoading(true);
    // return ;
    const formData = new FormData();

    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('phone', data.phone);

    if (avatar) formData.append('avatar', avatar);

    httpClient_put('/users/update-user', formData)
      .then((response) => {
        console.log('response', response);
        const {
          firstName,
          lastName,
          phone,
          avatar,
        } = response.data.user;
        setLoading(false);
        console.log(
          'firstName,lastName,phone,avatar,',
          firstName,
          lastName,
          phone,
          avatar,
        );
        dispatch(
          UserUpdateSuccess({
            firstName,
            lastName,
            phone,
            avatar,
          }),
        );

        setShow(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log('err', err);
      });

    //
  };

  const { getInputProps, getRootProps } = useDropzone({
    onDrop: (files) => {
      const file = files[0];
      if (!file) return;
      setAvatar(files[0]);
      const reader = new FileReader();
      reader.onload = (event) => {
        setImg(event.target.result);
      };
      reader.readAsDataURL(file);
      setError(false);
    },
    accept: '.jpeg,.jpg,.png',
    multiple: false,
    minSize: 0,
    maxSize: 5242880,
    onDropRejected: (err) => {
      console.log('err', err);
      swal(err[0]?.errors[0]?.message || 'error upload image', {
        icon: 'error',
      });
      setError(true);
    },
  });

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        setShow(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {loading && <Loader />}
      <Modal.Body>
        <Container>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              // textAlign: 'center',
            }}
          >
            <Row>
              <Col>
                <Row
                  style={{ justifyContent: 'center' }}
                  className="mb-2"
                >
                  <div
                    className="form-group"
                    style={{ height: ' 6rem', display: 'flex' }}
                  >
                    <img
                      src={img || profilePic}
                      className="rounded-circle avatar-xl img-thumbnail float-left"
                      alt="user-img"
                    />

                    <div {...getRootProps()} className="dropzone-btn">
                      <input {...getInputProps()} />

                      <button
                        className="btn btn-icon waves-effect btn-secondary"
                        style={{}}
                      >
                        <i className="fas fa-edit"></i>
                      </button>
                    </div>
                  </div>
                </Row>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col>
                      <div className="form-group">
                        <label htmlFor="firstName">Prénom</label>
                        <input
                          type="text"
                          name="firstName"
                          defaultValue={user.firstName}
                          className="form-control"
                          ref={register}
                        />
                        {/* <ErrorMessage errors={errors} name="firstName" /> */}
                      </div>

                      <div className="form-group">
                        <label htmlFor="emailAddress">
                          {' '}
                          Adresse e-mail
                        </label>
                        <input
                          type="email"
                          name="email"
                          disabled
                          defaultValue={user.email}
                          className="form-control"
                          ref={register}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <label htmlFor="lastName">
                          {' '}
                          Nom de famille
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          defaultValue={user.lastName}
                          className="form-control"
                          ref={register}
                        />
                      </div>

                      <FormGroup>
                        <Label for="examplePassword">
                          Numéro de téléphone:
                        </Label>

                        <Controller
                          control={control}
                          name="phone"
                          render={({ onChange, onBlur, value }) => (
                            <PhoneInput
                              // className="form-control "
                              enableSearch
                              inputClass={'widthFull'}
                              country={'us'}
                              preferredCountries={['ma', 'us', 'fr']}
                              value={user.phone}
                              onChange={onChange}
                            />
                          )}
                          defaultValue={user.phone}
                        />

                        <FormFeedback
                          {...(errors.phone
                            ? { invalid: 'true' }
                            : {})}
                        >
                          {errors.phone && 'Phone is required'}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="form-group text-right mb-0">
                    <button
                      type="reset"
                      className="btn btn-secondary waves-effect waves-light mr-1"
                      onClick={() => setShow(false)}
                    >
                      cancel
                    </button>
                    <button
                      className="btn btn-primary waves-effect waves-light "
                      type="submit"
                    >
                      submit
                    </button>
                  </div>
                </form>
              </Col>
            </Row>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

const Staf = ({ user }) => {
  const selectedPointOfSale = useSelector(
    (state) => state.Supervisor.selectedPointOfSale,
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const user = useSelector((state) => state.Auth.user);

  const [showProfileModal, setShowProfileModal] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <>
      <ModalUserProfile
        show={showProfileModal}
        setShow={setShowProfileModal}
        user={user}
      />
      <DropdownToggle
        data-toggle="dropdown"
        tag="button"
        className="btn btn-link nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
        onClick={toggleDropdown}
        aria-expanded={dropdownOpen}
      >
        {/* <span className="pro-user-name ml-1">
          {`${user.firstName} ${user.lastName}`}{' '}
          <i className="mdi mdi-chevron-down"></i>
        </span> */}
        <img
          className="rounded-circle img-thumbnail "
          style={{ width: '60px', height: '60px' }}
          src={
            user.avatar ? baseURL + '/' + user.avatar.path : profile
          }
        />
      </DropdownToggle>
      <DropdownMenu right className="profile-dropdown">
        <div onClick={toggleDropdown}>
          <div className="dropdown-header noti-title">
            <h6 className="text-overflow m-0">
              {i18n.t('ProfileDropdown.welecome')} !
            </h6>
          </div>

          <React.Fragment key={'-profile-menu'}>
            <DropdownItem divider />
            <Link
              to={'/logout'}
              className="dropdown-item notify-item"
            >
              <i className={`fe-log-out mr-1`}></i>
              <span>{i18n.t('ProfileDropdown.logout')}</span>
            </Link>
          </React.Fragment>
        </div>
      </DropdownMenu>
      <DropdownMenu right className="profile-dropdown">
        <div onClick={toggleDropdown}>
          <div className="dropdown-header noti-title">
            <h6 className="text-overflow m-0">
              {i18n.t('ProfileDropdown.welecome')} !
            </h6>
          </div>

          <React.Fragment key={'-profile-menu'}>
            <span
              onClick={() => setShowProfileModal((prv) => !prv)}
              className="dropdown-item notify-item"
            >
              <i className={` mdi mdi-account mr-1`}></i>
              <span>{i18n.t('ProfileDropdown.profile')}</span>
            </span>
            <DropdownItem divider />
            <Link
              to={'/logout'}
              className="dropdown-item notify-item"
            >
              <i className={`fe-log-out mr-1`}></i>
              <span>{i18n.t('ProfileDropdown.logout')}</span>
            </Link>
          </React.Fragment>
        </div>
      </DropdownMenu>
    </>
  );
};

const SuperVis = ({ user, menuItems }) => {
  const selectedPointOfSale = useSelector(
    (state) => state.Supervisor.selectedPointOfSale,
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  // const user = useSelector((state) => state.Auth.user);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  console.log('======user======>>>', user._id);
  // const profilePic = props.profilePic || null;

  if (selectedPointOfSale) {
    return (
      <>
        <ModalQr
          show={showModal}
          setShow={setShowModal}
          id={user._id}
        />
        <ModalUserProfile
          show={showProfileModal}
          setShow={setShowProfileModal}
          user={user}
        />
        <DropdownToggle
          data-toggle="dropdown"
          tag="button"
          className="btn btn-link nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
          onClick={toggleDropdown}
          aria-expanded={dropdownOpen}
        >
          {/* <img
            src={baseURL + '/' + selectedPointOfSale.logo?.path}
            alt="user-img"
            title={user.ownedPointOfSales?.title}
            className="rounded-circle img-thumbnail avatar-lg"
            style={{ width: '55px', height: '55px' }}
          /> */}
          <img
            className="rounded-circle img-thumbnail "
            style={{ width: '60px', height: '60px' }}
            src={
              user.avatar ? baseURL + '/' + user.avatar.path : profile
            }
          />
          {/* <img
            className="rounded-circle img-thumbnail avatar-lg"
            src={profile}
          /> */}
          {/* <span className="pro-user-name ml-1">
            {selectedPointOfSale?.title}
            <i className="mdi mdi-chevron-down"></i>
          </span> */}
        </DropdownToggle>
        <DropdownMenu right className="profile-dropdown">
          <div onClick={toggleDropdown}>
            <div className="dropdown-header noti-title">
              <h6 className="text-overflow m-0">
                {i18n.t('ProfileDropdown.welecome')} !
              </h6>
            </div>

            <React.Fragment key={'-profile-menu'}>
              <span
                onClick={() => setShowModal((prv) => !prv)}
                className="dropdown-item notify-item"
              >
                <i className={` fas fa-qrcode mr-1`}></i>
                <span>QR</span>
              </span>
              <span
                onClick={() => setShowProfileModal((prv) => !prv)}
                className="dropdown-item notify-item"
              >
                <i className={` mdi mdi-account mr-1`}></i>
                <span>{i18n.t('ProfileDropdown.profile')}</span>
              </span>

              <Link
                to={'/supervisor/Settings'}
                className="dropdown-item notify-item"
              >
                <i className={`fe-settings mr-1`}></i>
                <span>{i18n.t('ProfileDropdown.settings')}</span>
              </Link>
              <DropdownItem divider />
              <Link
                to={'/logout'}
                className="dropdown-item notify-item"
              >
                <i className={`fe-log-out mr-1`}></i>
                <span>{i18n.t('ProfileDropdown.logout')}</span>
              </Link>
            </React.Fragment>
          </div>
        </DropdownMenu>
      </>
    );
  }

  return (
    <>
      <ModalQr
        show={showModal}
        setShow={setShowModal}
        id={user._id}
      />

      <ModalUserProfile
        show={showProfileModal}
        setShow={setShowProfileModal}
        user={user}
      />


      <DropdownToggle
        data-toggle="dropdown"
        tag="button"
        className="btn btn-link nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
        onClick={toggleDropdown}
        aria-expanded={dropdownOpen}
      >
        {/* <span className="pro-user-name ml-1">
          {`${user.firstName} ${user.lastName}`}{' '}
          <i className="mdi mdi-chevron-down"></i>
        </span> */}
        <img
          className="rounded-circle img-thumbnail "
          style={{ width: '60px', height: '60px' }}
          src={
            user.avatar ? baseURL + '/' + user.avatar.path : profile
          }
        />
      </DropdownToggle>
      <DropdownMenu right className="profile-dropdown">
        <div onClick={toggleDropdown}>
          <div className="dropdown-header noti-title">
            <h6 className="text-overflow m-0">
              {i18n.t('ProfileDropdown.welecome')} !
            </h6>
          </div>

          <React.Fragment key={'-profile-menu'}>
            <DropdownItem divider />
            <Link
              to={'/logout'}
              className="dropdown-item notify-item"
            >
              <i className={`fe-log-out mr-1`}></i>
              <span>{i18n.t('ProfileDropdown.logout')}</span>
            </Link>
          </React.Fragment>
        </div>
      </DropdownMenu>
      <DropdownMenu right className="profile-dropdown">
        <div onClick={toggleDropdown}>
          <div className="dropdown-header noti-title">
            <h6 className="text-overflow m-0">
              {i18n.t('ProfileDropdown.welecome')} !
            </h6>
          </div>

          <React.Fragment key={'-profile-menu'}>
            <span
              onClick={() => setShowModal((prv) => !prv)}
              className="dropdown-item notify-item"
            >
              <i className={` fas fa-qrcode  mr-1`}></i>
              <span>QR</span>
            </span>

            <span
              onClick={() => setShowProfileModal((prv) => !prv)}
              className="dropdown-item notify-item"
            >
              <i className={` mdi mdi-account mr-1`}></i>
              <span>{i18n.t('ProfileDropdown.profile')}</span>
            </span>
            {user.typeAccount === 'hotel' && (
              <span
                onClick={() => setShowSettingsModal((prv) => !prv)}
                className="dropdown-item notify-item"
              >
                <i className={`fe-settings mr-1`}></i>
                <span>{i18n.t('ProfileDropdown.settings')}</span>
              </span>
            )}

            <DropdownItem divider />
            <Link
              to={'/logout'}
              className="dropdown-item notify-item"
            >
              <i className={`fe-log-out mr-1`}></i>
              <span>{i18n.t('ProfileDropdown.logout')}</span>
            </Link>
          </React.Fragment>
        </div>
      </DropdownMenu>
    </>
  );
};

const Admin = ({ user }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const user = useSelector((state) => state.Auth.user);

  const [showProfileModal, setShowProfileModal] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <>
      <ModalUserProfile
        show={showProfileModal}
        setShow={setShowProfileModal}
        user={user}
      />
      <DropdownToggle
        data-toggle="dropdown"
        tag="button"
        className="btn btn-link nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
        onClick={toggleDropdown}
        aria-expanded={dropdownOpen}
      >
        {/* <img
          src={baseURL + '/' + user.ownedPointOfSales?.logo?.path}
          alt="user-img"
          title={user.ownedPointOfSales?.title}
          className="rounded-circle img-thumbnail avatar-lg"
          style={{ width: '55px', height: '55px' }}
        /> */}
        {/* <span className="pro-user-name ml-1">
          {user.ownedPointOfSales?.title}{' '}
          <i className="mdi mdi-chevron-down"></i>
        </span> */}
        <img
          className="rounded-circle img-thumbnail "
          style={{ width: '60px', height: '60px' }}
          src={
            user.avatar ? baseURL + '/' + user.avatar.path : profile
          }
        />
      </DropdownToggle>
      <DropdownMenu right className="profile-dropdown">
        <div onClick={toggleDropdown}>
          <div className="dropdown-header noti-title">
            <h6 className="text-overflow m-0">
              {i18n.t('ProfileDropdown.welecome')} !
            </h6>
          </div>

          <React.Fragment key={'-profile-menu'}>
            <span
              onClick={() => setShowProfileModal((prv) => !prv)}
              className="dropdown-item notify-item"
            >
              <i className={` mdi mdi-account mr-1`}></i>
              <span>{i18n.t('ProfileDropdown.profile')}</span>
            </span>
            <Link
              to={'/Settings'}
              className="dropdown-item notify-item"
            >
              <i className={`fe-settings mr-1`}></i>
              <span>{i18n.t('ProfileDropdown.settings')}</span>
            </Link>

            <DropdownItem divider />
            <Link
              to={'/logout'}
              className="dropdown-item notify-item"
            >
              <i className={`fe-log-out mr-1`}></i>
              <span>{i18n.t('ProfileDropdown.logout')}</span>
            </Link>
          </React.Fragment>
        </div>
      </DropdownMenu>
    </>
  );
};
const SupAdmin = ({ user }) => {
  const selectedPointOfSale = useSelector(
    (state) => state.Supervisor.selectedPointOfSale,
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const user = useSelector((state) => state.Auth.user);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <>
      <DropdownToggle
        data-toggle="dropdown"
        tag="button"
        className="btn btn-link nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
        onClick={toggleDropdown}
        aria-expanded={dropdownOpen}
      >
        {/* <span className="pro-user-name ml-1">
          {`${user.email}`}
          <i className="mdi mdi-chevron-down"></i>
        </span> */}
        <img
          className="rounded-circle img-thumbnail "
          style={{
            width: '60px',
            height: '60px',
            objectFit: 'contain',
          }}
          src={avatarAdmin}
        />
      </DropdownToggle>
      <DropdownMenu right className="profile-dropdown">
        <div onClick={toggleDropdown}>
          <div className="dropdown-header noti-title">
            <h6 className="text-overflow m-0">
              {i18n.t('ProfileDropdown.welecome')} !
            </h6>
          </div>

          <React.Fragment key={'-profile-menu'}>
            <DropdownItem divider />
            <Link
              to={'/logout'}
              className="dropdown-item notify-item"
            >
              <i className={`fe-log-out mr-1`}></i>
              <span>{i18n.t('ProfileDropdown.logout')}</span>
            </Link>
          </React.Fragment>
        </div>
      </DropdownMenu>
      <DropdownMenu right className="profile-dropdown">
        <div onClick={toggleDropdown}>
          <div className="dropdown-header noti-title">
            <h6 className="text-overflow m-0">
              {i18n.t('ProfileDropdown.welecome')} !
            </h6>
          </div>



          <React.Fragment key={'-profile-menu'}>
            <DropdownItem divider />
            <Link
              to={'/logout'}
              className="dropdown-item notify-item"
            >
              <i className={`fe-log-out mr-1`}></i>
              <span>Logout</span>
            </Link>
          </React.Fragment>
        </div>
      </DropdownMenu>
    </>
  );
};

const ProfileDropdown = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const user = useSelector((state) => state.Auth.user);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const profilePic = props.profilePic || null;

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className="notification-list"
    >
      {user.role === 'ROLE_SUPERVISOR' ? (
        <SuperVis user={user} menuItems={props.menuItems} />
      ) : user.role === 'ROLE_ADMIN' ? (
        <Admin user={user} />
      ) : user.role === 'ROLE_SUPER_ADMIN' ? (
        <SupAdmin user={user} />
      ) : (
        <Staf user={user} />
      )}
    </Dropdown>
  );
};

export default ProfileDropdown;
