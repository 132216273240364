// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  GET_POINT_OF_SALE_CONTACT,
  ADD_POINT_OF_SALE_CONTACT,
  EDIT_POINT_OF_SALE_CONTACT,
} from '../../constants/actionTypes';
import {
  getPointOfSaleCompanySuccess,
  getPointOfSaleCompanyFailed,
  addPointOfSaleCompanySuccess,
  addPointOfSaleCompanyFailed,
  editPointOfSaleCompanySuccess,
  editPointOfSaleCompanyFailed,
} from './actions';
import {
  getPointOfSaleCompanyService,
  addPointOfSaleCompanyService,
  editPointOfSaleCompanyService,
} from './service';

function* allContacts({ payload: { pointOfSaleId } }) {
  try {
    console.log('----allContacts---');
    const response = yield call(getPointOfSaleCompanyService, {
      pointOfSaleId,
    });
    yield put(getPointOfSaleCompanySuccess(response?.contacts || []));
  } catch (error) {
    yield put(getPointOfSaleCompanyFailed(error));
  }
}

function* addContact({
  payload: {
    pointOfSaleId,
    email,
    phone,
    role,
    companyName,
    website,
    address,
    rc,
    ice,
    client,
    callback,
  },
}) {
  try {
    const response = yield call(addPointOfSaleCompanyService, {
      pointOfSaleId,
      email,
      phone,
      role,
      companyName,
      website,
      address,
      client,
      rc,
      ice,
    });
    yield put(addPointOfSaleCompanySuccess(response?.contact));
    callback();
  } catch (error) {
    yield put(addPointOfSaleCompanyFailed(error));
  }
}

function* editContact({
  payload: {
    id,
    email,
    phone,
    role,
    companyName,
    website,
    address,
    client,
    rc,
    ice,
    callback,
  },
}) {
  try {
    const response = yield call(editPointOfSaleCompanyService, {
      id,
      email,
      phone,
      role,
      companyName,
      website,
      address,
      client,
      rc,
      ice,
    });
    yield put(editPointOfSaleCompanySuccess(response?.contact));
    callback();
  } catch (error) {
    yield put(editPointOfSaleCompanyFailed(error));
  }
}

export function* watchEditContact() {
  yield takeEvery(EDIT_POINT_OF_SALE_CONTACT, editContact);
}

export function* watchAllContact() {
  yield takeEvery(GET_POINT_OF_SALE_CONTACT, allContacts);
}

export function* watchAddContact() {
  yield takeEvery(ADD_POINT_OF_SALE_CONTACT, addContact);
}

function* PointOfSaleCompanySaga() {
  yield all([
    fork(watchAllContact),
    fork(watchAddContact),
    fork(watchEditContact),
  ]);
}

export default PointOfSaleCompanySaga;
