import {
  httpClient_get,
  httpClient_post,
  httpClient_put,
} from '../../helpers/api';

const getProductReceiptService = (pointOfSaleId) => {
  return httpClient_post('/stock/products-receipts/get', {
    pointOfSaleId,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getProductDeliveryService = (pointOfSaleId) => {
  return httpClient_post('/stock/products-delivery/get', {
    pointOfSaleId,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getProductSupplierCommandService = (pointOfSaleId) => {
  return httpClient_post('/stock/products-supplier-command/get', {
    pointOfSaleId,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getProductCustomerCommandService = (pointOfSaleId) => {
  return httpClient_post('/stock/products-customer-command/get', {
    pointOfSaleId,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getInvoiceService = (pointOfSaleId) => {
  return httpClient_post('/stock/invoices/get', {
    pointOfSaleId,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const addProductReceiptService = ({
  pointOfSaleId,
  name,
  commandLines,
  note,
  productSupplierCommand,
}) => {
  return httpClient_post('/stock/products-receipts/create', {
    pointOfSaleId,
    name,
    commandLines,
    note,
    productSupplierCommand,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const addProductCustomerCommandService = ({
  pointOfSaleId,
  name,
  customer,
  commandLines,
  note,
}) => {
  return httpClient_post('/stock/products-customer-command/create', {
    pointOfSaleId,
    name,
    customer,
    commandLines,
    note,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const editProductCustomerCommandService = ({
  productCustomerCommandId,
  name,
  commandLines,
  note,
}) => {
  return httpClient_put('/stock/products-customer-command/edit', {
    productCustomerCommandId,
    name,
    commandLines,
    note,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const editProductSupplierCommandService = ({
  productSupplierCommandId,
  name,
  commandLines,
  note,
}) => {
  return httpClient_put('/stock/products-supplier-command/edit', {
    productSupplierCommandId,
    name,
    commandLines,
    note,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const addProductSupplierCommandService = ({
  pointOfSaleId,
  name,
  supplier,
  commandLines,
  note,
}) => {
  return httpClient_post('/stock/products-supplier-command/create', {
    pointOfSaleId,
    name,
    supplier,
    commandLines,
    note,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const createInvoiceService = ({
  pointOfSaleId,
  invoiceLines,
  company,
  productDelivery,
  discountPercent,
  HT,
  TTC,
  paymentChoice,
  referencePayment,
  paymentDeadline,
  latePenalties,
  escompte,
  object,
  note,
  datePayment,
}) => {
  return httpClient_post('/stock/invoices/create', {
    pointOfSaleId,
    invoiceLines,
    company,
    productDelivery,
    discountPercent,
    HT,
    TTC,
    paymentChoice,
    referencePayment,
    paymentDeadline,
    latePenalties,
    escompte,
    datePayment,
    note,
    object,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const addProductDeliveryService = ({
  pointOfSaleId,
  name,
  commandLines,
  note,
  productCustomerCommand,
}) => {
  return httpClient_post('/stock/products-delivery/create', {
    pointOfSaleId,
    name,
    commandLines,
    note,
    productCustomerCommand,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export {
  getProductReceiptService,
  addProductReceiptService,
  getInvoiceService,
  createInvoiceService,
  getProductSupplierCommandService,
  addProductSupplierCommandService,
  getProductCustomerCommandService,
  addProductCustomerCommandService,
  addProductDeliveryService,
  getProductDeliveryService,
  editProductSupplierCommandService,
  editProductCustomerCommandService,
};
