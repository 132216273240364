const phoneColor = '#2781ea';
const walkInColor = '#35ca6e';
const onlineColor = '#f7be23';

const primaryBookTableColor = '#C14646';

export {
  phoneColor,
  walkInColor,
  onlineColor,
  primaryBookTableColor,
};
