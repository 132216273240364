// @flow
import {
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAILED,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  GET_USERS,
  GET_USERS_FAILED,
  GET_USERS_SUCCESS,
} from '../../constants/actionTypes';
import { REHYDRATE } from 'redux-persist';
const INIT_STATE = {
  user: {},
  loading: false,
  error: false,
  users: [],
};

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS:
      return { ...state, loading: true };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: [...action.payload],
        loading: false,
        error: null,
      };
    case GET_USERS_FAILED:
      return { ...state, error: action.payload, loading: false };

    case ADD_USER:
      return { ...state, loading: true };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
        loading: false,
        error: null,
      };
    case ADD_USER_FAILED:
      return { ...state, error: action.payload, loading: false };

    case UPDATE_USER:
      return { ...state, loading: true };
    case UPDATE_USER_SUCCESS:
      const newList = state.users.map((item) =>
        item._id === action.payload._id ? action.payload : item,
      );
      return {
        ...state,
        users: newList,
        loading: false,
        error: null,
      };
    case UPDATE_USER_FAILED:
      return { ...state, error: action.payload, loading: false };

    case DELETE_USER:
      return { ...state, loading: true };
    case DELETE_USER_SUCCESS:
      const List = state.users.filter(
        (item) => item._id !== action.payload._id,
      );
      return { ...state, users: List, loading: false, error: null };
    case DELETE_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.Users,
          }
        : {
            ...state,
          };
    default:
      return { ...state };
  }
};

export default Users;
