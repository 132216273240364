import React, { Component } from 'react';
import { isIE } from 'react-device-detect';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import ErrorBoundary from './components/ErrorBoundary';
import SuperAdminLayout from './components/superAdmin/SuperAdminLayout';
// setup fake backend
import { isUserAuthenticated } from './helpers/authUtils';
import { BooktableAlert } from './pages/Booking/BooktableAlert';
import { routes } from './routes';
import { withTranslation, Trans } from 'react-i18next';
// Themes
import './assets/scss/DefaultTheme.scss';

// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>;

// All layouts/containers
const NonAuthLayout = Loadable({
  loader: () => import('./components/NonAuthLayout'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});


/**
 * Exports the component with layout wrapped to it
 * @param {} WrappedComponent
 */
const withLayout = (WrappedComponent) => {
  const HOC = class extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return connect()(HOC);
};

/**
 * Main app component
 */
class App extends Component {
  /**
   * Returns the layout component based on different properties
   * @param {*} props
   */

  getLayout = (route) => {
    if (isUserAuthenticated(this.props)) {
      switch (route.layout) {

        case 'SuperAdminLayout':
          return SuperAdminLayout;
        ;
      }
    }
    return NonAuthLayout;
  };
  render() {
    if (isIE)
      return (
        <div>
          {' '}
          internet explorer is not supported. Download
          Chrome/Opera/Firefox{' '}
        </div>
      );

    return (
      // rendering the router with layout
      <BrowserRouter>
        {/*<ErrorBoundary>*/}
          <React.Fragment>
            {routes.map((route, index) => {
              return (
                <route.route
                  key={index}
                  path={route.path}
                  exact
                  categoryChecker={route.categoryChecker}
                  roles={route.roles}
                  component={withLayout((props) => {
                    const Layout = this.getLayout(route);
                    return (
                      <Layout
                        {...props}
                        title={route.title}
                        showContent={route.showContent}
                      >
                        <route.component {...props} />
                      </Layout>
                    );
                  })}
                />
              );
            })}
            <BooktableAlert />
          </React.Fragment>
        {/*</ErrorBoundary>*/}
      </BrowserRouter>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     isAuthenticated: state.Auth.isAuthenticated,
//   };
// };
// const TheApp = withTranslation()(App);
// export default connect(mapStateToProps, null)(TheApp);
export default withTranslation()(App);
