import {
  httpClient_get,
  httpClient_post,
  httpClient_put,
} from '../../helpers/api';

const getRestaurantsService = (data) => {
  return httpClient_get(`/pointofsale/all`)
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

const addRestaurantsService = (restaurant, createAdmin) => {
  const formData = new FormData();

  try {
    formData.append('address', restaurant.address);
    formData.append('email', restaurant.email);
    formData.append('email_admin', restaurant.email_admin);
    formData.append('latitude', restaurant.latitude);
    formData.append('longitude', restaurant.longitude);
    formData.append('name_r', restaurant.name_r);
    formData.append('name', restaurant.name);
    formData.append('password', restaurant.password);
    formData.append('prenom', restaurant.prenom);
    formData.append('city', restaurant.city);
    formData.append('category', restaurant.category);

    // if (restaurant.tags)
    // formData.append('tags', JSON.stringify(restaurant.tags));
    formData.append('telephone', restaurant.telephone);
    formData.append('telephone_admin', restaurant.telephone_admin);
    formData.append('web_l', restaurant.web_l);
    formData.append('createAdmin', createAdmin);
    formData.append('logoPointOfSale', restaurant.logo[0]);

    restaurant.tcuisine.map((el) =>
      formData.append('kitchenType[]', el),
    );
    restaurant.StRestourant.map((el) =>
      formData.append('restaurantStyle[]', el),
    );
    restaurant.outside.map((el) =>
      formData.append('outsideType[]', el),
    );
    formData.append('isAlcohol', restaurant.isAlcohol);
    formData.append('isSmokingArea', restaurant.isSmokingArea);
    formData.append('isVegetarian', restaurant.isVegetarian);
    formData.append('isAccessAnimals', restaurant.isAccessAnimals);
    formData.append('isGlutenFree', restaurant.isGlutenFree);

    formData.append('isCar', restaurant.isCar);
    formData.append('isCarPark', restaurant.isCarPark);

    formData.append('notes', restaurant.notes);
    restaurant.dressCode.map((el) =>
      formData.append('dressCode[]', el),
    );
  } catch (e) {
    console.log('||----restaurant ---->>', e);
  }
  return httpClient_post(`/pointofsale/add`, formData)
    .then((response) => {
      console.log(response.data);

      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

const addRestaurantsClientService = (restaurant, affiliateId) => {
  console.log('restaurant data', restaurant);
  const formData = new FormData();

  formData.append('address', restaurant.address);
  formData.append('email', restaurant.email);
  formData.append('email_admin', restaurant.email_admin);
  formData.append('latitude', restaurant.latitude);
  formData.append('longitude', restaurant.longitude);
  formData.append('name_r', restaurant.name_r);
  formData.append('name', restaurant.name);
  formData.append('password', restaurant.password);
  formData.append('prenom', restaurant.prenom);
  formData.append('city', restaurant.city);
  formData.append('country', restaurant.country);

  formData.append('telephone', restaurant.telephone);
  formData.append('telephone_admin', restaurant.telephone_admin);
  formData.append('web_l', restaurant.web_l);
  formData.append('affiliateId', affiliateId);
  formData.append('subscription', 'free');
  // formData.append('logoPointOfSale', restaurant.logoPointOfSale[0]);
  // formData.append('capacity', restaurant.capacity);
  // formData.append('averageBill', restaurant.averageBill);

  return httpClient_post(`/client/create-restaurant`, formData)
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

const editRestaurantsService = (pointOfSale) => {
  const formData = new FormData();

  formData.append('address', pointOfSale.address);
  formData.append('email', pointOfSale.email);
  formData.append('latitude', pointOfSale.latitude);
  formData.append('longitude', pointOfSale.longitude);
  formData.append('name_r', pointOfSale.name_r);
  formData.append('telephone', pointOfSale.telephone);
  formData.append('web_l', pointOfSale.web_l);
  formData.append('city', pointOfSale.city);
  formData.append('country', pointOfSale.country);
  formData.append('postalCode', pointOfSale.postalCode);

  formData.append('mobileRestaurant', pointOfSale.mobileRestaurant);

  formData.append('url_instagram', pointOfSale.url_instagram);
  formData.append('url_fb', pointOfSale.url_fb);
  formData.append('url_youtube', pointOfSale.url_youtube);
  formData.append('url_linkedin', pointOfSale.url_linkedin);

  formData.append('description', pointOfSale.description);
  formData.append('currency', pointOfSale.currency);
  // formData.append('priceAvg', pointOfSale.priceAvg);
  pointOfSale.tags &&
    formData.append('tags', JSON.stringify(pointOfSale.tags));

  // if (typeof pointOfSale.isDemo !== typeof undefined)
  //   formData.append('isDemo', pointOfSale.isDemo);

  // if (typeof pointOfSale.maxBookings !== typeof undefined)
  //   formData.append('maxBookings', pointOfSale.maxBookings);

  if (pointOfSale.logo.length > 0)
    formData.append('logoPointOfSale', pointOfSale.logo[0]);

  if (pointOfSale.backgroundImageCover.length > 0)
    formData.append(
      'backgroundImageCover',
      pointOfSale.backgroundImageCover[0],
    );

  if (pointOfSale.tcuisine && pointOfSale.tcuisine.length > 0)
    pointOfSale.tcuisine.map((el) =>
      formData.append('kitchenType[]', el),
    );

  if (pointOfSale.StRestourant && pointOfSale.StRestourant.length > 0)
    pointOfSale.StRestourant.map((el) =>
      formData.append('restaurantStyle[]', el),
    );

  if (pointOfSale.outside && pointOfSale.outside.length > 0) {
    pointOfSale.outside.map((el) =>
      formData.append('outsideType[]', el),
    );
  }

  formData.append('isAlcohol', pointOfSale.isAlcohol);
  formData.append('isSmokingArea', pointOfSale.isSmokingArea);
  formData.append('isVegetarian', pointOfSale.isVegetarian);
  formData.append('isAccessAnimals', pointOfSale.isAccessAnimals);
  formData.append('isGlutenFree', pointOfSale.isGlutenFree);
  formData.append('isCar', pointOfSale.isCar);
  formData.append('isCarPark', pointOfSale.isCarPark);
  formData.append(
    'isHandicappedFriendly',
    pointOfSale.isHandicappedFriendly || false,
  );

  formData.append('notes', pointOfSale.notes);

  if (pointOfSale.dressCode && pointOfSale.dressCode.length > 0)
    pointOfSale.dressCode.map((el) =>
      formData.append('dressCode[]', el),
    );

  if (
    pointOfSale.paymentOptions &&
    pointOfSale.paymentOptions.length > 0
  )
    pointOfSale.paymentOptions.map((el) =>
      formData.append('paymentOptions[]', el),
    );

  return httpClient_put(
    `/pointofsale/${pointOfSale._id}/edit`,
    formData,
  )
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

const showRestaurantsService = (id, show) => {
  return httpClient_post(`/pointofsale/show`, { id, show })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

const activeRestaurantService = (id) => {
  return httpClient_post(`/pointofsale/enable`, { id })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

const desactiveRestaurantService = (id) => {
  return httpClient_post(`/pointofsale/disable`, { id })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

const disableFreeTailRestaurantService = (id) => {
  return httpClient_post(`/pointofsale/disableFreeTail`, { id })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

const getRestaurantsClientService = (id) => {
  return httpClient_get(`/pointofsale/list/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

export {
  getRestaurantsService,
  addRestaurantsService,
  editRestaurantsService,
  addRestaurantsClientService,
  showRestaurantsService,
  getRestaurantsClientService,
  activeRestaurantService,
  desactiveRestaurantService,
  disableFreeTailRestaurantService
};
