// @flow
import {
  GETTIMEPERBOOKING,
  GETTIMEPERBOOKINGSUCCESS,
  SETTIMEPERBOOKING,
} from '../../constants/actionTypes';

export const setTimePerBookingFn = (
  pointOfSaleId,
  time,
  NoShow,
  NbCommand,
  timeCommand,
  zones,
  enableSendSMS,
) => ({
  type: SETTIMEPERBOOKING,
  payload: {
    time,
    pointOfSaleId,
    NoShow,
    NbCommand,
    timeCommand,
    zones,
    enableSendSMS,
  },
});

export const getTimePerBookingFn = (pointOfSaleId, NoShow) => ({
  type: GETTIMEPERBOOKING,
  payload: { pointOfSaleId, NoShow },
});

export const getTimePerBookingSuccess = (
  pointOfSaleId,
  time,
  NoShow,
  NbCommand,
  timePerCommand,
  zones,
  enableSendSMS,
) => ({
  type: GETTIMEPERBOOKINGSUCCESS,
  payload: {
    time,
    pointOfSaleId,
    NoShow,
    NbCommand,
    timePerCommand,
    zones,
    enableSendSMS,
  },
});
