// @flow
import {
  ADD_RESTAURANT,
  ADD_RESTAURANT_CLIENT,
  ADD_RESTAURANT_CLIENT_FAILED,
  ADD_RESTAURANT_CLIENT_SUCCESS,
  ADD_RESTAURANT_FAILED,
  ADD_RESTAURANT_SUCCESS,
  EDIT_RESTAURANT,
  EDIT_RESTAURANT_FAILED,
  EDIT_RESTAURANT_SUCCESS,
  GET_RESTAURANTS,
  GET_RESTAURANTS_CLIENT,
  GET_RESTAURANTS_CLIENT_FAILED,
  GET_RESTAURANTS_CLIENT_SUCCESS,
  GET_RESTAURANTS_FAILED,
  GET_RESTAURANTS_SUCCESS,
  SET_POINT_OF_SALE_UPDATE_AT,
  SET_RESTAURANT_SETTINGS,
  SHOW_RESTAURANT_SUCCESS,
} from '../../constants/actionTypes';

const INIT_STATE = {
  pointOfSales: [],
  loading: false,
  error: null,
  c_restaurants: [],
};

const PointOfSale = (state = INIT_STATE, action) => {
  state.error = null;
  switch (action.type) {
    case GET_RESTAURANTS:
      return { ...state, loading: true };

    case GET_RESTAURANTS_SUCCESS:
      // const listPoint_ = action.payload.map((elem)=>{

      //   if(elem.contacts.length > 0){
      //     let  manager = elem.contacts.find((el)=>el.role === "director") || null
      //     let  owner = elem.contacts.find((el)=>el.role === "owner") || null

      //     manager = manager ? manager.contact.firstName +" "+manager.contact.lastName : "";
      //     owner   = owner ? owner.contact.firstName +" "+owner.contact.lastName : "";

      //     return {...elem,manager,owner}
      //   }

      //   return {...elem,manager:"",owner:""}
      // })

      return {
        ...state,
        loading: false,
        pointOfSales: action.payload, //listPoint_,
      };

    case GET_RESTAURANTS_FAILED:
      return { ...state, loading: false, error: action.payload };

    case GET_RESTAURANTS_CLIENT:
      return { ...state, loading: true };

    case GET_RESTAURANTS_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        c_restaurants: action.payload,
      };

    case GET_RESTAURANTS_CLIENT_FAILED:
      return { ...state, loading: false, error: action.payload };

    case ADD_RESTAURANT:
      return { ...state, loading: true };

    case ADD_RESTAURANT_SUCCESS:
      return {
        ...state,
        loading: false,
        pointOfSales: [...state.pointOfSales, action.payload],
        error: null,
      };

    case ADD_RESTAURANT_FAILED:
      return { ...state, loading: false, error: action.payload };

    case ADD_RESTAURANT_CLIENT:
      return { ...state, loading: true };

    case ADD_RESTAURANT_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case ADD_RESTAURANT_CLIENT_FAILED:
      return { ...state, loading: false, error: action.payload };

    case EDIT_RESTAURANT:
      return { ...state, loading: true };

    case EDIT_RESTAURANT_SUCCESS:
      const list = state.pointOfSales.map((item) => {
        if (item._id === action.payload._id) {
          // if(item.contacts.length > 0){
          //   let  manager = item.contacts.find((el)=>el.role === "director") || null
          //   let  owner = item.contacts.find((el)=>el.role === "owner") || null

          //   manager = manager ? manager.contact.firstName +" "+manager.contact.lastName : "";
          //   owner   = owner ? owner.contact.firstName +" "+owner.contact.lastName : "";

          //   return {...action.payload,manager,owner}
          // }

          // else

          return { ...action.payload, manager: '', owner: '' };
        }

        return item;
      });

      return { ...state, loading: false, pointOfSales: list };

    case SHOW_RESTAURANT_SUCCESS:
      const list_ = state.pointOfSales.map((item) => {
        if (item._id === action.payload._id)
          return { ...item, show: action.payload.show };

        return item;
      });

      return { ...state, loading: false, pointOfSales: list_ };

    case EDIT_RESTAURANT_FAILED:
      return { ...state, loading: false, error: action.payload };

    case SET_POINT_OF_SALE_UPDATE_AT:
      const list_points =
        state.pointOfSales.map((item) => {
          if (item._id === action.payload) {
            return { ...item, admin_updateAt: new Date() };
          }

          return item;
        }) || [];

      return { ...state, pointOfSales: list_points };

    case SET_RESTAURANT_SETTINGS:
      const listPoint = state.pointOfSales.map((elm) => {
        if (elm._id === action.payload.pointOfSale_id) {
          return {
            ...elm,
            ...action.payload,
            admin_updateAt: new Date(),
          };
        }
        return elm;
      });

      return { ...state, pointOfSales: listPoint };

    default:
      return { ...state };
  }
};

export default PointOfSale;
