// @flow
import {
  GET_RESTO,
  GET_RESTO_SUCCESS,
  GET_RESTO_FAILED,
  SELECT_RESTO,
  SHOW_RESTAURANT_SUCCESS,
  EDIT_RESTAURANT_SUPERVISOR,
  EDIT_HOTEL_SUPERVISOR,
} from '../../constants/actionTypes';
import { REHYDRATE } from 'redux-persist';
const INIT_STATE = {
  pointOfSales: [],
  selectedPointOfSale: false,
  selectedPointOfSaleType: 'POS',
  loading: false,
  error: {},
};

const Supervisor = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RESTO:
      return {
        ...state,
        loading: action.payload,
        selectedPointOfSale: false,
        loading: false,
      };
    case SELECT_RESTO:
      return {
        ...state,
        selectedPointOfSale: action.payload.selectedPointOfSale,
        selectedPointOfSaleType:
          action.payload.selectedPointOfSaleType,
        loading: false,
      };
    case EDIT_RESTAURANT_SUPERVISOR:
      state.pointOfSales = state.pointOfSales.map((resto) => {
        if (resto._id === action.payload._id) {
          resto = action.payload;
        }
        return resto;
      });
      return {
        ...state,
        selectedPointOfSale: action.payload,
        loading: false,
      };
    case EDIT_HOTEL_SUPERVISOR:
      state.pointOfSales = state.pointOfSales.map((resto) => {
        if (resto._id === action.payload._id) {
          resto = action.payload;
        }
        return resto;
      });
      return {
        ...state,
        pointOfSales: state.pointOfSales,
        loading: false,
      };
    case GET_RESTO_SUCCESS:
      return {
        ...state,
        pointOfSales: action.payload.pointOfSales,
        loading: false,
        error: null,
      };
    case GET_RESTO_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case SHOW_RESTAURANT_SUCCESS:
      const list_ = state.pointOfSales.map((item) => {
        if (item._id === action.payload._id)
          return { ...item, show: action.payload.show };

        return item;
      });

      return { ...state, loading: false, pointOfSales: list_ };

    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.Supervisor,
          }
        : {
            ...state,
          };
    default:
      return { ...state };
  }
};

export default Supervisor;
