import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  getProductReceiptSuccess,
  getProductReceiptFailed,
  getProductReceiptAPISuccess,
  addProductReceiptSuccess,
  addProductReceiptFailed,
  getInvoiceSuccess,
  getInvoiceFailed,
  createInvoiceSuccess,
  createInvoiceFailed,
  getInvoiceTableSuccess,
  getProductCommandAPISuccess,
  getProductCommandSuccess,
  getProductCommandFailed,
  addProductSupplierCommandSuccess,
  addProductSupplierCommandFailed,
  getProductSupplierCommandSuccess,
  getProductSupplierCommandFailed,
  getProductCustomerCommandSuccess,
  getProductCustomerCommandFailed,
  addProductCustomerCommandSuccess,
  addProductCustomerCommandFailed,
  createProductDeliverySuccess,
  createProductDeliveryFailed,
  getProductDeliverySuccess,
  getProductDeliveryFailed,
  editProductSupplierCommandSuccess,
  editProductSupplierCommandFailed,
  editProductCustomerCommandSuccess,
  editProductCustomerCommandFailed,
} from './actions';
import {
  getProductReceiptService,
  addProductReceiptService,
  getInvoiceService,
  createInvoiceService,
  getProductSupplierCommandService,
  addProductSupplierCommandService,
  getProductCustomerCommandService,
  addProductCustomerCommandService,
  addProductDeliveryService,
  getProductDeliveryService,
  editProductCustomerCommandService,
  editProductSupplierCommandService,
} from './service';
import {
  GET_PRODUCT_RECEIPT,
  ADD_PRODUCT_RECEIPT,
  GET_INVOICE,
  CREATE_INVOICE,
  ADD_PRODUCT_SUPPLIER_COMMAND,
  GET_PRODUCT_SUPPLIER_COMMAND,
  ADD_PRODUCT_CUSTOMER_COMMAND,
  GET_PRODUCT_CUSTOMER_COMMAND,
  ADD_PRODUCT_DELIVERY,
  GET_PRODUCT_DELIVERY,
  EDIT_PRODUCT_SUPPLIER_COMMAND,
  EDIT_PRODUCT_CUSTOMER_COMMAND,
} from '../../constants/actionTypes';

function* getProductSupplierCommand({ payload: { pointOfSaleId } }) {
  try {
    const response = yield call(
      getProductSupplierCommandService,
      pointOfSaleId,
    );
    yield put(
      getProductSupplierCommandSuccess(
        response.productSupplierCommands,
      ),
    );
  } catch (error) {
    yield put(getProductSupplierCommandFailed(error));
  }
}

function* getProductCustomerCommand({ payload: { pointOfSaleId } }) {
  try {
    const response = yield call(
      getProductCustomerCommandService,
      pointOfSaleId,
    );
    yield put(
      getProductCustomerCommandSuccess(
        response.productCustomerCommands,
      ),
    );
  } catch (error) {
    yield put(getProductCustomerCommandFailed(error));
  }
}

function* getProductReceipts({ payload: { pointOfSaleId } }) {
  try {
    const response = yield call(
      getProductReceiptService,
      pointOfSaleId,
    );
    yield put(getProductReceiptAPISuccess(response.productsReceipts));
    yield put(getProductReceiptSuccess(response.productsReceipts));
    //     response.productsReceipts.map((pr) => {
    //       const productReceiptLine = {
    //         _id: pr._id,
    //         quantity: pr.quantity,
    //         receiptType: pr.receiptType,
    //         createdAt: pr.createdAt,
    //       };

    //       if (pr.receiptType === 'ingredient') {
    //         productReceiptLine.title = pr.ingredient.ingredientName;
    //       }

    //       if (pr.receiptType === 'product') {
    //         productReceiptLine.title = pr.product.itemName;
    //       }

    //       return productReceiptLine;
    //     }),
    //   ),
    // );
  } catch (error) {
    yield put(getProductReceiptFailed(error));
  }
}

function* getProductDelivery({ payload: { pointOfSaleId } }) {
  try {
    const response = yield call(
      getProductDeliveryService,
      pointOfSaleId,
    );
    yield put(getProductDeliverySuccess(response.productsDelivery));
  } catch (error) {
    yield put(getProductDeliveryFailed(error));
  }
}

function* getInvoices({ payload: { pointOfSaleId } }) {
  try {
    const response = yield call(getInvoiceService, pointOfSaleId);
    yield put(getInvoiceSuccess(response.invoices));
  } catch (error) {
    yield put(getInvoiceFailed(error));
  }
}

function* editProductCustomerCommand({
  payload: {
    productCustomerCommandId,
    name,
    commandLines,
    note,
    callback,
  },
}) {
  try {
    const response = yield call(editProductCustomerCommandService, {
      productCustomerCommandId,
      name,
      commandLines,
      note,
      callback,
    });

    yield put(
      editProductCustomerCommandSuccess(
        response.productCustomerCommand,
      ),
    );
    callback();
  } catch (error) {
    yield put(editProductCustomerCommandFailed(error));
  }
}

function* editProductSupplierCommand({
  payload: {
    productSupplierCommandId,
    name,
    commandLines,
    note,
    callback,
  },
}) {
  try {
    const response = yield call(editProductSupplierCommandService, {
      productSupplierCommandId,
      name,
      commandLines,
      note,
      callback,
    });

    yield put(
      editProductSupplierCommandSuccess(
        response.productSupplierCommand,
      ),
    );
    callback();
  } catch (error) {
    yield put(editProductSupplierCommandFailed(error));
  }
}

function* addProductSupplierCommand({
  payload: {
    pointOfSaleId,
    name,
    supplier,
    commandLines,
    note,
    callback,
  },
}) {
  try {
    const response = yield call(addProductSupplierCommandService, {
      pointOfSaleId,
      name,
      supplier,
      commandLines,
      note,
      callback,
    });

    yield put(
      addProductSupplierCommandSuccess(
        response.productSupplierCommand,
      ),
    );
    callback();
  } catch (error) {
    yield put(addProductSupplierCommandFailed(error));
  }
}

function* addProductCustomerCommand({
  payload: {
    pointOfSaleId,
    name,
    customer,
    commandLines,
    note,
    callback,
  },
}) {
  try {
    const response = yield call(addProductCustomerCommandService, {
      pointOfSaleId,
      name,
      customer,
      commandLines,
      note,
      callback,
    });

    yield put(
      addProductCustomerCommandSuccess(
        response.productCustomerCommand,
      ),
    );
    callback();
  } catch (error) {
    yield put(addProductCustomerCommandFailed(error));
  }
}

function* addProductReceipts({
  payload: {
    pointOfSaleId,
    name,
    commandLines,
    note,
    productSupplierCommand,
    callback,
  },
}) {
  try {
    const response = yield call(addProductReceiptService, {
      pointOfSaleId,
      name,
      commandLines,
      note,
      productSupplierCommand,
    });
    const pr = response.productReceipt;

    yield put(addProductReceiptSuccess(pr));
    callback();
  } catch (error) {
    yield put(addProductReceiptFailed(error));
  }
}

function* addProductDelivery({
  payload: {
    pointOfSaleId,
    name,
    commandLines,
    note,
    productCustomerCommand,
    callback,
  },
}) {
  try {
    const response = yield call(addProductDeliveryService, {
      pointOfSaleId,
      name,
      commandLines,
      note,
      productCustomerCommand,
    });
    const pr = response.productDelivery;

    yield put(createProductDeliverySuccess(pr));
    callback();
  } catch (error) {
    yield put(createProductDeliveryFailed(error));
  }
}

function* createInvoice({
  payload: {
    pointOfSaleId,
    invoiceLines,
    company,
    productDelivery,
    discountPercent,
    HT,
    TTC,
    paymentChoice,
    referencePayment,
    paymentDeadline,
    latePenalties,
    escompte,
    object,
    datePayment,
    note,
    callback,
  },
}) {
  try {
    const response = yield call(createInvoiceService, {
      pointOfSaleId,
      invoiceLines,
      company,
      productDelivery,
      discountPercent,
      HT,
      TTC,
      object,
      paymentChoice,
      referencePayment,
      paymentDeadline,
      latePenalties,
      escompte,
      note,
      datePayment,
    });

    yield put(createInvoiceSuccess(response.invoice));
    callback();
  } catch (error) {
    yield put(createInvoiceFailed(error));
  }
}

export function* watchCreateInvoices() {
  yield takeEvery(CREATE_INVOICE, createInvoice);
}

export function* watchGetInvoices() {
  yield takeEvery(GET_INVOICE, getInvoices);
}

export function* watchAddProductReceipts() {
  yield takeEvery(ADD_PRODUCT_RECEIPT, addProductReceipts);
}

export function* watchGetProductReceipts() {
  yield takeEvery(GET_PRODUCT_RECEIPT, getProductReceipts);
}

export function* watchAddProductSupplierCommand() {
  yield takeEvery(
    ADD_PRODUCT_SUPPLIER_COMMAND,
    addProductSupplierCommand,
  );
}

export function* watchEditProductSupplierCommand() {
  yield takeEvery(
    EDIT_PRODUCT_SUPPLIER_COMMAND,
    editProductSupplierCommand,
  );
}

export function* watchEditProductCustomerCommand() {
  yield takeEvery(
    EDIT_PRODUCT_CUSTOMER_COMMAND,
    editProductCustomerCommand,
  );
}

export function* watchGetProductSupplierCommand() {
  yield takeEvery(
    GET_PRODUCT_SUPPLIER_COMMAND,
    getProductSupplierCommand,
  );
}

export function* watchAddProductCustomerCommand() {
  yield takeEvery(
    ADD_PRODUCT_CUSTOMER_COMMAND,
    addProductCustomerCommand,
  );
}

export function* watchGetProductCustomerCommand() {
  yield takeEvery(
    GET_PRODUCT_CUSTOMER_COMMAND,
    getProductCustomerCommand,
  );
}

export function* watchAddProductDelivery() {
  yield takeEvery(ADD_PRODUCT_DELIVERY, addProductDelivery);
}

export function* watchGetProductDelivery() {
  yield takeEvery(GET_PRODUCT_DELIVERY, getProductDelivery);
}

function* managementSaga() {
  yield all([
    fork(watchGetProductReceipts),
    fork(watchAddProductReceipts),
    fork(watchGetInvoices),
    fork(watchCreateInvoices),
    fork(watchAddProductSupplierCommand),
    fork(watchGetProductSupplierCommand),
    fork(watchAddProductCustomerCommand),
    fork(watchGetProductCustomerCommand),
    fork(watchAddProductDelivery),
    fork(watchGetProductDelivery),
    fork(watchEditProductSupplierCommand),
    fork(watchEditProductCustomerCommand),
  ]);
}

export default managementSaga;
