import { REHYDRATE } from 'redux-persist';

// @flow
import {
  CREATE_INGREDIENT,
  CREATE_INGREDIENT_FAILED,
  CREATE_INGREDIENT_SUCCESS,
  CREATE_PRODUCT,
  CREATE_PRODUCT_CATEGORY,
  CREATE_PRODUCT_CATEGORY_FAILED,
  CREATE_PRODUCT_CATEGORY_SUCCESS,
  CREATE_PRODUCT_FAILED,
  CREATE_PRODUCT_SUCCESS,
  EDIT_INGREDIENT,
  EDIT_INGREDIENT_FAILED,
  EDIT_INGREDIENT_SUCCESS,
  EDIT_PRODUCT,
  EDIT_PRODUCT_CATEGORY,
  EDIT_PRODUCT_CATEGORY_FAILED,
  EDIT_PRODUCT_CATEGORY_SUCCESS,
  EDIT_PRODUCT_FAILED,
  EDIT_PRODUCT_SHOW_IN_POS_STATUS,
  EDIT_PRODUCT_SHOW_IN_POS_STATUS_FAILED,
  EDIT_PRODUCT_SHOW_IN_POS_STATUS_SUCCESS,
  EDIT_PRODUCT_SUCCESS,
  GET_CATEGORY_with_PRODUCT,
  GET_CATEGORY_with_PRODUCT_FAILED,
  GET_CATEGORY_with_PRODUCT_SUCCESS,
  GET_INGREDIENT,
  GET_INGREDIENT_FAILED,
  GET_INGREDIENT_SUCCESS,
  GET_PRODUCT,
  GET_PRODUCT_CATEGORY,
  GET_PRODUCT_CATEGORY_FAILED,
  GET_PRODUCT_CATEGORY_SUCCESS,
  GET_PRODUCT_FAILED,
  GET_PRODUCT_SUCCESS,
} from '../../constants/actionTypes';

const INIT_STATE = {
  productCategory: [],
  ingredient: [],
  products: [],
  loading: false,
  error: false,
  categoryWithProduct: [],
};

const Caisse = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCT_CATEGORY:
      return { ...state, loading: true };
    case GET_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        productCategory: action.payload,
        loading: false,
        error: null,
      };
    case GET_PRODUCT_CATEGORY_FAILED:
      return { ...state, error: action.payload, loading: false };
    case CREATE_PRODUCT_CATEGORY:
      return { ...state, loading: true };
    case CREATE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        productCategory: [...state.productCategory, action.payload],
        loading: false,
        error: null,
      };
    case CREATE_PRODUCT_CATEGORY_FAILED:
      return { ...state, error: action.payload, loading: false };
    case EDIT_PRODUCT_CATEGORY:
      return { ...state, loading: true };
    case EDIT_PRODUCT_CATEGORY_SUCCESS:
      const c = state.productCategory.map((pc) => {
        if (pc._id === action.payload._id) {
          pc = action.payload;
        }
        return pc;
      });
      return {
        ...state,
        productCategory: c,
        loading: false,
        error: null,
      };
    case EDIT_PRODUCT_CATEGORY_FAILED:
      return { ...state, error: action.payload, loading: false };
    case EDIT_PRODUCT:
      return { ...state, loading: true };
    case EDIT_PRODUCT_SUCCESS:
      const pr = state.products.map((pc) => {
        if (pc._id === action.payload._id) {
          pc = action.payload;
        }
        return pc;
      });
      return {
        ...state,
        products: pr,
        loading: false,
        error: null,
      };
    case EDIT_PRODUCT_FAILED:
      return { ...state, error: action.payload, loading: false };
    case EDIT_INGREDIENT:
      return { ...state, loading: true };
    case EDIT_INGREDIENT_SUCCESS:
      const ing = state.ingredient.map((pc) => {
        if (pc._id === action.payload._id) {
          pc = action.payload;
        }
        return pc;
      });
      return {
        ...state,
        ingredient: ing,
        loading: false,
        error: null,
      };
    case EDIT_INGREDIENT_FAILED:
      return { ...state, error: action.payload, loading: false };
    case CREATE_INGREDIENT:
      return { ...state, loading: true };
    case CREATE_INGREDIENT_SUCCESS:
      return {
        ...state,
        ingredient: [...state.ingredient, action.payload],
        loading: false,
        error: null,
      };
    case CREATE_INGREDIENT_FAILED:
      return { ...state, error: action.payload, loading: false };

    case GET_INGREDIENT:
      return { ...state, loading: true };
    case GET_INGREDIENT_SUCCESS:
      return {
        ...state,
        ingredient: action.payload,
        loading: false,
        error: null,
      };
    case GET_INGREDIENT_FAILED:
      return { ...state, error: action.payload, loading: false };

    case GET_PRODUCT:
      return { ...state, loading: true };
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        products: action.payload,
        loading: false,
        error: null,
      };
    case GET_PRODUCT_FAILED:
      return { ...state, error: action.payload, loading: false };

    case CREATE_PRODUCT:
      return { ...state, loading: true };
    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [...state.products, action.payload],
        loading: false,
        error: null,
      };
    case CREATE_PRODUCT_FAILED:
      return { ...state, error: action.payload, loading: false };

    case GET_CATEGORY_with_PRODUCT:
      return { ...state, loading: true };

    case GET_CATEGORY_with_PRODUCT_SUCCESS:
      return {
        ...state,
        categoryWithProduct: action.payload,
        loading: false,
        error: null,
      };

    case GET_CATEGORY_with_PRODUCT_FAILED:
      return { ...state, error: action.payload, loading: false };

    case EDIT_PRODUCT_SHOW_IN_POS_STATUS:
      return { ...state, loading: true };

    case EDIT_PRODUCT_SHOW_IN_POS_STATUS_SUCCESS:
      try {
        const productCategory = action.payload;
        let index = state.productCategory.findIndex(
          (e) => e._id == productCategory._id,
        );
        console.log({ index });
        if (index < 0) return { ...state, loading: false };
        state.productCategory[index].showInPos =
          productCategory.showInPos;
        return {
          ...state,
          productCategory: [...state.productCategory],
          loading: false,
          error: null,
        };
      } catch (error) {
        console.log(JSON.stringify({ error }, '', '\t'));
        return { ...state, loading: false };
      }

    case EDIT_PRODUCT_SHOW_IN_POS_STATUS_FAILED:
      return { ...state, error: action.payload, loading: false };

    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.Caisse,
          }
        : {
            ...state,
          };
    default:
      return { ...state };
  }
};

export default Caisse;
