// @flow
import {
  CALLSPECIALDAYS,
  SETSPECIALDAYS,
  SAVESPECIALDAYS,
} from '../../constants/actionTypes';

export const CallSpecialDays = (pointOfSaleId) => ({
  type: CALLSPECIALDAYS,
  payload: { pointOfSaleId },
});

export const setSpecialDays = (spacialDays) => ({
  type: SETSPECIALDAYS,
  payload: spacialDays,
});

export const SaveSpecialDays = (spacialDays, pointOfSaleId) => ({
  type: SAVESPECIALDAYS,
  payload: { spacialDays, pointOfSaleId },
});
