import { REHYDRATE } from 'redux-persist';

// @flow
import {
  ADD_COMMEND_MENU,
  CLEAR_COMMEND_MENU,
  COMMEND_UNIT,
  GET_COMMEND_ROOM,
  GET_COMMEND_ROOM_FAILED,
  GET_COMMEND_ROOM_SUCCESS,
  INSERT_COMMEND_ROOM,
} from '../../constants/actionTypes';

const INIT_STATE = {
  commends: [],
  commendsRoom: [],
  total: 0,
  loading: false,
  error: false,
  unit: {},
  nbrPersonne: 1,
  time: 0,
  user: {},
};

const CommendMenu = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_COMMEND_MENU:
      const has = state.commends.findIndex(
        (elem) => elem._id === action.payload.commend._id,
      );

      let commends_ = state.commends;
      if (has === -1)
        commends_ = [
          ...state.commends,
          { ...action.payload.commend, categoryDelivery: 'aperitif' },
        ];
      else {
        if (action.payload.commend.numberOfPlat === 0) {
          commends_ = commends_.filter(
            (elem) => elem._id !== action.payload.commend._id,
          );
        } else {
          commends_[has] = action.payload.commend;
        }
      }

      let total_ = 0;
      for (let index = 0; index < commends_.length; index++) {
        total_ += commends_[index].numberOfPlat;
      }

      return {
        ...state,
        commends: [...commends_],
        total: total_,
      };
    case CLEAR_COMMEND_MENU:
      return {
        ...state,
        commends: [],
        total: 0,
      };
    case 'CLEAR_COMMEND_ALL':
      return {
        ...state,
        commends: [],
        total: 0,
        loading: false,
        error: false,
        unit: {},
        nbrPersonne: 1,
        time: 0,
        user: {},
      };
    case COMMEND_UNIT:
      return { ...state, unit: action.payload };
    case 'SET_COMMAND_TIME':
      return { ...state, time: action.payload };
    case 'SET_COMMAND_USER':
      return { ...state, user: action.payload };
    case 'NBR_PERSONNE':
      return { ...state, nbrPersonne: action.payload };
    case GET_COMMEND_ROOM:
      return { ...state, loading: true, error: false };
    case GET_COMMEND_ROOM_SUCCESS:
      return {
        ...state,
        commendsRoom: action.payload,
        loading: true,
        error: false,
      };
    case GET_COMMEND_ROOM_FAILED:
      return { ...state, loading: false, error: action.payload };

    case INSERT_COMMEND_ROOM:
      const list_commends = [...state.commendsRoom, action.payload];

      return { ...state, commendsRoom: list_commends };

    case 'SET_CATEGORY_DELIVERY':
      const items = state.commends.map((elem) => {
        if (elem._id === action.payload.commend._id)
          return {
            ...elem,
            categoryDelivery: action.payload.categoryDelivery,
          };
        return elem;
      });

      return { ...state, commends: items };
    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.CommendMenu,
          }
        : {
            ...state,
          };
    default:
      return { ...state };
  }
};

export default CommendMenu;
