// @flow
import {
  GET_TABLES,
  GET_TABLES_SUCCESS,
  GET_TABLES_FAILED,
  EDIT_TABLE,
  SAVE_TABLES,
  EDIT_TABLES_SUCCESS,
  SETLOADINGFALSE,
  GET_TABLES_GLOBAL,
  GET_TABLES_GLOBAL_SUCCESS,
  GET_TABLES_GLOBAL_FAILED,
  GET_TABLE_MENU,
  GET_TABLE_MENU_FAILED,
  GET_TABLE_MENU_SUCCESS,
} from '../../constants/actionTypes';
import { REHYDRATE } from 'redux-persist';
const INIT_STATE = {
  unitsGlobal: [],
  units: [],
  loading: false,
  error: {},
};

const Units = (state = INIT_STATE, action) => {
  let units;
  switch (action.type) {
    case GET_TABLES:
      return {
        ...state,
        loading: action.payload.loading,
        loading: false,
      };
    case GET_TABLES_GLOBAL:
      return {
        ...state,
        loading: action.payload.loading,
        loading: false,
      };
    // case EDIT_TABLE:
    //   units = state.units;
    //   units = units.map((unit) => {
    //     if (unit._id === action.payload._id) {
    //       unit = { ...action.payload.unit };
    //     }
    //     return unit;
    //   });

    //   return { ...state, };
    case EDIT_TABLES_SUCCESS:
      units = state.units;
      units = units.map((unit) => {
        if (unit._id === action.payload.unit._id) {
          unit = { ...action.payload.unit };
        }
        return unit;
      });
      return {
        ...state,
        unitsGlobal: units,
        loading: false,
        error: null,
      };
    case GET_TABLES_SUCCESS:
      return {
        ...state,
        units: action.payload.units,
        loading: false,
        error: null,
      };
    case GET_TABLES_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case GET_TABLES_GLOBAL_SUCCESS:
      return {
        ...state,
        unitsGlobal: action.payload.units,
        loading: false,
        error: null,
      };
    case GET_TABLES_GLOBAL_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case SAVE_TABLES:
      return {
        ...state,
        unitsGlobal: action.payload.units,
        loading: true,
      };
    case SETLOADINGFALSE:
      return { ...state, loading: false };
    case GET_TABLE_MENU:
      return { ...state, loading: false };
    case GET_TABLE_MENU_SUCCESS:
      return { ...state, loading: false };
    case GET_TABLE_MENU_FAILED:
      return { ...state, loading: false };
    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.Units,
          }
        : {
            ...state,
          };
    default:
      return { ...state };
  }
};

export default Units;
