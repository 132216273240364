import {
  getLoggedInUser,
  isUserAuthenticated,
} from './helpers/authUtils';
import Login from './pages/auth/Login';
import Logout from './pages/auth/Logout';
// handle auth and authorization
import store from './redux/store';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import UpxpManagement from "./pages/upxpManagement";
import TableUsers from './pages/SupAdmin/users/index';
import Notification from "./pages/notification/notification";
import QrCodesSpots from "./pages/QrCode/QrCodesSpots";
import Partner from "./pages/partner/Partner";

const PrivateRoute = ({
  component: Component,
  roles,
  categoryChecker,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const isAuthTokenValid = isUserAuthenticated();
      if (!isAuthTokenValid) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }
      const loggedInUser = getLoggedInUser();

      // check if route is restricted by role
      if (roles && roles.indexOf(loggedInUser.role) === -1) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: '/' }} />;
      }

      if (
        categoryChecker &&
        roles?.find((r) => r === 'ROLE_SUPERVISOR') &&
        store?.store?.getState()?.Supervisor?.selectedPointOfSale
          ?.category !== 'restaurant'
      ) {
        return <Redirect to={{ pathname: '/' }} />;
      }

      // authorised so return component
      return <Component {...props} />;
    }}
  />
);

const KoRedirect = () => {
  const loggedInUser = getLoggedInUser();

  switch (loggedInUser.role) {
    case 'ROLE_SUPER_ADMIN':
      return <Redirect to="/admin/upxp-management" />;

  }
};

const routes = [
  { path: '/login', name: 'Login', component: Login, route: Route },
  // {
  //   path: '/book-table-client/:id',
  //   name: 'BookTableClient',
  //   layout: 'BookClientLayout',
  //   component: BookTableClientFork,
  //   route: Route,
  //   title: 'ForkDesign',
  // },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    route: Route,
  },
  // {
  //   path: '/forget-password',
  //   name: 'Forget Password',
  //   component: ForgetPassword,
  //   route: Route,
  // },
  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: Register,
  //   route: Route,
  // },
  // {
  //   path: '/confirm',
  //   name: 'Confirm',
  //   component: ConfirmAccount,
  //   route: Route,
  // },
  // {
  //   path: '/book-table-client/menu/:id/',
  //   name: 'MenuClient',
  //   layout: 'BookClientLayout',
  //   component: MenuClient,
  //   route: Route,
  // },
  // {
  //   path: '/book-table-client/menu/service-room/:id',
  //   name: 'MenuServiceRoom',
  //   layout: 'BookClientLayout',
  //   component: MenuServiceRoom,
  //   route: Route,
  // },
  // {
  //   path: '/book-table-client/command/:idRestaurant/:code?',
  //   name: 'MenuServiceRoom',
  //   layout: 'BookClientLayout',
  //   component: MenuServiceRoom,
  //   route: Route,
  // },
  // {
  //   path: '/book-table-client/command-menu/:idRestaurant/:showMenu',
  //   name: 'MenuServiceRoom',
  //   layout: 'BookClientLayout',
  //   component: MenuServiceRoom,
  //   route: Route,
  // },
  // {
  //   path: '/info/:id',
  //   name: 'restaurant-contact',
  //   layout: 'BookClientLayout',
  //   component: InfoClientRestaurant,
  //   route: Route,
  // },
  // {
  //   path: '/create-admin/:id',
  //   name: 'restaurant-admin',
  //   layout: 'BookClientLayout',
  //   component: CreateAdminClientRestaurant,
  //   route: Route,
  // },
  // {
  //   path: '/website/:id',
  //   name: 'website',
  //   layout: 'BookClientLayout',
  //   component: WebsiteClient,
  //   route: Route,
  // },
  // {
  //   path: '/order/validate_order',
  //   name: 'validate_order',
  //   component: ValidateOrder,
  //   layout: 'MenuClientLayout',
  //   route: Route,
  //   exact: true,
  // },
  // {
  //   path: '/order/login/:idTable',
  //   name: 'validate_order_menu_table',
  //   component: CommandLogin,
  //   layout: 'MenuClientLayout',
  //   route: Route,
  //   exact: true,
  // },
  // {
  //   path: '/book-table-client/restaurant/menu/:id/:code?',
  //   name: 'MenuClient',
  //   layout: 'BookClientLayout',
  //   component: MenuRestaurant,
  //   route: Route,
  // },
  // {
  //   path: '/book-table-client/table/menu/:idTable/:code?',
  //   name: 'MenuTable',
  //   layout: 'BookClientLayout',
  //   component: MenuTable,
  //   route: Route,
  // },
  // {
  //   path: '/book-table-client/restaurant/menu/cart',
  //   name: 'cartClient',
  //   layout: 'BookClientLayout',
  //   component: CartCommand,
  //   route: Route,
  // },
  // {
  //   path: '/reset-password/:idUser/:token',
  //   name: 'resetPassword',
  //   component: ResetPassword,
  //   route: Route,
  // },
  // {
  //   path: '/m/:id/',
  //   name: 'MenuClient',
  //   layout: 'BookClientLayout',
  //   component: MenuClient,
  //   route: Route,
  // },
  // {
  //   path: '/r/:resto/:id',
  //   name: 'MenuClient',
  //   layout: 'BookClientLayout',
  //   component: EditReservation,
  //   route: Route,
  // },
  // {
  //   path: '/s/:id',
  //   name: 'MenuClient',
  //   layout: 'BookClientLayout',
  //   component: SmsView,
  //   route: Route,
  // },
  // {
  //   path: '/inscription/1/:affiliateId?',
  //   name: 'inscription-resto',
  //   layout: 'BookClientLayout',
  //   component: GetStarted,
  //   route: Route,
  // },
  // {
  //   path: '/register/:affiliateId?',
  //   name: 'register-user',
  //   layout: 'BookClientLayout',
  //   component: Register,
  //   route: Route,
  // },
  // {
  //   path: '/activation_account/:id_user/:code',
  //   name: 'inscription-resto',
  //   layout: 'BookClientLayout',
  //   component: ValidationEmil,
  //   route: Route,
  // },
  // other pages
  // { path: '/dashboard', name: 'Dashboard', component: Dashboard, route: PrivateRoute, roles: ['ROLE_ADMIN','ROLE_STAFF'], title: 'Dashboard' },
  // {
  //   path: '/menu',
  //   name: 'ListMenu',
  //   component: ListMenu,
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
  //   title: 'list menu',
  // },
  // {
  //   path: '/menu/show',
  //   name: 'Menu',
  //   component: Menu,
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
  //   title: 'menu',
  // },
  // {
  //   path: '/menu/new',
  //   name: 'Menu',
  //   component: MenuComposer,
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
  //   title: 'menu',
  // },

  // { path: '/users', name: 'Users', component: Users, route: PrivateRoute, roles: ['ROLE_ADMIN'], title: 'user' },
  // { path: '/table-map', name: 'TableMap', component: TableMap, route: PrivateRoute, roles: ['ROLE_ADMIN', 'ROLE_STAFF'], title: 'TableMap' },
  // {
  //   path: '/NewBooking',
  //   name: 'NewBooking',
  //   component: NewBooking,
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN', 'ROLE_STAFF', 'ROLE_MANAGER'],
  //   title: 'NewBooking',
  // },
  // {
  //   path: '/Settings',
  //   name: 'NewBooking',
  //   component: Settings,
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN'],
  //   title: 'Settings',
  // },
  // {
  //   path: '/Timeline',
  //   name: 'NewBooking',
  //   component: Timeline,
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN', 'ROLE_STAFF', 'ROLE_MANAGER'],
  //   title: 'NewBooking',
  // },
  // {
  //   path: '/statistique',
  //   name: 'statistique',
  //   component: statistique,
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN', 'ROLE_SUPERVISOR'],
  //   title: 'statistique',
  // },
  // {
  //   path: '/Devices',
  //   name: 'listDevices',
  //   component: DevicesPage,
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN'],
  //   title: 'liste devices',
  // },
  // {
  //   path: '/clients',
  //   name: 'listClients',
  //   component: ClientPage,
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN'],
  //   title: 'list clients',
  // },
  // {
  //   path: '/website',
  //   name: 'website',
  //   component: Website,
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN'],
  //   title: 'website',
  // },
  // {
  //   path: '/newsletter',
  //   name: 'newsletter',
  //   component: NewsletterPage,
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN'],
  //   title: 'newsletter',
  // },
  //
  // {
  //   path: '/contacts',
  //   name: 'Contact',
  //   component: ContactPage,
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN'],
  //   title: 'Contact',
  // },
  // {
  //   path: '/management',
  //   name: 'Management',
  //   component: Management,
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN'],
  //   title: 'Management',
  // },
  // sup admins pages
  // {
  //   path: '/admin/restaurants',
  //   name: 'Restaurants',
  //   component: Restaurant,
  //   layout: 'SuperAdminLayout',
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_ASSISTANCE'],
  //   title: '',
  // },
  // {
  //   path: '/admin/task',
  //   name: 'task',
  //   component: TicketsList,
  //   layout: 'SuperAdminLayout',
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
  //   title: '',
  // },
  // {
  //   path: '/admin/company',
  //   name: 'Restaurants',
  //   component: Company,
  //   layout: 'SuperAdminLayout',
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
  //   title: '',
  // },
  // {
  //   path: '/admin/contacts',
  //   name: 'Restaurants',
  //   component: Contact,
  //   layout: 'SuperAdminLayout',
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_ASSISTANCE'],
  //   title: '',
  // },
  {
    path: '/admin/users',
    name: 'Users',
    component: TableUsers,
    layout: 'SuperAdminLayout',
    route: PrivateRoute,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    title: '',
  },
    {
    path: '/admin/partner',
    name: 'partner',
    component: Partner,
    layout: 'SuperAdminLayout',
    route: PrivateRoute,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    title: '',
  },
  // {
  //   path: '/admin/users/all',
  //   name: 'users-list',
  //   component: Users,
  //   layout: 'SuperAdminLayout',
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
  //   title: '',
  // },
  // {
  //   path: '/admin/devices',
  //   name: 'Users',
  //   component: Devices,
  //   layout: 'SuperAdminLayout',
  //   route: PrivateRoute,
  //   roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
  //   title: '',
  // },
  // {
  //   path: '/admin/dashboard',
  //   name: 'admin_dashboard',
  //   component: Dashboard,
  //   layout: 'SuperAdminLayout',
  //   route: PrivateRoute,
  //   roles: ['ROLE_SUPER_ADMIN'],
  //   title: '',
  // },
  // {
  //   path: '/admin/notification',
  //   name: 'admin_notification',
  //   component: Notification,
  //   layout: 'SuperAdminLayout',
  //   route: PrivateRoute,
  //   roles: ['ROLE_SUPER_ADMIN'],
  //   title: '',
  // },
  // {
  //   path: '/admin/ambassadors',
  //   name: 'admin_ambassadors',
  //   component: Ambassadors,
  //   layout: 'SuperAdminLayout',
  //   route: PrivateRoute,
  //   roles: ['ROLE_SUPER_ADMIN'],
  //   title: '',
  // },
  // {
  //   path: '/admin/influencers',
  //   name: 'admin_influencers',
  //   component: Influencers,
  //   layout: 'SuperAdminLayout',
  //   route: PrivateRoute,
  //   roles: ['ROLE_SUPER_ADMIN'],
  //   title: '',
  // },
  {
    path: '/admin/upxp-management',
    name: 'admin_upxpManagement',
    component: UpxpManagement,
    layout: 'SuperAdminLayout',
    route: PrivateRoute,
    roles: ['ROLE_SUPER_ADMIN'],
    title: '',
  },
{
    path: '/admin/notifications',
    name: 'admin_notifications',
    component: Notification,
    layout: 'SuperAdminLayout',
    route: PrivateRoute,
    roles: ['ROLE_SUPER_ADMIN'],
    title: '',
  },
    {
        path: '/admin/qr-code',
        name: 'admin_notifications',
        component: QrCodesSpots,
        layout: 'SuperAdminLayout',
        route: PrivateRoute,
        roles: ['ROLE_SUPER_ADMIN'],
        title: '',
    },
  // supervisor pages
  // {
  //   path: '/supervisor',
  //   name: 'Restaurants',
  //   component: SupervisorIndex,
  //   layout: 'SupervisorLayout',
  //   showContent: false,
  //   route: PrivateRoute,
  //   roles: ['ROLE_SUPERVISOR'],
  //   title: '',
  // },
  // {
  //   path: '/supervisor/checking',
  //   name: 'Restaurants',
  //   component: SupervisorChecking,
  //   layout: 'SupervisorLayout',
  //   showContent: false,
  //   route: PrivateRoute,
  //   roles: ['ROLE_SUPERVISOR'],
  //   title: '',
  // },
  // {
  //   path: '/supervisor/Devices',
  //   name: 'listDevices',
  //   component: DevicesPage,
  //   route: PrivateRoute,
  //   layout: 'SupervisorLayout',
  //   roles: ['ROLE_SUPERVISOR'],
  //   title: 'liste devices',
  //   categoryChecker: true,
  // },
  // {
  //   path: '/supervisor/statistique',
  //   name: 'statistiqueSupervisor',
  //   component: statistique,
  //   layout: 'SupervisorLayout',
  //   route: PrivateRoute,
  //   roles: ['ROLE_SUPERVISOR'],
  //   title: 'statistique',
  //   categoryChecker: true,
  // },
  // {
  //   path: '/supervisor/menu',
  //   name: 'menuSupervisor',
  //   component: ListMenu,
  //   route: PrivateRoute,
  //   layout: 'SupervisorLayout',
  //   roles: ['ROLE_SUPERVISOR'],
  //   title: 'list menu',
  // },
  // {
  //   path: '/supervisor/menu/show',
  //   name: 'Menu',
  //   component: Menu,
  //   route: PrivateRoute,
  //   layout: 'SupervisorLayout',
  //   roles: ['ROLE_SUPERVISOR'],
  //   title: 'menu',
  // },
  // {
  //   path: '/supervisor/Settings',
  //   name: 'settings',
  //   component: Settings,
  //   layout: 'SupervisorLayout',
  //   route: PrivateRoute,
  //   roles: ['ROLE_SUPERVISOR'],
  //   title: 'Settings',
  // },
  // {
  //   path: '/supervisor/NewBooking',
  //   name: 'NewBooking',
  //   component: NewBooking,
  //   layout: 'SupervisorLayout',
  //   route: PrivateRoute,
  //   roles: ['ROLE_SUPERVISOR'],
  //   title: 'NewBooking',
  // },
  // {
  //   path: '/supervisor/newsletter',
  //   name: 'NewBooking',
  //   component: NewsletterPage,
  //   layout: 'SupervisorLayout',
  //   route: PrivateRoute,
  //   roles: ['ROLE_SUPERVISOR'],
  //   title: 'NewBooking',
  //   categoryChecker: true,
  // },
  // {
  //   path: '/supervisor/contacts',
  //   name: 'Contact',
  //   component: ContactPage,
  //   layout: 'SupervisorLayout',
  //   route: PrivateRoute,
  //   roles: ['ROLE_SUPERVISOR'],
  //   title: 'Contact',
  //   categoryChecker: true,
  // },
  // {
  //   path: '/supervisor/management',
  //   name: 'Management',
  //   component: Management,
  //   layout: 'SupervisorLayout',
  //   route: PrivateRoute,
  //   roles: ['ROLE_SUPERVISOR'],
  //   title: 'Management',
  //   categoryChecker: true,
  // },
  // {
  //   path: '/supervisor/clients',
  //   name: 'listClients',
  //   component: ClientPage,
  //   layout: 'SupervisorLayout',
  //   route: PrivateRoute,
  //   roles: ['ROLE_SUPERVISOR'],
  //   title: 'list clients',
  //   categoryChecker: true,
  // },
  // {
  //   path: '/supervisor/website',
  //   name: 'website',
  //   component: Website,
  //   layout: 'SupervisorLayout',
  //   route: PrivateRoute,
  //   roles: ['ROLE_SUPERVISOR'],
  //   title: 'website',
  //   categoryChecker: true,
  // },
  {
    path: '/',
    exact: true,
    component: () => <KoRedirect />,
    route: PrivateRoute,
  },
];

export { routes, PrivateRoute };
