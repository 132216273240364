import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { GET_CLICK_COLLECT_TIME } from '../../constants/actionTypes';
import { getTimeFailed, getTimeSuccess } from './actions';
import { getTimeService } from './service';

function* getAvailableTime({ payload: { pointOfSaleId } }) {
  try {
    const response = yield call(getTimeService, pointOfSaleId);
    yield put(getTimeSuccess(response.times));
  } catch (error) {
    yield put(getTimeFailed(error));
  }
}

export function* watchGetAvailableTime() {
  yield takeEvery(GET_CLICK_COLLECT_TIME, getAvailableTime);
}

function* ClickCollectSaga() {
  yield all([fork(watchGetAvailableTime)]);
}

export default ClickCollectSaga;
