// @flow
import {
  GET_MENU,
  GET_MENU_SUCCESS,
  GET_MENU_FAILED,
  ADD_MENU,
  ADD_MENU_SUCCESS,
  ADD_MENU_FAILED,
  DELETE_MENU,
  DELETE_MENU_SUCCESS,
  DELETE_MENU_FAILED,
  SELECTED_MENU,
  PRIMARY_MENU,
  PRIMARY_MENU_SUCCESS,
  PRIMARY_MENU_FAILED,
  GET_MENU_CLIENT,
  GET_MENU_CLIENT_SUCCESS,
  GET_MENU_CLIENT_FAILED,
  GET_SETTINGS_MENU,
  GET_SETTINGS_MENU_SUCCESS,
  GET_SETTINGS_MENU_FAILED,
  SAVE_SETTINGS_MENU,
  SAVE_SETTINGS_MENU_SUCCESS,
  SAVE_SETTINGS_MENU_FAILED,
  DELETE_MENU_ITEM,
  DELETE_MENU_ITEM_SUCCESS,
  DELETE_MENU_ITEM_FAILED,
} from '../../constants/actionTypes';

export const getMenu = (pointOfSaleId) => ({
  type: GET_MENU,
  payload: { pointOfSaleId },
});

export const getMenuSuccess = (menu) => ({
  type: GET_MENU_SUCCESS,
  payload: menu,
});

export const getMenuFailed = (error) => ({
  type: GET_MENU_FAILED,
  payload: error,
});

export const addMenu = (
  menu,
  pointOfSaleId,
  onSave = () => null,
) => ({
  type: ADD_MENU,
  payload: { menu, pointOfSaleId, onSave },
});

export const addMenuSuccess = (menu) => ({
  type: ADD_MENU_SUCCESS,
  payload: menu,
});

export const addMenuFailed = (error) => ({
  type: ADD_MENU_FAILED,
  payload: error,
});

export const deleteMenu = (menuCategories, categoryItems) => ({
  type: DELETE_MENU,
  payload: { menuCategories, categoryItems },
});

export const deleteMenuSuccess = (menu) => ({
  type: DELETE_MENU_SUCCESS,
  payload: menu,
});

export const deleteMenuFailed = (error) => ({
  type: DELETE_MENU_FAILED,
  payload: error,
});

export const deleteMenuItem = (menuCategories, categoryItems) => ({
  type: DELETE_MENU_ITEM,
  payload: { menuCategories, categoryItems },
});

export const deleteMenuItemSuccess = (
  menuCategories,
  categoryItems,
) => ({
  type: DELETE_MENU_ITEM_SUCCESS,
  payload: { menuCategories, categoryItems },
});

export const deleteMenuItemFailed = (error) => ({
  type: DELETE_MENU_ITEM_FAILED,
  payload: error,
});

export const selectedMenu = (SelectedMenu) => ({
  type: SELECTED_MENU,
  payload: SelectedMenu,
});

export const primaryMenu = (id, pointOfSaleId) => ({
  type: PRIMARY_MENU,
  payload: { id, pointOfSaleId },
});

export const primaryMenuSuccess = (menus) => ({
  type: PRIMARY_MENU_SUCCESS,
  payload: menus,
});

export const primaryMenuFailed = (error) => ({
  type: PRIMARY_MENU_FAILED,
  payload: error,
});

export const getMenuClient = (id) => ({
  type: GET_MENU_CLIENT,
  payload: { id },
});

export const getMenuClientSuccess = (menu) => ({
  type: GET_MENU_CLIENT_SUCCESS,
  payload: menu,
});

export const getMenuClientFailed = (error) => ({
  type: GET_MENU_CLIENT_FAILED,
  payload: error,
});

export const getSettingsMenu = (id) => ({
  type: GET_SETTINGS_MENU,
  payload: { id },
});

export const getSettingsMenuSuccess = (setting) => ({
  type: GET_SETTINGS_MENU_SUCCESS,
  payload: setting,
});

export const getSettingsMenuFailed = (error) => ({
  type: GET_SETTINGS_MENU_FAILED,
  payload: error,
});

export const saveSettingsMenu = (id, data) => ({
  type: SAVE_SETTINGS_MENU,
  payload: { id, data },
});

export const seveSettingsMenuSuccess = (setting) => ({
  type: SAVE_SETTINGS_MENU_SUCCESS,
  payload: setting,
});

export const saveSettingsMenuFailed = (error) => ({
  type: SAVE_SETTINGS_MENU_FAILED,
  payload: error,
});
