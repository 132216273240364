import { REHYDRATE } from 'redux-persist';

// @flow
import {
  AUTH_EDIT_RESTAURANT,
  FORGET_PASSWORD,
  FORGET_PASSWORD_FAILED,
  FORGET_PASSWORD_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_FAILED,
  REGISTER_USER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  USER_UPDATE_SUCCESS,
  SETNEWLANGAGUE,
} from '../../constants/actionTypes';
import { getLoggedInUser, getUser } from '../../helpers/authUtils';

const INIT_STATE = {
  user: getUser(),
  loading: false,
  resetSuccess: false,
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null,
      };
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          phone: action.payload.phone,
          avatar: action.payload.avatar,
        },
        loading: false,
        error: null,
      };
    case LOGIN_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case REGISTER_USER:
      return { ...state, loading: true };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null,
      };
    case AUTH_EDIT_RESTAURANT:
      return {
        ...state,
        user: { ...state.user, ownedPointOfSales: action.payload },
        loading: false,
        error: null,
      };
    case REGISTER_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case LOGOUT_USER:
      return { ...state, user: null };
    case FORGET_PASSWORD:
      return { ...state, loading: true };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetStatus: action.payload,
        loading: false,
        error: null,
      };
    case FORGET_PASSWORD_FAILED:
      return { ...state, error: action.payload, loading: false };

    case RESET_PASSWORD:
      return { ...state, loading: true, resetSuccess: false };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        resetSuccess: true,
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
        resetSuccess: false,
      };
    case 'SET_SHOW_TOUR':
      return {
        ...state,
        user: { ...state.user, idTour: action.payload },
      };
    case SETNEWLANGAGUE:
      return {
        ...state,
        user: { ...state.user, lang: action.payload },
      };

    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.Auth,
          }
        : {
            ...state,
          };
    default:
      return { ...state };
  }
};

export default Auth;
