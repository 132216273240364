// @flow
import {
  ADD_DEVICES_TO_RESUTRANT,
  ADD_DEVICES_TO_RESUTRANT_FAILED,
  ADD_DEVICES_TO_RESUTRANT_SUCCESS,
  CALLRESUTRANTDEVICES,
  GET_DEVICES_NOT_RESUTRANT,
  GET_DEVICES_NOT_RESUTRANT_FAILED,
  GET_DEVICES_NOT_RESUTRANT_SUCCESS,
  SETRESTURANTDEVICES,
  AFFECTATION_MENU_DEVICE,
  AFFECTATION_MENU_DEVICE_SUCCESS,
  AFFECTATION_MENU_DEVICE_FAILED,
} from '../../constants/actionTypes';

export const CallDevices = (id) => ({
  type: CALLRESUTRANTDEVICES,
  payload: { id },
});

export const setDevices = (payload) => ({
  type: SETRESTURANTDEVICES,
  payload: payload,
});

export const getDevicesNotResutrant = () => ({
  type: GET_DEVICES_NOT_RESUTRANT,
  payload: null,
});
export const getDevicesNotResutrantSuccess = (devices) => ({
  type: GET_DEVICES_NOT_RESUTRANT_SUCCESS,
  payload: devices,
});
export const getDevicesNotResutrantFailed = (error) => ({
  type: GET_DEVICES_NOT_RESUTRANT_FAILED,
  payload: error,
});

export const affectationMenuToDevice = (id, idMenu, callback) => ({
  type: AFFECTATION_MENU_DEVICE,
  payload: { id, idMenu, callback },
});
export const affectationMenuToDeviceSuccess = (device) => ({
  type: AFFECTATION_MENU_DEVICE_SUCCESS,
  payload: device,
});
export const affectationMenuToDeviceFailed = (error) => ({
  type: AFFECTATION_MENU_DEVICE_FAILED,
  payload: error,
});

export const addDevicesToResutrant = (
  idDevice,
  idResto,
  handleClose,
) => ({
  type: ADD_DEVICES_TO_RESUTRANT,
  payload: { idDevice, idResto, handleClose },
});
export const addDevicesToResutrantSuccess = (device) => ({
  type: ADD_DEVICES_TO_RESUTRANT_SUCCESS,
  payload: device,
});
export const addDevicesToResutrantFailed = (error) => ({
  type: ADD_DEVICES_TO_RESUTRANT_FAILED,
  payload: error,
});
