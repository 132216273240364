import {
  httpClient_get,
  httpClient_post,
  httpClient_put,
  httpClient_delete,
} from '../../helpers/api';

const getWebsiteService = (pointOfSaleId) => {
  return httpClient_post('/websites', { pointOfSaleId })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const updateWebsiteGeneralService = ({
  pointOfSaleId,
  primaryColor,
  primaryFont,
  secondaryColor,
  secondaryFont,
  facebookLink,
  instagramLink,
}) => {
  console.log(
    ' ++++++++++++++++++++',
    pointOfSaleId,
    primaryColor,
    primaryFont,
    secondaryColor,
    secondaryFont,
    facebookLink,
    instagramLink,
  );
  return httpClient_put('/websites/general', {
    pointOfSaleId,
    primaryColor,
    primaryFont,
    secondaryColor,
    secondaryFont,
    linkFacebook: facebookLink,
    linkInstagram: instagramLink,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const updateWebsiteNewsletterService = ({
  pointOfSaleId,
  newsletterTitle,
}) => {
  return httpClient_put('/websites/newsletter', {
    pointOfSaleId,
    newsletterTitle,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const updateWebsiteMenuService = ({
  pointOfSaleId,
  menuSubTitle,
  menuTitle,
}) => {
  return httpClient_put('/websites/menu', {
    pointOfSaleId,
    menuSubTitle,
    menuTitle,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const updateWebsiteHeaderService = ({
  pointOfSaleId,
  backgroundImageHeader,
}) => {
  try {
    const formData = new FormData();

    if (pointOfSaleId)
      formData.append('pointOfSaleId', pointOfSaleId);
    formData.append('backgroundImageHeader', backgroundImageHeader);

    return httpClient_put('/websites/header', formData, {
      'Content-Type': 'multipart/form-data',
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    console.log(error);
  }
};

const updateWebsiteChefWordService = ({
  pointOfSaleId,
  chefPhoto,
  chefWord,
  chefName,
}) => {
  try {
    const formData = new FormData();

    if (pointOfSaleId)
      formData.append('pointOfSaleId', pointOfSaleId);
    formData.append('chefPhoto', chefPhoto);
    formData.append('chefWord', chefWord);
    formData.append('chefName', chefName);

    return httpClient_put('/websites/chef', formData, {
      'Content-Type': 'multipart/form-data',
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    console.log(error);
  }
};

const updateWebsiteHistoryService = ({
  pointOfSaleId,
  historyPhoto,
  historyWord,
  historyTitle,
}) => {
  try {
    const formData = new FormData();

    if (pointOfSaleId)
      formData.append('pointOfSaleId', pointOfSaleId);
    formData.append('historyPhoto', historyPhoto);
    formData.append('historyWord', historyWord);
    formData.append('historyTitle', historyTitle);

    return httpClient_put('/websites/history', formData, {
      'Content-Type': 'multipart/form-data',
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    console.log(error);
  }
};

const updateWebsiteGalleryService = ({
  pointOfSaleId,
  galleryTitle,
  gallerySubTitle,
  gallery,
}) => {
  try {
    const formData = new FormData();

    if (pointOfSaleId)
      formData.append('pointOfSaleId', pointOfSaleId);
    formData.append('galleryTitle', galleryTitle);
    formData.append('gallerySubTitle', gallerySubTitle);

    for (const file of gallery) {
      formData.append('gallery', file);
    }
    return httpClient_put('/websites/gallery', formData, {
      'Content-Type': 'multipart/form-data',
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    console.log(error);
  }
};
const deleteGalleryItemService = ({ pointOfSaleId, photoId }) => {
  try {
    console.log('pointOfSaleId, photoId', pointOfSaleId, photoId);
    return httpClient_delete(
      `/websites/photo/${pointOfSaleId}/${photoId}`,
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    console.log(error);
  }
};

export {
  getWebsiteService,
  updateWebsiteGeneralService,
  updateWebsiteNewsletterService,
  updateWebsiteMenuService,
  updateWebsiteHeaderService,
  updateWebsiteHistoryService,
  updateWebsiteChefWordService,
  updateWebsiteGalleryService,
  deleteGalleryItemService,
};
