import { httpClient_get, httpClient_post } from '../../helpers/api';
import moment from 'moment';
const RetriveSpecialDays = async (pointOfSaleId) => {
  const result = await httpClient_post(
    '/pointofsale/Get-Special-schedule',
    {
      pointOfSaleId,
    },
  );
  // console.log(result)
  return result;
};

const saveSpecialDays = async (SpecialDays, pointOfSaleId) => {
  for (let i = 0; i < SpecialDays.length; i++) {
    SpecialDays[i].dayName = moment(SpecialDays[i].dayName);
  }
  const result = await httpClient_post(
    '/pointofsale/save-Special-schedule',
    {
      SpecialDays,
      pointOfSaleId,
    },
  );
  // console.log(result)
  return result;
};

export { RetriveSpecialDays, saveSpecialDays };
