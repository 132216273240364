import { httpClient_get, httpClient_post } from '../../helpers/api';

const loginService = (email, password) => {
  return httpClient_post('/auth', {
    email: email,
    password: password,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log('-----error------', error.response);
      throw error.response.data;
    });
};

const forgotPasswordService = (email) => {
  return httpClient_post('/auth/forgot-password', {
    email: email,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log('-----error------', error.response);
      throw error.response.data;
    });
};

const resetPasswordService = (
  password,
  r_password,
  id_user,
  token,
) => {
  return httpClient_post(`/auth/new-password/${id_user}/${token}`, {
    newPassword: password,
    confirmPassword: r_password,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
};
const changeLanguageService = (lang) => {
  console.log({ lang });
  return httpClient_post(`/users//change-language`, {
    lang,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
};
export {
  loginService,
  forgotPasswordService,
  resetPasswordService,
  changeLanguageService,
};
