// @flow
import {
  EDIT_PRODUCT_SHOW_IN_POS_STATUS,
  EDIT_PRODUCT_SHOW_IN_POS_STATUS_FAILED,
  EDIT_PRODUCT_SHOW_IN_POS_STATUS_SUCCESS,
  GET_CATEGORY_with_PRODUCT,
  GET_CATEGORY_with_PRODUCT_FAILED,
  GET_CATEGORY_with_PRODUCT_SUCCESS,
} from '../../constants/actionTypes';
import {
  CREATE_INGREDIENT,
  CREATE_INGREDIENT_FAILED,
  CREATE_INGREDIENT_SUCCESS,
  CREATE_PRODUCT,
  CREATE_PRODUCT_CATEGORY,
  CREATE_PRODUCT_CATEGORY_FAILED,
  CREATE_PRODUCT_CATEGORY_SUCCESS,
  CREATE_PRODUCT_FAILED,
  CREATE_PRODUCT_SUCCESS,
  EDIT_INGREDIENT,
  EDIT_INGREDIENT_FAILED,
  EDIT_INGREDIENT_SUCCESS,
  EDIT_PRODUCT,
  EDIT_PRODUCT_CATEGORY,
  EDIT_PRODUCT_CATEGORY_FAILED,
  EDIT_PRODUCT_CATEGORY_SUCCESS,
  EDIT_PRODUCT_FAILED,
  EDIT_PRODUCT_SUCCESS,
  GET_INGREDIENT,
  GET_INGREDIENT_FAILED,
  GET_INGREDIENT_SUCCESS,
  GET_PRODUCT,
  GET_PRODUCT_CATEGORY,
  GET_PRODUCT_CATEGORY_FAILED,
  GET_PRODUCT_CATEGORY_SUCCESS,
  GET_PRODUCT_FAILED,
  GET_PRODUCT_SUCCESS,
} from '../../constants/actionTypes';

export const getProductCategory = (pointOfSaleId) => ({
  type: GET_PRODUCT_CATEGORY,
  payload: { pointOfSaleId },
});

export const getProductCategorySuccess = (productCategory) => ({
  type: GET_PRODUCT_CATEGORY_SUCCESS,
  payload: productCategory,
});

export const getProductCategoryFailed = (error) => ({
  type: GET_PRODUCT_CATEGORY_FAILED,
  payload: error,
});

export const getCategoryWithProduct = (pointOfSaleId) => ({
  type: GET_CATEGORY_with_PRODUCT,
  payload: { pointOfSaleId },
});

export const getCategoryWithProductSuccess = (productCategory) => ({
  type: GET_CATEGORY_with_PRODUCT_SUCCESS,
  payload: productCategory,
});

export const getCategoryWithProductFailed = (error) => ({
  type: GET_CATEGORY_with_PRODUCT_FAILED,
  payload: error,
});

export const setCategoryProductPosShowStatus = (productCategory) => ({
  type: EDIT_PRODUCT_SHOW_IN_POS_STATUS,
  payload: { productCategory },
});

export const setCategoryProductPosShowStatusSuccess = (
  productCategory,
) => ({
  type: EDIT_PRODUCT_SHOW_IN_POS_STATUS_SUCCESS,
  payload: productCategory,
});

export const setCategoryProductPosShowStatusFailed = (error) => ({
  type: EDIT_PRODUCT_SHOW_IN_POS_STATUS_FAILED,
  payload: error,
});

export const createProductCategory = (
  {
    pointOfSaleId,
    categoryName,
    categoryType,
    unit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
    subCategories,
  },
  handleClose,
) => ({
  type: CREATE_PRODUCT_CATEGORY,
  payload: {
    pointOfSaleId,
    categoryName,
    categoryType,
    unit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
    handleClose,
    subCategories,
  },
});

export const createProductCategorySuccess = (productCategory) => ({
  type: CREATE_PRODUCT_CATEGORY_SUCCESS,
  payload: productCategory,
});

export const createProductCategoryFailed = (error) => ({
  type: CREATE_PRODUCT_CATEGORY_FAILED,
  payload: error,
});

export const editProductCategory = (
  {
    productCategoryId,
    categoryName,
    categoryType,
    unit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
  },
  handleClose,
) => ({
  type: EDIT_PRODUCT_CATEGORY,
  payload: {
    productCategoryId,
    categoryName,
    categoryType,
    unit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
    handleClose,
  },
});

export const editProductCategorySuccess = (productCategory) => ({
  type: EDIT_PRODUCT_CATEGORY_SUCCESS,
  payload: productCategory,
});

export const editProductCategoryFailed = (error) => ({
  type: EDIT_PRODUCT_CATEGORY_FAILED,
  payload: error,
});

export const getIngredient = (pointOfSaleId) => ({
  type: GET_INGREDIENT,
  payload: { pointOfSaleId },
});

export const getIngredientSuccess = (ingredient) => ({
  type: GET_INGREDIENT_SUCCESS,
  payload: ingredient,
});

export const getIngredientFailed = (error) => ({
  type: GET_INGREDIENT_FAILED,
  payload: error,
});

export const editIngredient = (
  {
    ingredientId,
    ingredientName,
    ingredientType,
    sellUnit,
    purchaseUnit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
    sellPrice,
    buyingPrice,
    suppliers,
    image,
    productCategory,
  },
  handleClose,
) => ({
  type: EDIT_INGREDIENT,
  payload: {
    ingredientId,
    ingredientName,
    ingredientType,
    sellUnit,
    purchaseUnit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
    sellPrice,
    buyingPrice,
    image,
    productCategory,
    suppliers,
    handleClose,
  },
});

export const editIngredientSuccess = (ingredient) => ({
  type: EDIT_INGREDIENT_SUCCESS,
  payload: ingredient,
});

export const editIngredientFailed = (error) => ({
  type: EDIT_INGREDIENT_FAILED,
  payload: error,
});

export const createIngredient = (
  {
    pointOfSaleId,
    ingredientName,
    ingredientType,
    sellUnit,
    purchaseUnit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
    sellPrice,
    buyingPrice,
    suppliers,
    image,
    productCategory,
    stockInitial,
  },
  handleClose,
) => ({
  type: CREATE_INGREDIENT,
  payload: {
    pointOfSaleId,
    ingredientName,
    ingredientType,
    sellUnit,
    purchaseUnit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
    sellPrice,
    buyingPrice,
    image,
    productCategory,
    suppliers,
    handleClose,
    stockInitial,
  },
});

export const createIngredientSuccess = (ingredient) => ({
  type: CREATE_INGREDIENT_SUCCESS,
  payload: ingredient,
});

export const createIngredientFailed = (error) => ({
  type: CREATE_INGREDIENT_FAILED,
  payload: error,
});

export const getProducts = (pointOfSaleId) => ({
  type: GET_PRODUCT,
  payload: { pointOfSaleId },
});

export const getProductsSuccess = (products) => ({
  type: GET_PRODUCT_SUCCESS,
  payload: products,
});

export const getProductsFailed = (error) => ({
  type: GET_PRODUCT_FAILED,
  payload: error,
});

export const createProduct = (
  {
    pointOfSaleId,
    Itemtype,
    unit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
    desc,
    details,
    extras,
    itemName,
    ingredients,
    purchaseTypes,
    preparationTime,
    price,
    productCategory,
    productNature,
    productType,
    image,
    suppliers,
    content,
    stockInitial,
  },
  handleClose,
) => ({
  type: CREATE_PRODUCT,
  payload: {
    pointOfSaleId,
    Itemtype,
    itemName,
    unit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
    desc,
    details,
    extras,
    ingredients,
    purchaseTypes,
    preparationTime,
    price,
    productCategory,
    productNature,
    productType,
    image,
    suppliers,
    content,
    handleClose,
    stockInitial,
  },
});

export const createProductSuccess = (product) => ({
  type: CREATE_PRODUCT_SUCCESS,
  payload: product,
});

export const createProductFailed = (error) => ({
  type: CREATE_PRODUCT_FAILED,
  payload: error,
});

export const editProduct = (
  {
    productId,
    Itemtype,
    unit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
    desc,
    details,
    extras,
    itemName,
    ingredients,
    purchaseTypes,
    preparationTime,
    price,
    productCategory,
    productNature,
    productType,
    image,
    suppliers,
    content,
  },
  handleClose,
) => ({
  type: EDIT_PRODUCT,
  payload: {
    productId,
    Itemtype,
    itemName,
    unit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
    desc,
    details,
    extras,
    ingredients,
    purchaseTypes,
    preparationTime,
    price,
    productCategory,
    productNature,
    productType,
    image,
    suppliers,
    content,
    handleClose,
  },
});

export const editProductSuccess = (product) => ({
  type: EDIT_PRODUCT_SUCCESS,
  payload: product,
});

export const editProductFailed = (error) => ({
  type: EDIT_PRODUCT_FAILED,
  payload: error,
});
