import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_RESTO } from '../../constants/actionTypes';
import { getSupervisorRestaurantsService } from './service';
import {
  getSupervisorRestaurantsSuccess,
  getSupervisorRestaurantsFailed,
} from './actions';

function* getRestaurants() {
  try {
    const response = yield call(getSupervisorRestaurantsService);
    yield put(getSupervisorRestaurantsSuccess(response.pointOfSales));
  } catch (error) {
    yield put(getSupervisorRestaurantsFailed(error));
  }
}

export function* watchGetRestaurants() {
  yield takeEvery(GET_RESTO, getRestaurants);
}

function* supervisorSaga() {
  yield all([fork(watchGetRestaurants)]);
}

export default supervisorSaga;
