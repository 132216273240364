import { REHYDRATE } from 'redux-persist';

import {
  ADD_ADMIN_CONTACT,
  EDIT_ADMIN_CONTACT,
  GET_ADMIN_ALL_CONTACT,
  GET_ADMIN_ALL_CONTACT_FAILED,
  GET_ADMIN_ALL_CONTACT_SUCCESS,
} from '../../../constants/actionTypes';

const INIT_STATE = {
  loading: false,
  error: false,
  contacts: [],
};

const SupAdminContacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADMIN_ALL_CONTACT:
      return { ...state, loading: true };
    case GET_ADMIN_ALL_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: action.payload,
        loading: false,
        error: null,
      };
    case GET_ADMIN_ALL_CONTACT_FAILED:
      return { ...state, error: action.payload, loading: false };

    case ADD_ADMIN_CONTACT:
      // const {email} =  action.payload;
      // const email_ =  email ? email : phone.replace(/\s/g, '')+"@upxp.pro";
      // console.log("-----email_----",email_)
      return {
        ...state,
        contacts: [...state.contacts, action.payload],
      };

    case EDIT_ADMIN_CONTACT:
      return {
        ...state,
        contacts: state.contacts.map((elem) =>
          elem._id === action.payload.contact_id
            ? { ...elem, ...action.payload }
            : elem,
        ),
      };

    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.Users,
          }
        : {
            ...state,
          };
    default:
      return { ...state };
  }
};

export default SupAdminContacts;
