import { httpClient_get, httpClient_put } from '../../../helpers/api';

const getAllCompanyService = () => {
  return httpClient_get(`/company/all`)
    .then((response) => {
      return response.data.companys;
    })
    .catch((error) => {
      throw error;
    });
};

export { getAllCompanyService };
