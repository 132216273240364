// @flow
import {
  GET_WEBSITE,
  GET_WEBSITE_FAILED,
  GET_WEBSITE_SUCCESS,
  UPDATE_WEBSITE_GENERAL,
  UPDATE_WEBSITE_GENERAL_SUCCESS,
  UPDATE_WEBSITE_GENERAL_FAILED,
  UPDATE_WEBSITE_NEWSLETTER,
  UPDATE_WEBSITE_NEWSLETTER_SUCCESS,
  UPDATE_WEBSITE_NEWSLETTER_FAILED,
  UPDATE_WEBSITE_MENU,
  UPDATE_WEBSITE_MENU_SUCCESS,
  UPDATE_WEBSITE_MENU_FAILED,
  UPDATE_WEBSITE_HEADER,
  UPDATE_WEBSITE_HEADER_SUCCESS,
  UPDATE_WEBSITE_HEADER_FAILED,
  UPDATE_WEBSITE_CHEF_WORD,
  UPDATE_WEBSITE_CHEF_WORD_SUCCESS,
  UPDATE_WEBSITE_CHEF_WORD_FAILED,
  UPDATE_WEBSITE_HISTORY,
  UPDATE_WEBSITE_HISTORY_SUCCESS,
  UPDATE_WEBSITE_HISTORY_FAILED,
  UPDATE_WEBSITE_GALLERY,
  UPDATE_WEBSITE_GALLERY_SUCCESS,
  UPDATE_WEBSITE_GALLERY_FAILED,
  DELETE_GALLERY_ITEM,
  DELETE_GALLERY_ITEM_SUCCESS,
  DELETE_GALLERY_ITEM_FAILED,
} from '../../constants/actionTypes';
import { REHYDRATE } from 'redux-persist';
const INIT_STATE = {
  website: {},
  pointOfSale: {},
  loading: false,
  error: false,
};

const Website = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_WEBSITE_GENERAL:
      return { ...state, loading: true };
    case UPDATE_WEBSITE_GENERAL_SUCCESS:
      return {
        ...state,
        website: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_WEBSITE_GENERAL_FAILED:
      return { ...state, error: action.payload, loading: false };

    case UPDATE_WEBSITE_NEWSLETTER:
      return { ...state, loading: true };
    case UPDATE_WEBSITE_NEWSLETTER_SUCCESS:
      return {
        ...state,
        website: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_WEBSITE_NEWSLETTER_FAILED:
      return { ...state, error: action.payload, loading: false };

    case UPDATE_WEBSITE_MENU:
      return { ...state, loading: true };
    case UPDATE_WEBSITE_MENU_SUCCESS:
      return {
        ...state,
        website: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_WEBSITE_MENU_FAILED:
      return { ...state, error: action.payload, loading: false };

    case UPDATE_WEBSITE_HEADER:
      return { ...state, loading: true };
    case UPDATE_WEBSITE_HEADER_SUCCESS:
      return {
        ...state,
        website: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_WEBSITE_HEADER_FAILED:
      return { ...state, error: action.payload, loading: false };

    case UPDATE_WEBSITE_CHEF_WORD:
      return { ...state, loading: true };
    case UPDATE_WEBSITE_CHEF_WORD_SUCCESS:
      return {
        ...state,
        website: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_WEBSITE_CHEF_WORD_FAILED:
      return { ...state, error: action.payload, loading: false };

    case UPDATE_WEBSITE_HISTORY:
      return { ...state, loading: true };
    case UPDATE_WEBSITE_HISTORY_SUCCESS:
      return {
        ...state,
        website: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_WEBSITE_HISTORY_FAILED:
      return { ...state, error: action.payload, loading: false };

    case UPDATE_WEBSITE_GALLERY:
      return { ...state, loading: true };
    case UPDATE_WEBSITE_GALLERY_SUCCESS:
      return {
        ...state,
        website: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_WEBSITE_GALLERY_FAILED:
      return { ...state, error: action.payload, loading: false };

    case DELETE_GALLERY_ITEM:
      return { ...state, loading: true };
    case DELETE_GALLERY_ITEM_SUCCESS:
      return {
        ...state,
        website: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_GALLERY_ITEM_FAILED:
      return { ...state, error: action.payload, loading: false };

    case GET_WEBSITE:
      return { ...state, loading: true };
    case GET_WEBSITE_SUCCESS:
      return {
        ...state,
        website: action.payload.website,
        pointOfSale: action.payload,
        loading: false,
        error: null,
      };
    case GET_WEBSITE_FAILED:
      return { ...state, error: action.payload, loading: false };

    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.Website,
          }
        : {
            ...state,
          };
    default:
      return { ...state };
  }
};

export default Website;
