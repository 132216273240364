import React, { useEffect, useMemo, useRef, useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import {
  useFilters,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table';
import styled from 'styled-components';

import { httpClient_get_s } from '../helpers/api';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';
import { EditUser, Show } from '../pages/SupAdmin/Users';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import LoadingioSpinner from './loadingio-spinner';

const Menu = ({
  row,
  setShowInfo = () => null,
  setShowEdit = () => null,
  setUser = () => null,
  sedEmail = () => null,
}) => {
  return (
    <Dropdown drop={'left'}>
      <Dropdown.Toggle className="btn-not-background">
        <i className=" fas fa-cogs" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          eventKey="1"
          onClick={() => {
            setUser(row);
            setShowEdit(true);
          }}
        >
          <i className="fas fa-edit" /> edit
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="2"
          onClick={() => {
            setUser(row);
            setShowInfo(true);
          }}
        >
          <i className="far fa-eye" /> show
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="2"
          onClick={() => {
            // sedEmail(rowData.email);
          }}
        >
          <i className="far  fa-paper-plane" /> reset password
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const DateFilter = ({ onChange }) => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <DatePicker
      dateFormat="dd-MM-yyyy"
      selected={startDate}
      onChange={(date) => {
        setStartDate(date);
        onChange(date);
      }}
    />
  );
};

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  const [state, setState] = useState('');
  const [showInfo, setShowInfo] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    setState(filterValue);
  }, [filterValue]);

  return (
    <input
      value={state}
      onChange={(e) => {
        setFilter(e.target.value); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search by...`}
    />
  );
}

const TutorialsList = (props) => {
  const [tutorials, setTutorials] = useState([]);
  const [searchTitle, setSearchTitle] = useState('');
  const [showInfo, setShowInfo] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({});

  const cancelToken = useRef();

  const params_filter = useRef({
    email: '',
  }).current;

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy_, setSortBy] = useState([]);

  const pageSizes = [10, 20, 30];

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = { ...params_filter };

    if (page) {
      params['page'] = page - 1;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }

    if (sortBy.length > 0)
      params[`sort_${sortBy[0].id}`] = sortBy[0].desc ? 1 : -1;

    return params;
  };

  function fuzzyTextFilterFn(rows, id, filterValue) {
    setLoading(true);
    let params = {};

    params[id.toString()] = filterValue;

    params_filter[id.toString()] = filterValue;

    if (page) {
      params['page'] = page - 1;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }

    //Check if there are any previous pending requests
    if (typeof cancelToken.current !== typeof undefined) {
      cancelToken.current.cancel(
        'Operation canceled due to new request.',
      );
    }
    cancelToken.current = axios.CancelToken.source();

    httpClient_get_s('/users/', params, cancelToken.current.token)
      .then((response) => {
        const { total, users } = response.data;

        setTutorials(users);
        setCount(Math.floor(total / pageSize));

        setLoading(false);
      })
      .catch((e) => {
        // setLoading(false)
        console.log(e);
      });

    return rows;
  }

  function dateFilter(date) {
    setLoading(true);
    let params = {};

    if (date) {
      params['createAt'] = moment(date).format('yyyy-MM-DD');

      params_filter['createAt'] = moment(date).format('yyyy-MM-DD');
    }

    if (page) {
      params['page'] = page - 1;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }

    //Check if there are any previous pending requests
    if (typeof cancelToken.current !== typeof undefined) {
      cancelToken.current.cancel(
        'Operation canceled due to new request.',
      );
    }
    cancelToken.current = axios.CancelToken.source();

    httpClient_get_s('/users/', params, cancelToken.current.token)
      .then((response) => {
        const { total, users } = response.data;

        setTutorials(users);
        setCount(Math.floor(total / pageSize));

        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
    // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })

    return rows;
  }

  const retrieveTutorials = () => {
    setLoading(true);
    const params = getRequestParams(searchTitle, page, pageSize);

    //Check if there are any previous pending requests
    if (typeof cancelToken.current !== typeof undefined) {
      cancelToken.current.cancel(
        'Operation canceled due to new request.',
      );
    }
    cancelToken.current = axios.CancelToken.source();

    httpClient_get_s('/users/', params, cancelToken.current.token)
      .then((response) => {
        const { total, users } = response.data;

        setTutorials(users);
        setCount(Math.floor(total / pageSize));

        setLoading(false);
      })
      .catch((e) => {
        // setLoading(false)
        console.log(e);
      });
  };

  useEffect(retrieveTutorials, [page, pageSize]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400,
      Filter: DefaultColumnFilter,
      onSort: (field, order) => {
        console.log('2020202002');
      },
    }),
    [],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'lastName',
        accessor: 'lastName',
        filter: 'fuzzyText',
        width: 50,
      },
      {
        Header: 'firstName',
        accessor: 'firstName',
        filter: 'fuzzyText',
      },
      {
        Header: 'email',
        accessor: 'email',
        filter: 'fuzzyText',
      },
      {
        Header: 'phone',
        accessor: 'phone',
        filter: 'fuzzyText',
      },
      {
        Header: 'role',
        accessor: 'role',
        filter: 'fuzzyText',
      },
      {
        Header: 'country',
        accessor: 'country',
        filter: 'fuzzyText',
      },
      {
        Header: 'createdFromCountry',
        accessor: 'createdFromCountry',
        filter: 'fuzzyText',
        Cell: (props) => {
          if (props.value) {
            return (
              <img
                src={`https://flagcdn.com/w80/${props.value.toLowerCase()}.png`}
                srcSet={
                  'https://flagcdn.com/w160/' +
                  props.value.toLowerCase() +
                  '.png 2x'
                }
                width="40"
                height="25"
                alt={props.value}
              />
            );
          } else {
            return (
              <img
                src={`https://flagcdn.com/w80/un.png`}
                srcSet={'https://flagcdn.com/w160/un.png 2x'}
                width="40"
                height="25"
                alt="un"
              />
            );
          }
        },
      },
      {
        Header: 'createdFromAddressIP',
        accessor: 'createdFromAddressIP',
        filter: 'fuzzyText',
        // Cell: (props) => {
        //     const rowIdx = props.row.id;
        //     console.log("--props---???",props)
        //     return (
        //         <div>
        //
        //         </div>
        //     );
        // }
      },
      {
        Header: 'rewardAffiliated',
        accessor: 'rewardAffiliated',
        filter: 'fuzzyText',
        isSortedDesc: true,
      },
      {
        Header: 'createAt',
        accessor: 'createAt',
        filter: 'fuzzyText',
        Filter: <DateFilter onChange={dateFilter} />,
        Cell: (props) => {
          return props.value
            ? moment.utc(props.value).format('DD-MM-YYYY')
            : '';
        },
      },
      {
        Header: 'stellarPubAddress',
        accessor: 'stellarPubAddress',
        filter: 'fuzzyText',
      },
      {
        Header: 'affiliateFromUser',
        accessor: 'affiliateFromUser.email',
        disableFilters: true,
        // disableSortBy: true
      },
      {
        Header: 'lastLogin',
        accessor: 'lastLoginDate.createdAt',
        disableFilters: true,
        // disableSortBy: true,
        Cell: (props) => {
          return props.value
            ? moment.utc(props.value).format('DD-MM-YYYY')
            : '';
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        filterable: false,
        disableFilters: true,
        Cell: (props) => {
          return (
            <Menu
              row={props.row.original}
              setUser={setUser}
              setShowInfo={setShowInfo}
              setShowEdit={setShowEdit}
            />
          );
        },
      },
    ],
    [],
  );

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
    visibleColumns,
  } = useTable(
    {
      columns,
      data: tutorials,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        sortBy: sortBy_,
      },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
  );

  useEffect(() => {
    setSortBy(sortBy);
  }, [sortBy]);

  const sorting = (name, val) => {
    if (val !== undefined) {
      setLoading(true);
      const params = getRequestParams(searchTitle, page, pageSize);

      params[`sort_${name}`] = val ? 1 : -1;

      console.log('---params--', sortBy);

      //Check if there are any previous pending requests
      if (typeof cancelToken.current !== typeof undefined) {
        cancelToken.current.cancel(
          'Operation canceled due to new request.',
        );
      }
      cancelToken.current = axios.CancelToken.source();

      httpClient_get_s('/users/', params, cancelToken.current.token)
        .then((response) => {
          const { total, users } = response.data;

          setTutorials(users);
          setCount(Math.floor(total / pageSize));

          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }
  };

  return (
    <div className="list row">
      {showInfo && (
        <Show
          show={showInfo}
          handleClose={() => {
            setShowInfo(false);
          }}
          onEdite={() => {}}
          user={user}
        />
      )}

      <EditUser
        show={showEdit}
        handleClose={() => {
          setShowEdit(false);
        }}
        onEdite={() => {}}
        user={user}
      />
      <div className="col-md-12 list">
        <div className={'table-responsive text-nowrap'}>
          <Scroll>
            <table
              className="table table-striped table-bordered"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      const headerProps = column.getHeaderProps(
                        column.getSortByToggleProps(),
                      );

                      const clickHandler = (e) => {
                        const val =
                          column.isSortedDesc === true
                            ? undefined
                            : column.isSortedDesc === false;
                        sorting(column.id, val);
                        headerProps.onClick(e);
                      };

                      return (
                        <th>
                          <div
                            {...column.getHeaderProps(
                              column.getSortByToggleProps(),
                            )}
                            onClick={clickHandler}
                          >
                            {column.render('Header')}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? ' 🔽'
                                  : ' 🔼'
                                : ''}
                            </span>
                          </div>
                          {/* Render the columns filter UI */}
                          <div>
                            {column.canFilter
                              ? column.render('Filter')
                              : null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              {loading ? (
                <tr>
                  <th
                    colSpan={visibleColumns.length}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <LoadingioSpinner />
                  </th>
                </tr>
              ) : (
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </Scroll>
        </div>
        <div className="mt-3">
          {'Items per Page: '}
          <select onChange={handlePageSizeChange} value={pageSize}>
            {pageSizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>

          <Pagination
            className="my-3"
            count={count}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

const Wrapper = styled.section`
  background: papayawhip;
  overflow: auto;
`;

const Table = styled.table`
  width: 500px;
`;

const Scroll = styled.div`
  max-height: calc(100vh - 280px);
  overflow: auto;
`;

export default TutorialsList;
