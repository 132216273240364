// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  EDIT_TABLE,
  GET_TABLES,
  GET_TABLES_GLOBAL,
  SAVE_TABLES,
} from '../../constants/actionTypes';
import {
  editTablesSuccess,
  getTablesFailed,
  getTablesGlobalFailed,
  getTablesGlobalSuccess,
  getTablesSuccess,
  setLoadingFalse,
} from './actions';
import {
  createOrUpdateTables,
  editTableService,
  getTablesGlobalService,
  getTablesService,
} from './service';

function* getTablesGlobal({ payload: { pointOfSaleId } }) {
  try {
    const response = yield call(
      getTablesGlobalService,
      pointOfSaleId,
    );
    yield put(getTablesGlobalSuccess(response.units));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(getTablesGlobalFailed(error));
  }
}

function* getTables({ payload: { pointOfSaleId } }) {
  try {
    const response = yield call(getTablesService, pointOfSaleId);
    yield put(getTablesSuccess(response.units));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(getTablesFailed(error));
  }
}

function* editTable({ payload: { unit } }) {
  try {
    const response = yield call(editTableService, unit);
    // console.log('response +++++++++++++++', response)

    //    yield put(editTablesSuccess(response.table));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    // console.log('error **************', error)
    //  yield put(getTablesFailed(error));
  }
}
function* SaveTables({ payload: { pointOfSaleId, units } }) {
  try {
    const response = yield call(
      createOrUpdateTables,
      units,
      pointOfSaleId,
    );
    console.log({ response });
    yield put(setLoadingFalse());
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    console.log('error **************', error);
    //  yield put(getTablesFailed(error));
  }
}

export function* watchGetTables() {
  yield takeEvery(GET_TABLES, getTables);
}

export function* watchEditTables() {
  yield takeEvery(EDIT_TABLE, editTable);
}
export function* watchSaveTables() {
  yield takeEvery(SAVE_TABLES, SaveTables);
}
export function* watchGetTablesGlobal() {
  yield takeEvery(GET_TABLES_GLOBAL, getTablesGlobal);
}

function* tablesSaga() {
  yield all([
    fork(watchGetTables),
    fork(watchEditTables),
    fork(watchSaveTables),
    fork(watchGetTablesGlobal),
    // fork(watchForgetPassword),
  ]);
}

export default tablesSaga;
