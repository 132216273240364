import moment from 'moment';
import React, { useEffect, useMemo, useRef } from 'react';
import Tabulator from 'tabulator-tables';

import './style.scss';
import 'react-tabulator/css/bootstrap/tabulator_bootstrap.min.css';
import 'react-tabulator/lib/styles.css';

function sorter_number(a, b, aRow, bRow, column, dir, sorterParams) {
  let a_ = parseFloat(
    a.replace(/\s/g, '').replace(/,/g, '.').replace(/€/g, ''),
  );

  let b_ = parseFloat(
    b.replace(/\s/g, '').replace(/,/g, '.').replace(/€/g, ''),
  );

  return a_ - b_;
}

const formatDate = (cell) => {
  const value = cell.getValue();

  return value ? moment(value).format('DD-MM-YYYY') : '';
};
const formatDateTime = (cell) => {
  const value = cell.getValue();

  return value ? moment(value).format('DD-MM-YYYY HH:mm') : '';
};

const LabelValue = ({
  cell,
  valid = '',
  textV = 'oui',
  textNV = 'non',
}) => {
  const value = cell.getValue();

  if (value === undefined) return <></>;

  const test = value.toString().includes(valid);

  return (
    <span
      className={`badge ${
        test ? 'badge-success' : 'badge-danger'
      } badge-pill`}
    >
      {test ? textV : textNV}
      {/* <i className={`ml-1 fas   ${test  ? "fa-caret-up":"fa-angle-down"}`}></i> */}
    </span>
  );
};

function customHeaderFilter(
  headerValue,
  rowValue,
  rowData,
  filterParams,
) {
  const test = rowData[filterParams.name] ? 'oui' : 'non';
  return test.toString().includes(headerValue);
}

function customHeaderFilter2(
  headerValue,
  rowValue,
  rowData,
  filterParams,
) {
  return rowData[filterParams.name]?.toString().includes(headerValue);
}

const TableList = React.forwardRef(
  ({ columns_p = [], data_p = [] }, ref) => {
    const ref_table = useRef();
    const table = useRef();

    const show = (name) => {
      table.current.toggleColumn(name);
      return table.current;
    };

    const getColumns = () => {
      return table.current;
    };

    useEffect(() => {
      if (ref_table.current) {
        table.current = new Tabulator(ref_table.current, {
          data: data_p,
          reactiveData: true,
          columns: columns_p,
          pagination: 'local',
          paginationSize: 20,
          paginationSizeSelector: true,
          layout: 'fitColumns',
          height: '100%',
          placeholder: 'No Data Set',
          ajaxLoader: true,
          ajaxLoaderLoading: 'updating data',
        });

        if (ref) {
          ref.current = {
            show: show,
            table: table.current,
            getColumns,
          };
        }
      }
    }, [data_p]);

    return useMemo(() => {
      return <div ref={ref_table} />;
    }, [columns_p, data_p]);
  },
);

class TableList2 extends React.Component {
  el = React.createRef();

  tabulator = null;

  componentDidMount() {
    this.tabulator = new Tabulator(this.el, {
      data: this.props.data_p,
      reactiveData: true,
      columns: this.props.columns_p,
      pagination: 'local',
      paginationSize: 10,
      paginationSizeSelector: true,
      layout: 'fitColumns',
    });
  }

  render() {
    return <div ref={(el) => (this.el = el)} />;
  }
}

export default TableList;

export {
  sorter_number,
  formatDate,
  formatDateTime,
  LabelValue,
  customHeaderFilter,
  customHeaderFilter2,
  TableList2,
};
