import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  FormGroup,
  Button,
  Alert,
} from 'reactstrap';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';

import { loginUser } from '../../redux/actions';
import { isUserAuthenticated } from '../../helpers/authUtils';
import Loader from '../../components/Loader';
import logo from '../../assets/images/logo-dark.png';

const Login = (props) => {
  const [password] = useState('');
  const [username] = useState('');

  // const loading = useSelector((state) => state.Auth.loading);
  const [loading, setLoading] = useState(false);
  const error = useSelector((state) => state.Auth.error);

  const dispatch = useDispatch();
  let _isMounted = useRef(false);

  useEffect(() => {
    _isMounted.current = true;
    document.body.classList.add('authentication-bg');
    return () => {
      _isMounted.current = false;
      document.body.classList.remove('authentication-bg');
    };
  }, []);

  const handleValidSubmit = (event, values) => {
    setLoading(true);
    dispatch(
      loginUser(
        values.username,
        values.password,
        // props.history,
        () => {
          setLoading(false);
        },
      ),
    );
  };

  /**
   * Redirect to root
   */
  const renderRedirectToRoot = () => {
    const isAuthTokenValid = isUserAuthenticated();
    if (isAuthTokenValid) {
      return <Redirect to="/" />;
    }
  };

  const isAuthTokenValid = isUserAuthenticated();
  return (
    <>
      {renderRedirectToRoot()}

      {(_isMounted || !isAuthTokenValid) && (
        <>
          {/* <Helmet>
            <meta
              property="og:url"
              content="https://www.your-domain.com/your-page.html"
            />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Your Website Title" />
            <meta
              property="og:description"
              content="Your description"
            />
            <meta
              property="og:image"
              content="https://www.your-domain.com/path/image.jpg"
            />
          </Helmet> */}
          <div
            className="account-pages"
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Container>
              {loading && <Loader />}
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <div className="text-center">
                    <Link to="/">
                      <span>
                        <img
                          src={logo}
                          alt=""
                          style={{
                            width: '6rem',
                          }}
                        />
                      </span>
                    </Link>
                    {/* <p className="text-muted mt-2 mb-4"> </p> */}
                  </div>
                  <Card
                    style={{
                      backgroundColor: '#ccc',
                    }}
                  >
                    <CardBody
                      className="p-4 position-relative"
                      style={{ paddingTop: 0 }}
                    >
                      {/* preloader */}

                      {/* <div className="text-center mb-4">
                        <h4 className="text-uppercase mt-0">Sign In</h4>
                      </div> */}

                      {error && (
                        <Alert
                          color="danger"
                          isOpen={error ? true : false}
                        >
                          <div>{error}</div>
                        </Alert>
                      )}

                      <AvForm onValidSubmit={handleValidSubmit}>
                        <AvField
                          name="username"
                          placeholder="Enter your username"
                          value={username}
                          required
                        />

                        <AvGroup
                          style={{
                            marginBottom: '1rem',
                          }}
                        >
                          <AvInput
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Enter your password"
                            value={password}
                            required
                          />
                          <AvFeedback>
                            This field is invalid
                          </AvFeedback>
                        </AvGroup>

                        <FormGroup>
                          <Button
                            color="primary"
                            className="btn-block"
                          >
                            Log In
                          </Button>
                        </FormGroup>

                        {/* <p>
                          <strong>Username:</strong> test &nbsp;&nbsp;{" "}
                          <strong>Password:</strong> test
                        </p> */}
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default Login;
