import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyAR4Zso9OWZD9C87oK4m-_7sL6IJw597mI",
    authDomain: "upxpmanager-38f79.firebaseapp.com",
    projectId: "upxpmanager-38f79",
    storageBucket: "upxpmanager-38f79.appspot.com",
    messagingSenderId: "330711661783",
    appId: "1:330711661783:web:5d99d3bdffe5257e85c492",
    measurementId: "G-MC7E95J4WF"
};

// Initialize Firebase
 const app = initializeApp(firebaseConfig);
 // const analytics = getAnalytics(app);
 const database = getFirestore(app);


export {
    app,
    firebaseConfig,
    database
}
