// defaults to localStorage for web
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { combineReducers } from 'redux';

import { LOGOUT_USER } from '../constants/actionTypes';
import DevicesReducers from '../redux/Device/reducers';
import SupAdminCompany from '../redux/SupAdmin/company/reducers';
import SupAdminContacts from '../redux/SupAdmin/contact/reducers';
import SupAdminUsers from '../redux/SupAdmin/users/reducers';
import Auth from './auth/reducers';
import Booking from './Booking/reducers';
import { BooktableAlert } from './booktableAlert/reducer';
import ClickCollect from './ClickCollect/reducers.js';
import Menu from './menu/reducers';
import CommendMenu from './menuCommend/reducers';
import PointOfSale from './PointOfSale/reducers';
import ReservationTicket from './Reservation/reducers';
import SpecialDays from './SpecialDays/reducers';
import Supervisor from './supervisor/reducers';
import TimeSettings from './TimeSettings/reducers';
import Units from './units/reducers';
import Users from './users/reducers';
import Website from './website/reducers';
import WeeklySchedule from './WeeklySchedule/reducers';
import AffiliationSettings from './AffiliationSettings/reducers';
import Caisse from './caisse/reducers';
import PointOfSaleCompany from './pointOfSaleCompany/reducers';
import Management from './management/reducers';

const persistConfig = {
  key: 'root',
  storage: storage,
  // whitelist: ['Module', 'Quiz'],
};

const appReducer = combineReducers({
  Auth,
  Units,
  Users,
  Menu,
  Booking,
  TimeSettings,
  WeeklySchedule,
  SpecialDays,
  ReservationTicket,
  PointOfSale,
  Supervisor,
  SupAdminUsers,
  DevicesReducers,
  BooktableAlert,
  Website,
  CommendMenu,
  SupAdminContacts,
  SupAdminCompany,
  ClickCollect,
  AffiliationSettings,
  Caisse,
  PointOfSaleCompany,
  Management,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    // for all keys defined in your persistConfig(s)
    storage.removeItem('persist:root');
    // storage.removeItem('persist:otherKey')

    state = undefined;
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
