import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logoutUser } from '../../redux/actions';
import { Cookies } from 'react-cookie';

const Logout = (props) => {
  useEffect(() => {
    new Cookies().remove('user', { path: '/' });
    props.logoutUser(props.history);
  }, [props]);

  return <></>;
};

export default withRouter(connect(null, { logoutUser })(Logout));
