import React from 'react';
import PropTypes from 'prop-types';

function LoadingioSpinner(props) {
  return (
    <div className="loadingio-spinner-dual-ring-uqjlfkebs99">
      <div className="ldio-ois4qq7uijf">
        <div></div>
        <div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

LoadingioSpinner.propTypes = {};
LoadingioSpinner.defaultProps = {};

export default LoadingioSpinner;
