const initialState = {
  show: false,
  title: '',
  body: '',
  iClass: 'fas fa-info-circle',
  cancelable: false,
  asking: true,
  success: async () => console.log('okkk'),
  error: async () => console.log('error'),
};

const BooktableAlert = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOWBOOKTABLEALERT':
      return {
        show: true,
        ...action.payload,
      };
    case 'HIDEBOOKTABLEALERT':
      return {
        ...state,
        show: false,
        asking: false,
      };
    default:
      return state;
  }
};
export { BooktableAlert };
