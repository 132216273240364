import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_RESERVATION_TICKET } from '../../constants/actionTypes';
import { setReservationTicket } from './actions';
import { getReservationTicketFn } from './service';

function* getReservationById({ payload }) {
  try {
    const {
      reservation,
      yellowReservations,
    } = yield call(getReservationTicketFn, { id: payload });
    console.log('saga reservation : ', reservation);

    yield put(setReservationTicket(reservation, yellowReservations));
    // yield put(getBookingsSuccess(response.bookings));
  } catch (error) {
    //  yield put(getBookingsFailed(error));
  }
}

export function* watchCallReservationById() {
  yield takeEvery(GET_RESERVATION_TICKET, getReservationById);
}

function* ReservationSaga() {
  yield all([fork(watchCallReservationById)]);
}

export default ReservationSaga;
