// @flow
import {
  ADD_COMMEND_MENU,
  ADD_COMMEND_MENU_FAILED,
  ADD_COMMEND_MENU_SUCCESS,
  CLEAR_COMMEND_MENU,
  COMMEND_UNIT,
  GET_COMMEND_ROOM,
  GET_COMMEND_ROOM_FAILED,
  GET_COMMEND_ROOM_SUCCESS,
} from '../../constants/actionTypes';

export const addCommendMenu = (commend) => ({
  type: ADD_COMMEND_MENU,
  payload: { commend },
});

export const addCommendMenuSuccess = (menu) => ({
  type: ADD_COMMEND_MENU_SUCCESS,
  payload: menu,
});

export const addCommendMenuFailed = (error) => ({
  type: ADD_COMMEND_MENU_FAILED,
  payload: error,
});

export const getTableMenu = (commend) => ({
  type: GET_TABLE_MENU,
  payload: { commend },
});

export const getTableMenuSuccess = (unit) => ({
  type: GET_TABLE_MENU_SUCCESS,
  payload: unit,
});

export const getTableMenuFailed = (error) => ({
  type: GET_TABLE_MENU_FAILED,
  payload: error,
});

export const clearCommendMenu = () => ({
  type: CLEAR_COMMEND_MENU,
  payload: {},
});

export const commendUnit = (unit) => ({
  type: COMMEND_UNIT,
  payload: unit,
});

export const getCommendRoom = (pointOfSaleId) => ({
  type: GET_COMMEND_ROOM,
  payload: { pointOfSaleId },
});

export const getCommendRoomSuccess = (list) => ({
  type: GET_COMMEND_ROOM_SUCCESS,
  payload: list,
});

export const getCommendRoomFailed = (error) => ({
  type: GET_COMMEND_ROOM_FAILED,
  payload: error,
});
