// @flow
import {
  ADD_BOOKING,
  GET_RESERVATION,
  ADD_BOOKING_SUCCESS,
  ADD_BOOKING_FAILED,
  GET_BOOKING,
  GET_BOOKING_SUCCESS,
  GET_BOOKING_FAILED,
  SEARCH_BOOKING,
  SEARCH_BOOKING_SUCCESS,
  GET_BOOKING_C_FAILED,
  GET_BOOKING_C_SUCCESS,
  GET_BOOKING_C,
} from '../../constants/actionTypes';

export const Book = (Booking, callback, pointOfSaleId) => ({
  type: ADD_BOOKING,
  payload: { Booking, callback, pointOfSaleId },
});

export const BookSuccess = (Booking) => ({
  type: ADD_BOOKING_SUCCESS,
  payload: Booking,
});

export const BookFailed = (error) => ({
  type: ADD_BOOKING_FAILED,
  payload: error,
});
export const getBookings = (date, pointOfSaleId) => ({
  type: GET_BOOKING,
  payload: { date, pointOfSaleId },
});

export const searchBookings = () => ({
  type: SEARCH_BOOKING,
  payload: {},
});

export const searchBookingsSuccess = (bookings, active) => ({
  type: SEARCH_BOOKING_SUCCESS,
  payload: { bookings, active },
});

export const getBookingsSuccess = (Bookings) => ({
  type: GET_BOOKING_SUCCESS,
  payload: Bookings,
});

export const getBookingsFailed = (error) => ({
  type: GET_BOOKING_FAILED,
  payload: error,
});

export const getReservation = (time) => ({
  type: GET_RESERVATION,
  payload: time,
});

export const getBooking = (id) => ({
  type: GET_BOOKING_C,
  payload: { id },
});

export const getBookingSuccess = (booking) => ({
  type: GET_BOOKING_C_SUCCESS,
  payload: booking,
});

export const getBookingFailed = (error) => ({
  type: GET_BOOKING_C_FAILED,
  payload: error,
});
