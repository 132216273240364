// @flow
import {
  GET_RESTO,
  GET_RESTO_SUCCESS,
  GET_RESTO_FAILED,
  SELECT_RESTO,
  EDIT_RESTAURANT_SUPERVISOR,
  EDIT_HOTEL_SUPERVISOR,
} from '../../constants/actionTypes';

export const selectSupervisorRestaurants = (
  selectedPointOfSale,
  selectedPointOfSaleType = 'POS',
) => ({
  type: SELECT_RESTO,
  payload: { selectedPointOfSale, selectedPointOfSaleType },
});
export const getSupervisorRestaurants = (loading = true) => ({
  type: GET_RESTO,
  payload: loading,
});

export const getSupervisorRestaurantsSuccess = (pointOfSales) => ({
  type: GET_RESTO_SUCCESS,
  payload: { pointOfSales },
});

export const getSupervisorRestaurantsFailed = (error) => ({
  type: GET_RESTO_FAILED,
  payload: { error },
});

export const supervisorEditRestaurant = (restaurant) => ({
  type: EDIT_RESTAURANT_SUPERVISOR,
  payload: restaurant,
});

export const supervisorEditHotel = (restaurant) => ({
  type: EDIT_HOTEL_SUPERVISOR,
  payload: restaurant,
});
