// @flow
import {
  GET_PRODUCT_RECEIPT,
  GET_PRODUCT_RECEIPT_SUCCESS,
  GET_PRODUCT_RECEIPT_FAILED,
  GET_PRODUCT_RECEIPT_API_SUCCESS,
  ADD_PRODUCT_RECEIPT,
  ADD_PRODUCT_RECEIPT_SUCCESS,
  ADD_PRODUCT_RECEIPT_FAILED,
  GET_PRODUCT_SUPPLIER_COMMAND,
  GET_PRODUCT_SUPPLIER_COMMAND_SUCCESS,
  GET_PRODUCT_SUPPLIER_COMMAND_FAILED,
  ADD_PRODUCT_SUPPLIER_COMMAND,
  ADD_PRODUCT_SUPPLIER_COMMAND_SUCCESS,
  ADD_PRODUCT_SUPPLIER_COMMAND_FAILED,
  GET_PRODUCT_CUSTOMER_COMMAND,
  GET_PRODUCT_CUSTOMER_COMMAND_SUCCESS,
  GET_PRODUCT_CUSTOMER_COMMAND_FAILED,
  ADD_PRODUCT_CUSTOMER_COMMAND,
  ADD_PRODUCT_CUSTOMER_COMMAND_SUCCESS,
  ADD_PRODUCT_CUSTOMER_COMMAND_FAILED,
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_FAILED,
  CREATE_INVOICE,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_FAILED,
  GET_INVOICE_TABLE_SUCCESS,
  ADD_PRODUCT_DELIVERY,
  ADD_PRODUCT_DELIVERY_SUCCESS,
  ADD_PRODUCT_DELIVERY_FAILED,
  GET_PRODUCT_DELIVERY,
  GET_PRODUCT_DELIVERY_SUCCESS,
  GET_PRODUCT_DELIVERY_FAILED,
  EDIT_PRODUCT_SUPPLIER_COMMAND,
  EDIT_PRODUCT_SUPPLIER_COMMAND_SUCCESS,
  EDIT_PRODUCT_SUPPLIER_COMMAND_FAILED,
  EDIT_PRODUCT_CUSTOMER_COMMAND,
  EDIT_PRODUCT_CUSTOMER_COMMAND_SUCCESS,
  EDIT_PRODUCT_CUSTOMER_COMMAND_FAILED,
} from '../../constants/actionTypes';

export const getProductSupplierCommand = (pointOfSaleId) => ({
  type: GET_PRODUCT_SUPPLIER_COMMAND,
  payload: { pointOfSaleId },
});

export const getProductSupplierCommandSuccess = (
  productSupplierCommands,
) => ({
  type: GET_PRODUCT_SUPPLIER_COMMAND_SUCCESS,
  payload: productSupplierCommands,
});

export const getProductSupplierCommandFailed = (error) => ({
  type: GET_PRODUCT_SUPPLIER_COMMAND_FAILED,
  payload: error,
});

export const addProductSupplierCommand = (
  { pointOfSaleId, name, supplier, commandLines, note },
  callback,
) => ({
  type: ADD_PRODUCT_SUPPLIER_COMMAND,
  payload: {
    pointOfSaleId,
    name,
    supplier,
    commandLines,
    note,
    callback,
  },
});

export const addProductSupplierCommandSuccess = (
  productSupplierCommand,
) => ({
  type: ADD_PRODUCT_SUPPLIER_COMMAND_SUCCESS,
  payload: productSupplierCommand,
});

export const addProductSupplierCommandFailed = (error) => ({
  type: ADD_PRODUCT_SUPPLIER_COMMAND_FAILED,
  payload: error,
});

export const editProductSupplierCommand = (
  { productSupplierCommandId, name, commandLines, note },
  callback,
) => ({
  type: EDIT_PRODUCT_SUPPLIER_COMMAND,
  payload: {
    productSupplierCommandId,
    name,
    commandLines,
    note,
    callback,
  },
});

export const editProductSupplierCommandSuccess = (
  productSupplierCommand,
) => ({
  type: EDIT_PRODUCT_SUPPLIER_COMMAND_SUCCESS,
  payload: productSupplierCommand,
});

export const editProductSupplierCommandFailed = (error) => ({
  type: EDIT_PRODUCT_SUPPLIER_COMMAND_FAILED,
  payload: error,
});

export const editProductCustomerCommand = (
  { productCustomerCommandId, name, commandLines, note },
  callback,
) => ({
  type: EDIT_PRODUCT_CUSTOMER_COMMAND,
  payload: {
    productCustomerCommandId,
    name,
    commandLines,
    note,
    callback,
  },
});

export const editProductCustomerCommandSuccess = (
  productSupplierCommand,
) => ({
  type: EDIT_PRODUCT_CUSTOMER_COMMAND_SUCCESS,
  payload: productSupplierCommand,
});

export const editProductCustomerCommandFailed = (error) => ({
  type: EDIT_PRODUCT_CUSTOMER_COMMAND_FAILED,
  payload: error,
});

export const getProductCustomerCommand = (pointOfSaleId) => ({
  type: GET_PRODUCT_CUSTOMER_COMMAND,
  payload: { pointOfSaleId },
});

export const getProductCustomerCommandSuccess = (
  productCustomerCommands,
) => ({
  type: GET_PRODUCT_CUSTOMER_COMMAND_SUCCESS,
  payload: productCustomerCommands,
});

export const getProductCustomerCommandFailed = (error) => ({
  type: GET_PRODUCT_CUSTOMER_COMMAND_FAILED,
  payload: error,
});

export const addProductCustomerCommand = (
  { pointOfSaleId, name, customer, commandLines, note },
  callback,
) => ({
  type: ADD_PRODUCT_CUSTOMER_COMMAND,
  payload: {
    pointOfSaleId,
    name,
    customer,
    commandLines,
    note,
    callback,
  },
});

export const addProductCustomerCommandSuccess = (
  productCustomerCommand,
) => ({
  type: ADD_PRODUCT_CUSTOMER_COMMAND_SUCCESS,
  payload: productCustomerCommand,
});

export const addProductCustomerCommandFailed = (error) => ({
  type: ADD_PRODUCT_CUSTOMER_COMMAND_FAILED,
  payload: error,
});

export const getProductReceipt = (pointOfSaleId) => ({
  type: GET_PRODUCT_RECEIPT,
  payload: { pointOfSaleId },
});

export const getProductReceiptAPISuccess = (productReceipts) => ({
  type: GET_PRODUCT_RECEIPT_API_SUCCESS,
  payload: productReceipts,
});

export const getProductReceiptSuccess = (productReceipts) => ({
  type: GET_PRODUCT_RECEIPT_SUCCESS,
  payload: productReceipts,
});

export const getProductReceiptFailed = (error) => ({
  type: GET_PRODUCT_RECEIPT_FAILED,
  payload: error,
});

export const getProductDelivery = (pointOfSaleId) => ({
  type: GET_PRODUCT_DELIVERY,
  payload: { pointOfSaleId },
});

export const getProductDeliverySuccess = (productDelivery) => ({
  type: GET_PRODUCT_DELIVERY_SUCCESS,
  payload: productDelivery,
});

export const getProductDeliveryFailed = (error) => ({
  type: GET_PRODUCT_DELIVERY_FAILED,
  payload: error,
});

export const addProductReceipt = (
  { pointOfSaleId, productSupplierCommand, name, commandLines, note },
  callback,
) => ({
  type: ADD_PRODUCT_RECEIPT,
  payload: {
    pointOfSaleId,
    name,
    commandLines,
    note,
    productSupplierCommand,
    callback,
  },
});

export const addProductReceiptSuccess = (productReceipt) => ({
  type: ADD_PRODUCT_RECEIPT_SUCCESS,
  payload: productReceipt,
});

export const addProductReceiptFailed = (error) => ({
  type: ADD_PRODUCT_RECEIPT_FAILED,
  payload: error,
});

export const getInvoice = (pointOfSaleId) => ({
  type: GET_INVOICE,
  payload: { pointOfSaleId },
});

export const getInvoiceSuccess = (invoice) => ({
  type: GET_INVOICE_SUCCESS,
  payload: invoice,
});

export const getInvoiceTableSuccess = (invoice) => ({
  type: GET_INVOICE_TABLE_SUCCESS,
  payload: invoice,
});

export const getInvoiceFailed = (error) => ({
  type: GET_INVOICE_FAILED,
  payload: error,
});

export const createInvoice = (
  {
    pointOfSaleId,
    invoiceLines,
    company,
    productDelivery,
    discountPercent,
    HT,
    TTC,
    paymentChoice,
    referencePayment,
    paymentDeadline,
    latePenalties,
    escompte,
    datePayment,
    object,
    note,
  },
  callback,
) => ({
  type: CREATE_INVOICE,
  payload: {
    pointOfSaleId,
    invoiceLines,
    company,
    productDelivery,
    discountPercent,
    HT,
    TTC,
    paymentChoice,
    referencePayment,
    paymentDeadline,
    latePenalties,
    escompte,
    datePayment,
    object,
    note,
    callback,
  },
});

export const createInvoiceSuccess = (invoice) => ({
  type: CREATE_INVOICE_SUCCESS,
  payload: invoice,
});

export const createInvoiceFailed = (error) => ({
  type: CREATE_INVOICE_FAILED,
  payload: error,
});

export const createProductDelivery = (
  { pointOfSaleId, name, commandLines, note, productCustomerCommand },
  callback,
) => ({
  type: ADD_PRODUCT_DELIVERY,
  payload: {
    pointOfSaleId,
    name,
    commandLines,
    note,
    productCustomerCommand,
    callback,
  },
});

export const createProductDeliverySuccess = (productDelivery) => ({
  type: ADD_PRODUCT_DELIVERY_SUCCESS,
  payload: productDelivery,
});

export const createProductDeliveryFailed = (error) => ({
  type: ADD_PRODUCT_DELIVERY_FAILED,
  payload: error,
});
