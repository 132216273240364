import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  ADD_DEVICES_TO_RESUTRANT,
  AFFECTATION_MENU_DEVICE,
  CALLRESUTRANTDEVICES,
  GET_DEVICES_NOT_RESUTRANT,
} from '../../constants/actionTypes';
import {
  addDevicesToResutrantSuccess,
  affectationMenuToDeviceFailed,
  affectationMenuToDeviceSuccess,
  getDevicesNotResutrantSuccess,
  setDevices,
} from './actions';

import {
  addDevicesToResutrantService,
  affectationMenuToDeviceService,
  devicesNotResutrantService,
  retriveDevices,
} from './Services';

function* retiveDeviceFn({ payload: { id } }) {
  try {
    const Response = yield call(retriveDevices, id);
    console.log('Response.Devices', Response.Devices);
    yield put(setDevices(Response.Devices));
  } catch (err) {
    //console.log("err", err)
  }
}

function* getDevicesNotResutrant() {
  try {
    const response = yield call(devicesNotResutrantService);
    console.log('Response.Devices====>>>>>', response);

    yield put(getDevicesNotResutrantSuccess(response));
  } catch (err) {
    //console.log("err", err)
  }
}
function* addDevicesToResutrant({
  payload: { idDevice, idResto, handleClose },
}) {
  try {
    const response = yield call(
      addDevicesToResutrantService,
      idDevice,
      idResto,
    );
    handleClose(false);
    yield put(addDevicesToResutrantSuccess(response));
  } catch (err) {
    //console.log("err", err)
  }
}
function* affectationMenuToDevice({
  payload: { id, idMenu, callback = () => {} },
}) {
  try {
    const response = yield call(
      affectationMenuToDeviceService,
      id,
      idMenu,
    );

    yield put(affectationMenuToDeviceSuccess(response));
    callback();
  } catch (err) {
    yield put(affectationMenuToDeviceFailed(err));
  }
}

export function* watchAffectationMenuToDevice() {
  yield takeEvery(AFFECTATION_MENU_DEVICE, affectationMenuToDevice);
}

export function* watchDevicesCalling() {
  yield takeEvery(CALLRESUTRANTDEVICES, retiveDeviceFn);
}

export function* watchGetDevicesNotResutrant() {
  yield takeEvery(GET_DEVICES_NOT_RESUTRANT, getDevicesNotResutrant);
}
export function* watchAddDevicesToResutrant() {
  yield takeEvery(ADD_DEVICES_TO_RESUTRANT, addDevicesToResutrant);
}

function* DeviceSaga() {
  yield all([
    fork(watchDevicesCalling),
    fork(watchGetDevicesNotResutrant),
    fork(watchAddDevicesToResutrant),
    fork(watchAffectationMenuToDevice),
  ]);
}

export default DeviceSaga;
