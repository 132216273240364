import { httpClient_get, httpClient_post } from '../../helpers/api';

const getReservationTicketFn = (data) => {
  return httpClient_post(`/bookings/getBookingById`, { ...data })
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export { getReservationTicketFn };
