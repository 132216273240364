import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  getMenuFailed,
  getMenuSuccess,
  addMenuSuccess,
  addMenuFailed,
  primaryMenuSuccess,
  primaryMenuFailed,
  getMenuClientSuccess,
  getMenuClientFailed,
  getSettingsMenu,
  getSettingsMenuFailed,
  getSettingsMenuSuccess,
  seveSettingsMenuSuccess,
  saveSettingsMenuFailed,
  deleteMenuSuccess,
  deleteMenuItemFailed,
  deleteMenuItemSuccess,
} from './actions';
import {
  getMenuService,
  addMenuService,
  deleteMenuService,
  primaryMenuService,
  getMenuClientService,
  getSettingsMenuService,
  saveSettingsMenuService,
} from './service';
import {
  GET_MENU,
  ADD_MENU,
  DELETE_MENU,
  PRIMARY_MENU,
  GET_MENU_CLIENT,
  GET_SETTINGS_MENU,
  SAVE_SETTINGS_MENU,
  DELETE_MENU_ITEM,
} from '../../constants/actionTypes';

function* getMenu({ payload: { pointOfSaleId } }) {
  try {
    const response = yield call(getMenuService, pointOfSaleId);
    yield put(getMenuSuccess(response.menu));
  } catch (error) {
    yield put(getMenuFailed(error));
  }
}

function* addMenu({ payload: { menu, pointOfSaleId, onSave } }) {
  try {
    const response = yield call(addMenuService, menu, pointOfSaleId);
    yield put(addMenuSuccess(response.menu));
    onSave();
  } catch (error) {
    console.log(error);
    yield put(addMenuFailed(error));
  }
}
function* deleteMenuItem({
  payload: { menuID, menuCategories, categoryItems },
}) {
  try {
    const response = yield call(
      deleteMenuService,
      menuCategories,
      categoryItems,
    );
    yield put(deleteMenuItemSuccess(menuCategories, categoryItems));
  } catch (error) {
    console.log('------error-----', error);
    yield put(deleteMenuItemFailed(error));
  }
}
function* deleteMenu({
  payload: { menuID, menuCategories, categoryItems },
}) {
  try {
    const response = yield call(
      deleteMenuService,
      menuCategories,
      categoryItems,
    );
    // yield put(deleteMenuSuccess(response.menu));
  } catch (error) {
    // yield put(deleteMenuFailed(error));
  }
}

function* primaryMenu({ payload: { id, pointOfSaleId } }) {
  try {
    const response = yield call(
      primaryMenuService,
      id,
      pointOfSaleId,
    );
    yield put(primaryMenuSuccess(response.menus));
  } catch (error) {
    yield put(primaryMenuFailed(error));
  }
}
function* getMenuClient({ payload: { id } }) {
  try {
    const response = yield call(getMenuClientService, id);
    yield put(getMenuClientSuccess(response.menu));
  } catch (error) {
    yield put(getMenuClientFailed(error));
  }
}

function* getSettingMenu({ payload: { id } }) {
  try {
    console.log('-----function* getSettingMenu-----', id);

    const response = yield call(getSettingsMenuService, id);
    // console.log('-----response. getSettingMenu -----', response);

    yield put(getSettingsMenuSuccess(response.setting));
  } catch (error) {
    console.log('-----error-----', error);
    yield put(getSettingsMenuFailed(error));
  }
}

function* seveSettingMenu({ payload: { id, data } }) {
  try {
    console.log('!!!!---------', data);
    const response = yield call(saveSettingsMenuService, id, data);

    yield put(seveSettingsMenuSuccess(response.setting));
  } catch (error) {
    console.log('-----error-----', error);
    yield put(saveSettingsMenuFailed(error));
  }
}

export function* watchGetMenu() {
  yield takeEvery(GET_MENU, getMenu);
}

export function* watchAddMenu() {
  yield takeEvery(ADD_MENU, addMenu);
}

export function* watchDeleteMenuItem() {
  yield takeEvery(DELETE_MENU_ITEM, deleteMenuItem);
}

export function* watchDeleteMenu() {
  yield takeEvery(DELETE_MENU, deleteMenu);
}
export function* watchPrimaryMenu() {
  yield takeEvery(PRIMARY_MENU, primaryMenu);
}
export function* watchGetMenuClient() {
  yield takeEvery(GET_MENU_CLIENT, getMenuClient);
}
export function* watchGetSettingMenu() {
  yield takeEvery(GET_SETTINGS_MENU, getSettingMenu);
}
export function* watchSaveSettingMenu() {
  yield takeEvery(SAVE_SETTINGS_MENU, seveSettingMenu);
}

function* menuSaga() {
  yield all([
    fork(watchGetMenu),
    fork(watchAddMenu),
    fork(watchDeleteMenu),
    fork(watchPrimaryMenu),
    fork(watchGetMenuClient),
    fork(watchGetSettingMenu),
    fork(watchSaveSettingMenu),
    fork(watchDeleteMenuItem),
  ]);
}

export default menuSaga;
