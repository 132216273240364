// @flow
import {
  GET_TABLES,
  EDIT_TABLE,
  GET_TABLES_SUCCESS,
  GET_TABLES_FAILED,
  SAVE_TABLES,
  EDIT_TABLES_SUCCESS,
  SETLOADINGFALSE,
  GET_TABLES_GLOBAL,
  GET_TABLES_GLOBAL_SUCCESS,
  GET_TABLES_GLOBAL_FAILED,
} from '../../constants/actionTypes';

export const getTables = (loading = true, pointOfSaleId) => ({
  type: GET_TABLES,
  payload: { pointOfSaleId, loading },
});

export const getTablesSuccess = (units) => ({
  type: GET_TABLES_SUCCESS,
  payload: { units },
});

export const getTablesGlobal = (loading = true, pointOfSaleId) => ({
  type: GET_TABLES_GLOBAL,
  payload: { pointOfSaleId, loading },
});

export const getTablesGlobalSuccess = (units) => ({
  type: GET_TABLES_GLOBAL_SUCCESS,
  payload: { units },
});

export const getTablesGlobalFailed = (error) => ({
  type: GET_TABLES_GLOBAL_FAILED,
  payload: { error },
});

export const editTablesSuccess = (unit) => ({
  type: EDIT_TABLES_SUCCESS,
  payload: { unit },
});

export const getTablesFailed = (error) => ({
  type: GET_TABLES_FAILED,
  payload: { error },
});

export const editTable = (unit) => ({
  type: EDIT_TABLE,
  payload: { unit },
});
export const saveTables = (units, pointOfSaleId) => ({
  type: SAVE_TABLES,
  payload: { units, pointOfSaleId },
});
export const setLoadingFalse = () => ({
  type: SETLOADINGFALSE,
  payload: null,
});
