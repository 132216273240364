import { REHYDRATE } from 'redux-persist';

// @flow
import {
  ADD_MENU,
  ADD_MENU_FAILED,
  ADD_MENU_SUCCESS,
  ADD_QR_CODE_INFO,
  DELETE_MENU_ITEM,
  DELETE_MENU_ITEM_FAILED,
  DELETE_MENU_ITEM_SUCCESS,
  GET_MENU,
  GET_MENU_CLIENT,
  GET_MENU_CLIENT_FAILED,
  GET_MENU_CLIENT_SUCCESS,
  GET_MENU_FAILED,
  GET_MENU_SUCCESS,
  GET_SETTINGS_MENU,
  GET_SETTINGS_MENU_FAILED,
  GET_SETTINGS_MENU_SUCCESS,
  PRIMARY_MENU,
  PRIMARY_MENU_FAILED,
  PRIMARY_MENU_SUCCESS,
  SAVE_SETTINGS_MENU,
  SAVE_SETTINGS_MENU_FAILED,
  SAVE_SETTINGS_MENU_SUCCESS,
  SELECTED_MENU,
} from '../../constants/actionTypes';

const INIT_STATE = {
  menus: [],
  loading: false,
  error: false,
  menu: {},
  menuClient: {},
  setting: {},
  qrCodeInfo: null,
};

const Menu = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MENU:
      return { ...state, loading: true };
    case GET_MENU_SUCCESS:
      return {
        ...state,
        menus: action.payload,
        loading: false,
        error: null,
      };
    case GET_MENU_FAILED:
      return { ...state, error: action.payload, loading: false };

    case ADD_MENU:
      return { ...state, loading: true };
    case ADD_MENU_SUCCESS:
      return {
        ...state,
        menu: action.payload,
        loading: false,
        error: null,
      };
    case ADD_MENU_FAILED:
      return { ...state, error: action.payload, loading: false };

    case SELECTED_MENU:
      return { ...state, menu: action.payload };

    case PRIMARY_MENU:
      return { ...state, loading: true };
    case PRIMARY_MENU_SUCCESS:
      return {
        ...state,
        menus: action.payload,
        loading: false,
        error: null,
      };
    case PRIMARY_MENU_FAILED:
      return { ...state, error: action.payload, loading: false };

    case GET_SETTINGS_MENU:
      return { ...state, loading: true };
    case GET_SETTINGS_MENU_SUCCESS:
      return {
        ...state,
        setting: action.payload,
        loading: false,
        error: null,
      };
    case GET_SETTINGS_MENU_FAILED:
      return { ...state, error: action.payload, loading: false };

    case DELETE_MENU_ITEM:
      return { ...state, loading: true };
    case DELETE_MENU_ITEM_SUCCESS:
      let les = state.menu.menuCategories.map((elem, index) => {
        return {
          ...elem,
          categoryItems: elem.categoryItems.filter((el) => {
            return el._id !== action.payload.categoryItems;
          }),
        };
      });
      return {
        ...state,
        loading: false,
        menu: { ...state.menu, menuCategories: [...les] },
      };
    case DELETE_MENU_ITEM_FAILED:
      return { ...state, error: action.payload, loading: false };

    case SAVE_SETTINGS_MENU:
      return { ...state, loading: true };
    case SAVE_SETTINGS_MENU_SUCCESS:
      return {
        ...state,
        setting: action.payload,
        loading: false,
        error: null,
      };
    case SAVE_SETTINGS_MENU_FAILED:
      return { ...state, error: action.payload, loading: false };

    case GET_MENU_CLIENT:
      return { ...state, loading: true };
    case GET_MENU_CLIENT_SUCCESS:
      return {
        ...state,
        menuClient: action.payload,
        loading: false,
        error: null,
      };
    case GET_MENU_CLIENT_FAILED:
      return { ...state, error: action.payload, loading: false };

    case ADD_QR_CODE_INFO:
      return { ...state, qrCodeInfo: action.payload, loading: false };

    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.Menu,
          }
        : {
            ...state,
          };
    default:
      return { ...state };
  }
};

export default Menu;
