// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { GET_AFFILIATION_ID } from '../../constants/actionTypes';
import {
  getAffiliationIdFailed,
  getAffiliationIdSuccess,
} from './actions';
import { getAffiliationIdService } from './Services';

// import { ChangeTimePerBook } from "./Services";

function* getAffiliationIdSaga({ payload: { pointOfSaleId } }) {
  try {
    const response = yield call(
      getAffiliationIdService,
      pointOfSaleId,
    );
    yield put(
      getAffiliationIdSuccess({
        affiliateId: response.affiliateId,
        rewardAffiliated: response.rewardAffiliated,
        numberOfAffiliated: response.numberOfAffiliated,
        nbClickAffiliationLink: response.nbClickAffiliationLink,
      }),
    );
  } catch (error) {
    yield put(getAffiliationIdFailed(error));
  }
}

export function* watchGetAffiliationSetting() {
  yield takeEvery(GET_AFFILIATION_ID, getAffiliationIdSaga);
}

function* affiliateSettingSaga() {
  yield all([fork(watchGetAffiliationSetting)]);
}

export default affiliateSettingSaga;
