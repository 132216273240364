import { httpClient_post } from '../../helpers/api';

const getPointOfSaleCompanyService = ({ pointOfSaleId }) => {
  return httpClient_post('/pointofsale-contact/all', {
    pointOfSaleId: pointOfSaleId ? pointOfSaleId : 'undefined',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const addPointOfSaleCompanyService = ({
  pointOfSaleId,
  client,
  email,
  phone,
  role,
  companyName,
  website,
  address,
  rc,
  ice,
}) => {
  return httpClient_post('/pointofsale-contact/create', {
    pointOfSaleId: pointOfSaleId ? pointOfSaleId : 'undefined',
    client,
    email,
    phone,
    role,
    companyName,
    website,
    address,
    rc,
    ice,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const editPointOfSaleCompanyService = ({
  id,
  client,
  email,
  phone,
  role,
  companyName,
  website,
  address,
  rc,
  ice,
}) => {
  return httpClient_post('/pointofsale-contact/edit/' + id, {
    client,
    email,
    phone,
    role,
    companyName,
    website,
    address,
    rc,
    ice,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export {
  getPointOfSaleCompanyService,
  addPointOfSaleCompanyService,
  editPointOfSaleCompanyService,
};
