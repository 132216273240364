// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  FORGET_PASSWORD,
  LOGIN_USER,
  LOGOUT_USER,
  RESET_PASSWORD,
  SETNEWLANGAGUE,
} from '../../constants/actionTypes';
// import { forgetPasswordFailed, forgetPasswordSuccess, loginUserFailed, loginUserSuccess, resetPasswordFailed, resetPasswordSuccess } from '../../helpers/ability';

import {
  loginUserSuccess,
  loginUserFailed,
  forgetPasswordFailed,
  resetPasswordFailed,
  resetPasswordSuccess,
  forgetPasswordSuccess,
} from './actions';
import {
  forgotPasswordService,
  loginService,
  resetPasswordService,
  changeLanguageService,
} from './service';

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (user) => {
  let cookies = new Cookies();
  if (user) {
    cookies.set(
      'user',
      JSON.stringify({
        token: user.token,
        email: user.email,
        password: user.password,
        role: user.role,
      }),
      { path: '/' },
    );
    // ability.update(defineRulesFor(user));
  } else cookies.remove('user');
};

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password, callback } }) {
  try {
    const response = yield call(loginService, username, password);
    if (!response.user.enabled)
      throw new Error('your account has been suspended ');
    if (response.user.role === 'ROLE_USER')
      throw new Error('Access Denied for normal User');
    const user = {
      ...response.user,
      token: response.token,
      enabled: response.user.enabled,
      expiresIn: response.expiresIn,
    };

    setSession(user);

    yield put(loginUserSuccess(user));
    callback();
  } catch (error) {
    yield put(loginUserFailed(error.message));
    setSession(null);
    callback();
  }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
  try {
    setSession(null);
    yield call(() => {
      history.push('/login');
    });
  } catch (error) {}
}

/**
 * Register the user
 */
// function* register({ payload: { fullname, email, password } }) {
//     const options = {
//         body: JSON.stringify({ fullname, email, password }),
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' }
//     };

//     try {
//         const response = yield call(fetchJSON, '/users/register', options);
//         yield put(registerUserSuccess(response));
//     } catch (error) {
//         let message;
//         switch (error.status) {
//             case 500: message = 'Internal Server Error'; break;
//             case 401: message = 'Invalid credentials'; break;
//             default: message = error;
//         }
//         yield put(registerUserFailed(message));
//     }
// }

/**
 * forget password
 */

function* ChangeLang({ payload }) {
  try {
    const response = yield call(changeLanguageService, payload);
  } catch (error) {}
}

function* forgetPassword({ payload: { username } }) {
  try {
    const response = yield call(forgotPasswordService, username);
    console.log('|-------forgetPassword------->>', response);
    yield put(forgetPasswordSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(forgetPasswordFailed(message));
  }
}

function* resetPassword({
  payload: { password, r_password, id_user, token },
}) {
  try {
    const response = yield call(
      resetPasswordService,
      password,
      r_password,
      id_user,
      token,
    );
    yield put(resetPasswordSuccess(response));
  } catch (error) {
    yield put(resetPasswordFailed(error));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLangChange() {
  yield takeEvery(SETNEWLANGAGUE, ChangeLang);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

// export function* watchRegisterUser() {
//     yield takeEvery(REGISTER_USER, register);
// }

export function* watchForgetPassword() {
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    // fork(watchRegisterUser),
    fork(watchForgetPassword),
    fork(watchResetPassword),
    fork(watchLangChange),
  ]);
}

export default authSaga;
