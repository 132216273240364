import { httpClient_get, httpClient_post } from '../../helpers/api';

const getTablesService = (pointOfSaleId) => {
  return httpClient_post('/pointofsale/units', { pointOfSaleId })
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
const getTablesGlobalService = (pointOfSaleId) => {
  return httpClient_post('/pointofsale/units-global', {
    pointOfSaleId,
  })
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const editTableService = (unit) => {
  return httpClient_post(`/units/${unit._id}/update-position`, {
    x: unit.x,
    y: unit.y,
  })
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
const createOrUpdateTables = (units, pointOfSaleId) => {
  return httpClient_post(`/units/createOrUpdateUnits`, {
    units,
    pointOfSaleId,
  })
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export {
  getTablesService,
  editTableService,
  createOrUpdateTables,
  getTablesGlobalService,
};
