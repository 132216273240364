import { Ability, AbilityBuilder } from '@casl/ability';
// import store from '../redux/store';
import store from '../redux/store';
// import {getLoggedInUser} from '';
// Defines how to detect object's type
function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }
  return item.__type;
}

let currentAuth;
let selectedPointOfSale;
let ability = null;

store.store.subscribe(() => {
  // const prevAuth = currentAuth;
  currentAuth = store.store.getState().Auth.user;
  selectedPointOfSale = store.store.getState().Supervisor
    .selectedPointOfSale;

  if (currentAuth !== null) {
    ability = new Ability([], { subjectName });
    ability.update(defineRulesFor(currentAuth, selectedPointOfSale));
  }
});

function defineRulesFor(auth, selectedPointOfSale) {
  const { can, rules } = AbilityBuilder.extract();

  switch (auth.role) {
    case 'ROLE_SUPER_ADMIN':
      can('menu', 'all');
      can(
        [
          'add',
          'showDetail',
          'history',
          'changeAdmin',
          'sendEmail',
          'link',
          'addCompany',
        ],
        'pointOfSale',
      );
      break;
    case 'ROLE_ASSISTANCE':
      can(['show'], 'pointOfSale');
      break;
    case 'ROLE_ADMIN':
      can(['show'], 'menu');
      can(['show'], 'booking-System');
      can(['show'], 'settings');
      can(['show'], 'statistique');
      can(['show'], 'listDevices');
      can(['show'], 'clients');
      can(['show'], 'newsletter');
      can(['show'], 'sitWeb');
      can(['show'], 'contacts');
      can(['show'], 'management');

      break;
    case 'ROLE_STAFF':
      can(['show'], 'booking-System');
      break;

    case 'ROLE_MANAGER':
      can(['show'], 'booking-System');
      can(['show'], 'menu');

      break;
    case 'ROLE_SUPERVISOR':
      // can(['show'], 'menu');
      // can(['show'], 'statistiqueSupervisor');
      // can(['show'], 'listDevices');
      // can(['show'], 'menuSupervisor');
      // can(['show'], 'clientsSupervisor');

      can(['show'], 'menu');
      can(['show'], 'booking-System');
      can(['show'], 'settings');
      selectedPointOfSale?.category === 'restaurant' &&
        can(['show'], 'statistique');
      selectedPointOfSale?.category === 'restaurant' &&
        can(['show'], 'listDevices');
      selectedPointOfSale?.category === 'restaurant' &&
        can(['show'], 'clients');
      selectedPointOfSale?.category === 'restaurant' &&
        can(['show'], 'newsletter');
      selectedPointOfSale?.category === 'restaurant' &&
        can(['show'], 'sitWeb');
      selectedPointOfSale?.category === 'restaurant' &&
        can(['show'], 'contacts');
      selectedPointOfSale?.category === 'restaurant' &&
        can(['show'], 'management');

      break;

    default:
      break;
  }
  return rules;
}

export { ability, defineRulesFor };
