import QRCode, { QRCodeCanvas, QRCodeSVG } from 'qrcode.react';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { jsPDF } from 'jspdf'
const QrCodeSvg = forwardRef(
    (
        {
            show = true,
            id = 123456,
            link = '',
            width,
            location = window.location.origin,
            relativeLink = true,
            size = 200,
            size_ = 1200,
            number = 0
        },
        ref,
    ) => {
        useImperativeHandle(ref, () => ({
            downloadQR() {
                downloadQR();
            },
        }));

        const [base64data, setBase64data] = useState()

        useEffect(() => {

            (() => {

                fetch("https://placehold.co/24x24/white/black?text=" + number)
                    .then(res => res.blob()) // Gets the response and returns it as a blob
                    .then(blob => {
                        // Here's where you get access to the blob
                        // And you can use it for whatever you want
                        // Like calling ref().put(blob)

                        // Here, I use it to make an image appear on the page

                        const reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = () => {
                            console.log("---reader.result", reader.result)
                            setBase64data(reader.result)
                        }

                        // let objectURL = URL.createObjectURL(blob);
                        // let myImage = new Image();
                        // myImage.src = objectURL;
                        // document.getElementById('myImg').appendChild(myImage)
                    });
            }

            )()


        }, [])


        function setDPI(canvas, dpi) {
            // Set up CSS size.
            canvas.style.width = canvas.style.width || canvas.width + 'px';
            canvas.style.height = canvas.style.height || canvas.height + 'px';

            // Get size information.
            const scaleFactor = dpi / 96;
            const width = parseFloat(canvas.style.width);
            const height = parseFloat(canvas.style.height);

            // Backup the canvas contents.
            const oldScale = canvas.width / width;
            const backupScale = scaleFactor / oldScale;
            const backup = canvas.cloneNode(false);
            backup.getContext('2d').drawImage(canvas, 0, 0);

            // Resize the canvas.
            const ctx = canvas.getContext('2d');
            canvas.width = Math.ceil(width * scaleFactor);
            canvas.height = Math.ceil(height * scaleFactor);

            // Redraw the canvas image and scale future draws.
            ctx.setTransform(backupScale, 0, 0, backupScale, 0, 0);
            ctx.drawImage(backup, 0, 0);
            ctx.setTransform(scaleFactor, 0, 0, scaleFactor, 0, 0);
        }


        const downloadQR = () => {
            const cln = document.getElementById(`QRCode-${id}`);




            // // const canvas = cloneCanvas(cln, size_*2, size_*2);
            // // setDPI(cln,300)
            // const pngUrl = cln
            //     .toDataURL('image/png')
            //     .replace('image/png', 'image/octet-stream');
            // let downloadLink = document.createElement('a');
            // downloadLink.href = pngUrl;
            // downloadLink.download = `${id}.png`;
            // document.body.appendChild(downloadLink);
            // downloadLink.click();
            // document.body.removeChild(downloadLink);



            let pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: [40, 50]
            })
            let base64Image = cln.toDataURL()
            pdf.addImage(base64Image, 'png', 0, 0, 40, 40)
            pdf.text("MENU", 12, 45)
            pdf.setFontSize(18)
            pdf.save(`${id}.pdf`)
        };



        const cloneCanvas = (oldCanvas, width = size * 2, height = size * 2) => {
            //create a new canvas
            var newCanvas = document.createElement('canvas');
            var context = newCanvas.getContext('2d');
            //set dimensions
            newCanvas.width = width;
            newCanvas.height = height;
            //apply the old canvas to the new one
            context.drawImage(oldCanvas, 0, 0);
            //return the new canvas
            return newCanvas;
        };

        return (<QRCode
            id={`QRCode-${id}`}
            value={relativeLink ? `${location}${link}` : link}
            // className="thumb-img img-fluid"
            size={size_}
            level={'L'}
            includeMargin={true}
            style={{
                margin: '0',
                display: 'block',
                width: '200px',
                height: '200px',
            }}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            imageSettings={{
                src: base64data,
                x: undefined,
                y: undefined,
                height: 70,
                width: 70,
                excavate: true,
            }}
        />

        );
    },
);

export default QrCodeSvg;
