// @flow
import {
  SUP_ADMIN_EDIT_USERS,
  SUP_ADMIN_EDIT_USERS_FAILED,
  SUP_ADMIN_EDIT_USERS_SUCCESS,
  SUP_ADMIN_GET_USERS,
  SUP_ADMIN_GET_USERS_FAILED,
  SUP_ADMIN_GET_USERS_SUCCESS,
  SUP_ADMIN_ADD_SUPERVISOR,
  SUP_ADMIN_ADD_SUPERVISOR_SUCCESS,
  SUP_ADMIN_ADD_SUPERVISOR_FAILED,
  SUP_ADMIN_SEARCH_NOTIFICATION,
  SUP_ADMIN_SEARCH_NOTIFICATION_SUCCESS,
  SUP_ADMIN_SEARCH_NOTIFICATION_FAILED,
} from '../../../constants/actionTypes';

// export const addUser = (user) => ({
//     type: ADD_USER,
//     payload: { user }
// });

// export const addUserSuccess = (user)  => ({
//     type: ADD_USER_SUCCESS,
//     payload: user
// });

// export const addUserFailed = (error) => ({
//     type: ADD_USER_FAILED,
//     payload: error
// });

export const UpdateUser = (user, callback) => ({
  type: SUP_ADMIN_EDIT_USERS,
  payload: { user, callback },
});

export const UpdateUserSuccess = (user) => ({
  type: SUP_ADMIN_EDIT_USERS_SUCCESS,
  payload: user,
});

export const UpdateUserFailed = (error) => ({
  type: SUP_ADMIN_EDIT_USERS_FAILED,
  payload: error,
});

export const searchNotification = (data) => ({
  type: SUP_ADMIN_SEARCH_NOTIFICATION,
  payload: { data },
});

export const searchNotificationSuccess = (data) => ({
  type: SUP_ADMIN_SEARCH_NOTIFICATION_SUCCESS,
  payload: data,
});

export const searchNotificationFailed = (error) => ({
  type: SUP_ADMIN_SEARCH_NOTIFICATION_FAILED,
  payload: error,
});

// export const deleteUser = (user) => ({
//     type: DELETE_USER,
//     payload: { user }
// });

// export const deleteUserSuccess = (user)  => ({
//     type: DELETE_USER_SUCCESS,
//     payload: user
// });

// export const deleteUserFailed = (error) => ({
//     type: DELETE_USER_FAILED,
//     payload: error
// });

export const getUsersSuperAdmin = () => ({
  type: SUP_ADMIN_GET_USERS,
  payload: {},
});

export const getUsersSuccess = (users) => ({
  type: SUP_ADMIN_GET_USERS_SUCCESS,
  payload: users,
});

export const getUsersFailed = (error) => ({
  type: SUP_ADMIN_GET_USERS_FAILED,
  payload: error,
});

export const addSupervisor = (data, callback) => ({
  type: SUP_ADMIN_ADD_SUPERVISOR,
  payload: { data, callback },
});

export const addSupervisorSuccess = (user) => ({
  type: SUP_ADMIN_ADD_SUPERVISOR_SUCCESS,
  payload: user,
});

export const addSupervisorFailed = (error) => ({
  type: SUP_ADMIN_ADD_SUPERVISOR_FAILED,
  payload: error,
});
