// import i18n from 'i18n-js';
// import store from '../../redux/store';
// console.log()
// // import dt from './dt.json';
// // import en from './en.json';
// // import fr from './fr.json';

// const initLang = store.store.getState().Auth.user.lang
// i18n.fallbacks = false;
// i18n.translations = { fr, en, dt };
// i18n.locale = initLang;

// export { i18n };

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import dt from './dt.json';
import en from './en.json';
import fr from './fr.json';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      fr,
      en,
      dt,
    },
    /* default language when load the website in browser */
    lng: 'en',
    /* When react i18next not finding any language to as default in borwser */
    fallbackLng: 'en',
    /* debugger For Development environment */
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
  });

export { i18n };
