import React, { useState, useEffect, useMemo } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);

    if (process.env.REACT_APP_MODE !== 'development')
      window.location.href = '/logout';
  }

  render() {
    //
    if (
      this.state.hasError &&
      process.env.REACT_APP_MODE !== 'development'
    ) {
      // You can render any custom fallback UI
      return <></>;
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
