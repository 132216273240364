import { REHYDRATE } from 'redux-persist';

import {
  ADD_ADMIN_COMPANY,
  EDIT_ADMIN_COMPANY,
  GET_ADMIN_ALL_COMPANY,
  GET_ADMIN_ALL_COMPANY_FAILED,
  GET_ADMIN_ALL_COMPANY_SUCCESS,
} from '../../../constants/actionTypes';

const INIT_STATE = {
  user: {},
  loading: false,
  error: false,
  company: [],
};

const SupAdminCompany = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADMIN_ALL_COMPANY:
      return { ...state, loading: true };
    case GET_ADMIN_ALL_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload,
        loading: false,
        error: null,
      };
    case GET_ADMIN_ALL_COMPANY_FAILED:
      return { ...state, error: action.payload, loading: false };

    case ADD_ADMIN_COMPANY:
      return {
        ...state,
        company: [...state.company, action.payload],
      };

    case EDIT_ADMIN_COMPANY:
      return {
        ...state,
        company: state.company.map((elem) =>
          elem._id === action.payload.id
            ? { ...elem, ...action.payload }
            : elem,
        ),
      };

    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.Users,
          }
        : {
            ...state,
          };
    default:
      return { ...state };
  }
};

export default SupAdminCompany;
