// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  ADD_BOOKING,
  GET_BOOKING,
  SEARCH_BOOKING,
  GET_BOOKING_C,
} from '../../constants/actionTypes';
import {
  BookFailed,
  BookSuccess,
  getBookingFailed,
  getBookings,
  getBookingsFailed,
  getBookingsSuccess,
  getBookingSuccess,
} from './actions';
import {
  bookTableService,
  getBookingService,
  getBookingsService,
  searchBookingsService,
} from './service';
import moment from 'moment';

function* getBookingsSaga({
  payload: {
    date, //format="YYYY-MM-DD"
    pointOfSaleId,
  },
}) {
  try {
    const response = yield call(getBookingsService, {
      date, //format="YYYY-MM-DD"
      pointOfSaleId,
    });
    // console.log()
    //yield put(setTimePerBookingFn(response.settings.timePerBooking));
    yield put(getBookingsSuccess(response.bookings));
  } catch (error) {
    yield put(getBookingsFailed(error));
  }
}

function* searchBookingsSaga({ payload: { word, pointOfSaleId } }) {
  // try {
  //   const response = yield call(
  //     searchBookingsService,
  //     word,
  //     pointOfSaleId,
  //   );
  //   yield put(getBookingsSuccess(response.bookings));
  // } catch (error) {
  //   yield put(getBookingsFailed(error));
  // }
}

function* bookTableSaga({
  payload: { Booking, callback, pointOfSaleId },
}) {
  try {
    const {
      date, //format="YYYY-MM-DD"
      time, // format: 18.5 == 15h30min
      numberPlaces,
      unitId,
      firstName,
      lastName,
      phone,
      email,
      note,
      typeBooking,
    } = Booking;
    const response = yield call(bookTableService, {
      date, //format="YYYY-MM-DD"
      time, // format: 18.5 == 15h30min
      numberPlaces,
      unitId,
      firstName,
      lastName,
      phone,
      email,
      note,
      typeBooking,
      pointOfSaleId,
    });
    // console.log()
    //yield put(setTimePerBookingFn(response.settings.timePerBooking));
    // console.log('response --------------------------------------', response)
    yield put(BookSuccess(response.booking));

    yield put(
      getBookings(
        moment(response.booking.bookingDate).format('YYYY-MM-DD'),
        pointOfSaleId,
      ),
    );
    callback();
  } catch (error) {
    //console.log("error++++++++++++++++++", error);

    yield put(BookFailed(error));
  }
}

function* getBookingSaga({ payload: { id } }) {
  try {
    const response = yield call(getBookingService, id);
    yield put(getBookingSuccess(response.booking));
  } catch (error) {
    yield put(getBookingFailed(error));
  }
}

export function* WatchBookTable() {
  yield takeEvery(ADD_BOOKING, bookTableSaga);
}

export function* WatchGetBookings() {
  yield takeEvery(GET_BOOKING, getBookingsSaga);
}
export function* WatchGetBooking() {
  yield takeEvery(GET_BOOKING_C, getBookingSaga);
}

export function* WatchSearchBookings() {
  yield takeEvery(SEARCH_BOOKING, searchBookingsSaga);
}

function* bookingSaga() {
  yield all([
    fork(WatchBookTable),
    fork(WatchGetBookings),
    fork(WatchSearchBookings),
    fork(WatchGetBooking),
    // fork(watchForgetPassword),
  ]);
}

export default bookingSaga;
