import React, {useEffect, useRef, useState} from 'react';
import style from './style.module.scss';
import {Button, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import TextEditor from "./RichText/TextEditor";
import {
    collection,
    doc,
    query,
    Timestamp,
    addDoc,
    onSnapshot,
    deleteDoc,
    where,
    runTransaction,
    orderBy, limit, startAfter, getDocs
} from "firebase/firestore";
import {database} from "../../helpers/firebaseConfig";
import styled from "styled-components";
import moment from "moment";
import Swal from 'sweetalert2'



export default function Notification({}) {
    const [listCurrentNotification, setListCurrentNotification] = useState([]);
    const [title, setTitle] = useState("");
    const textEditor = useRef();


    const fetchNotification = async  ()=>{

        const q = query(
            collection(database, 'notifications'),
            where("pointOfSaleId","in",['nil']),
            orderBy("registeredAt","desc"),
        )
        //



        onSnapshot(q, (querySnapshot) => {
            const res = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }))
            // .filter(doc => !doc.deletingBy?.includes(_id)).sort((a,b) => {
            //
            //     // console.log("---a.registeredAt--",a.registeredAt.seconds  );
            //     return  b.registeredAt.seconds  - a.registeredAt.seconds
            // });

            // setLastVisible(querySnapshot.docs[querySnapshot.docs.length-1]);

            setListCurrentNotification(res);
        })

    }

    useEffect( () => {

        async function fetchData() {
            await fetchNotification()
        }
        fetchData().then(r => r);

    },[])

    const add = async (message)=>{


        const docRef = await addDoc(collection(database, "notifications"), {
            title,
            message,
            registeredAt:Timestamp.fromDate(new Date()),
            pointOfSaleId: 'nil',
            readingBy:[],
            deletingBy:[]
        });


        setTitle("")
        textEditor.current.reset()

    }

    const deleteNotif =  (notif)=> async (e)=>{
        const docRef = doc(database, "notifications", notif.id);


        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteDoc(docRef);
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        })

        //

    }

    const resSandNotif = (notif)=> async (e)=>{


        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to send this notification !",
            icon: 'warning',
            showCancelButton: true,
            // confirmButtonColor: '#3085d6',
            // cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await addDoc(collection(database, "notifications"), {
                    title:notif.title,
                    message:notif.message,
                    registeredAt:Timestamp.fromDate(new Date()),
                    pointOfSaleId: 'nil',
                    readingBy:[],
                    deletingBy:[]
                });
                Swal.fire(
                    'Sent!',
                    'Your notification has been sent.',
                    'success'
                )
            }
        })



    }


    return (<div className={style.notification}>
        <Row>
            <Col lg={6}>
                <FormGroup >
                    <Label for="exampleEmail">Title</Label>
                    <Input type="text" value={title} onChange={({target:{value}})=>setTitle(value)} />
                </FormGroup>
                <FormGroup >
                    <Label for="exampleEmail">Message</Label>
                    <TextEditor ref={textEditor}  onSave={add} />
                </FormGroup>
            </Col>
            <Col lg={6}>
               <div className={style.contentListNotif}>
                   {listCurrentNotification.map((elm,index)=>{

                       return <div  key={elm.id} className={style.itemNotif} >
                           <div className={style.controlled} >
                                <span className={style.delete_btn} onClick={resSandNotif(elm)} >
                                    <i className={"fas fa-sync-alt"} ></i>
                                </span>
                                <span className={style.reload} onClick={deleteNotif(elm)} >
                                    <i className={"fa fa-close"} ></i>
                                </span>

                           </div>
                           <h3>
                               {elm.title}
                           </h3>
                           <p dangerouslySetInnerHTML={{ __html: elm.message }} />
                           <span className={style.date} >
                               {moment(elm.registeredAt.toDate()).format("DD-MM-yyyy  HH:mm:ss")}
                           </span>
                       </div>
                   })}
               </div>
            </Col>
        </Row>

    </div>);
}
