import { REHYDRATE } from 'redux-persist';

import {
  GET_POINT_OF_SALE_CONTACT,
  GET_POINT_OF_SALE_CONTACT_SUCCESS,
  GET_POINT_OF_SALE_CONTACT_FAILED,
  ADD_POINT_OF_SALE_CONTACT,
  ADD_POINT_OF_SALE_CONTACT_SUCCESS,
  ADD_POINT_OF_SALE_CONTACT_FAILED,
  EDIT_POINT_OF_SALE_CONTACT,
  EDIT_POINT_OF_SALE_CONTACT_SUCCESS,
  EDIT_POINT_OF_SALE_CONTACT_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
  loading: false,
  error: false,
  contacts: [],
};

const PointOfSaleCompany = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_POINT_OF_SALE_CONTACT:
      return { ...state, loading: true };
    case GET_POINT_OF_SALE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: action.payload,
        loading: false,
        error: null,
      };
    case GET_POINT_OF_SALE_CONTACT_FAILED:
      return { ...state, error: action.payload, loading: false };
    case ADD_POINT_OF_SALE_CONTACT:
      return { ...state, loading: true };
    case ADD_POINT_OF_SALE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: [...state.contacts, action.payload],
        loading: false,
        error: null,
      };
    case ADD_POINT_OF_SALE_CONTACT_FAILED:
      return { ...state, error: action.payload, loading: false };
    case EDIT_POINT_OF_SALE_CONTACT:
      return { ...state, loading: true };
    case EDIT_POINT_OF_SALE_CONTACT_SUCCESS:
      const c = state.contacts.map((pc) => {
        if (pc._id === action.payload._id) {
          pc = action.payload;
        }
        return pc;
      });

      return {
        ...state,
        contacts: [...c],
        loading: false,
        error: null,
      };
    case EDIT_POINT_OF_SALE_CONTACT_FAILED:
      return { ...state, error: action.payload, loading: false };

    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.PointOfSaleCompany,
          }
        : {
            ...state,
          };
    default:
      return { ...state };
  }
};

export default PointOfSaleCompany;
