import { httpClient_get } from '../../../helpers/api';

const getAllContactsService = (user) => {
  return httpClient_get(`/contact/all`)
    .then((response) => {
      return response.data.contacts;
    })
    .catch((error) => {
      throw error;
    });
};

export { getAllContactsService };
