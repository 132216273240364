import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { GET_COMMEND_ROOM } from '../../constants/actionTypes';
import {
  getCommendRoomFailed,
  getCommendRoomSuccess,
} from './actions';
import { getCommandRoomService } from './service';

function* getCommandRoom({ payload: { pointOfSaleId } }) {
  try {
    const response = yield call(getCommandRoomService, pointOfSaleId);
    yield put(getCommendRoomSuccess(response.commands));
  } catch (error) {
    yield put(getCommendRoomFailed(error));
  }
}

export function* watchCommendRoom() {
  yield takeEvery(GET_COMMEND_ROOM, getCommandRoom);
}

function* CommendMenuSaga() {
  yield all([fork(watchCommendRoom)]);
}

export default CommendMenuSaga;
