import moment from 'moment';

import { httpClient_post } from '../../helpers/api';

const getTimeService = (pointOfSaleId) => {
  // date format="YYYY-MM-DD"
  return httpClient_post(
    `/command/getTimesAvailableClickAndCollect`,
    {
      date: moment().format('YYYY-MM-DD'),
      pointOfSaleId,
    },
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const addCommandService = (command) => {
  return httpClient_post(
    `/command/getTimesAvailableClickAndCollect`,
    {
      date: moment().format('YYYY-MM-DD'),
      pointOfSaleId,
    },
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export { getTimeService, addCommandService };
