import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';
import rootReducer from './reducers';

// const sagaMiddleware = createSagaMiddleware();
// const middlewares = [sagaMiddleware];

// function configureStore(initialState) {
//     const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//     const store = createStore(
//         reducers,
//         initialState,
//         composeEnhancers(
//             applyMiddleware(...middlewares)
//         ),
//     );
//     sagaMiddleware.run(sagas);
//     return store;
// }

// const store = configureStore();
// export default store;

// // export function configureStore(initialState) {

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

//     const store = createStore(
//         reducers,
//         initialState,
//         composeEnhancers(
//             applyMiddleware(...middlewares)
//         ),
//     );

//     let persistor = persistStore(store)

//     return { store, persistor, sagaMiddleware }

// // }

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
let store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);
let persistor = persistStore(store);

export default { store, persistor, sagaMiddleware };
