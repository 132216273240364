import { REHYDRATE } from 'redux-persist';

// @flow
import {
  GET_PRODUCT_RECEIPT,
  GET_PRODUCT_RECEIPT_SUCCESS,
  GET_PRODUCT_RECEIPT_FAILED,
  GET_PRODUCT_RECEIPT_API_SUCCESS,
  ADD_PRODUCT_RECEIPT,
  ADD_PRODUCT_RECEIPT_SUCCESS,
  ADD_PRODUCT_RECEIPT_FAILED,
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_FAILED,
  CREATE_INVOICE,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_FAILED,
  GET_INVOICE_TABLE_SUCCESS,
  GET_PRODUCT_SUPPLIER_COMMAND,
  GET_PRODUCT_SUPPLIER_COMMAND_SUCCESS,
  GET_PRODUCT_SUPPLIER_COMMAND_FAILED,
  ADD_PRODUCT_SUPPLIER_COMMAND,
  ADD_PRODUCT_SUPPLIER_COMMAND_SUCCESS,
  ADD_PRODUCT_SUPPLIER_COMMAND_FAILED,
  GET_PRODUCT_CUSTOMER_COMMAND,
  GET_PRODUCT_CUSTOMER_COMMAND_SUCCESS,
  GET_PRODUCT_CUSTOMER_COMMAND_FAILED,
  ADD_PRODUCT_CUSTOMER_COMMAND,
  ADD_PRODUCT_CUSTOMER_COMMAND_SUCCESS,
  ADD_PRODUCT_CUSTOMER_COMMAND_FAILED,
  ADD_PRODUCT_DELIVERY,
  ADD_PRODUCT_DELIVERY_SUCCESS,
  ADD_PRODUCT_DELIVERY_FAILED,
  GET_PRODUCT_DELIVERY,
  GET_PRODUCT_DELIVERY_SUCCESS,
  GET_PRODUCT_DELIVERY_FAILED,
  EDIT_PRODUCT_SUPPLIER_COMMAND,
  EDIT_PRODUCT_SUPPLIER_COMMAND_SUCCESS,
  EDIT_PRODUCT_SUPPLIER_COMMAND_FAILED,
  EDIT_PRODUCT_CUSTOMER_COMMAND,
  EDIT_PRODUCT_CUSTOMER_COMMAND_SUCCESS,
  EDIT_PRODUCT_CUSTOMER_COMMAND_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
  productReceiptsAPI: [],
  productReceipts: [],
  productCommandsAPI: [],
  productCommands: [],
  productSupplierCommands: [],
  productCustomerCommands: [],
  productDelivery: [],
  invoices: [],
  invoicesTable: [],
  loading: false,
  error: false,
};

const Management = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCT_SUPPLIER_COMMAND:
      return { ...state, loading: true };
    case GET_PRODUCT_SUPPLIER_COMMAND_SUCCESS:
      return {
        ...state,
        productSupplierCommands: action.payload,
        loading: false,
        error: null,
      };
    case GET_PRODUCT_SUPPLIER_COMMAND_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
        error: null,
      };

    case EDIT_PRODUCT_CUSTOMER_COMMAND:
      return { ...state, loading: true };
    case EDIT_PRODUCT_CUSTOMER_COMMAND_SUCCESS:
      const pcc = state.productCustomerCommands.map((pc) => {
        if (pc._id === action.payload._id) {
          pc = action.payload;
        }
        return pc;
      });

      return {
        ...state,
        productCustomerCommands: pcc,
        loading: false,
        error: null,
      };
    case EDIT_PRODUCT_CUSTOMER_COMMAND_FAILED:
      return { ...state, error: action.payload, loading: false };

    case EDIT_PRODUCT_SUPPLIER_COMMAND:
      return { ...state, loading: true };
    case EDIT_PRODUCT_SUPPLIER_COMMAND_SUCCESS:
      const psc = state.productSupplierCommands.map((pc) => {
        if (pc._id === action.payload._id) {
          pc = action.payload;
        }
        return pc;
      });

      return {
        ...state,
        productSupplierCommands: psc,
        loading: false,
        error: null,
      };
    case EDIT_PRODUCT_SUPPLIER_COMMAND_FAILED:
      return { ...state, error: action.payload, loading: false };

    case ADD_PRODUCT_SUPPLIER_COMMAND:
      return { ...state, loading: true };
    case ADD_PRODUCT_SUPPLIER_COMMAND_SUCCESS:
      return {
        ...state,
        productSupplierCommands: [
          ...state.productSupplierCommands,
          action.payload,
        ],
        loading: false,
        error: null,
      };
    case ADD_PRODUCT_SUPPLIER_COMMAND_FAILED:
      return { ...state, error: action.payload, loading: false };

    case GET_PRODUCT_CUSTOMER_COMMAND:
      return { ...state, loading: true };
    case GET_PRODUCT_CUSTOMER_COMMAND_SUCCESS:
      return {
        ...state,
        productCustomerCommands: action.payload,
        loading: false,
        error: null,
      };
    case GET_PRODUCT_CUSTOMER_COMMAND_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
        error: null,
      };

    case ADD_PRODUCT_CUSTOMER_COMMAND:
      return { ...state, loading: true };
    case ADD_PRODUCT_CUSTOMER_COMMAND_SUCCESS:
      return {
        ...state,
        productCustomerCommands: [
          ...state.productCustomerCommands,
          action.payload,
        ],
        loading: false,
        error: null,
      };
    case ADD_PRODUCT_CUSTOMER_COMMAND_FAILED:
      return { ...state, error: action.payload, loading: false };

    case GET_PRODUCT_RECEIPT:
      return { ...state, loading: true };
    case GET_PRODUCT_RECEIPT_SUCCESS:
      return {
        ...state,
        productReceipts: action.payload,
        loading: false,
        error: null,
      };
    case GET_PRODUCT_RECEIPT_API_SUCCESS:
      return {
        ...state,
        productReceiptsAPI: action.payload,
        loading: false,
        error: null,
      };
    case GET_PRODUCT_RECEIPT_FAILED:
      return { ...state, error: action.payload, loading: false };

    case GET_PRODUCT_DELIVERY:
      return { ...state, loading: true };
    case GET_PRODUCT_DELIVERY_SUCCESS:
      return {
        ...state,
        productDelivery: action.payload,
        loading: false,
        error: null,
      };
    case GET_PRODUCT_DELIVERY_FAILED:
      return { ...state, error: action.payload, loading: false };

    case ADD_PRODUCT_RECEIPT:
      return { ...state, loading: true };
    case ADD_PRODUCT_RECEIPT_SUCCESS:
      return {
        ...state,
        productReceipts: [...state.productReceipts, action.payload],
        loading: false,
        error: null,
      };
    case ADD_PRODUCT_RECEIPT_FAILED:
      return { ...state, error: action.payload, loading: false };

    case ADD_PRODUCT_DELIVERY:
      return { ...state, loading: true };
    case ADD_PRODUCT_DELIVERY_SUCCESS:
      return {
        ...state,
        productDelivery: [...state.productDelivery, action.payload],
        loading: false,
        error: null,
      };
    case ADD_PRODUCT_DELIVERY_FAILED:
      return { ...state, error: action.payload, loading: false };

    case CREATE_INVOICE:
      return { ...state, loading: true };
    case CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: [...state.invoices, action.payload],
        loading: false,
        error: null,
      };
    case CREATE_INVOICE_FAILED:
      return { ...state, error: action.payload, loading: false };

    case GET_INVOICE:
      return { ...state, loading: true };
    case GET_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        loading: false,
        error: null,
      };
    case GET_INVOICE_TABLE_SUCCESS:
      return {
        ...state,
        invoicesTable: action.payload,
        loading: false,
        error: null,
      };
    case GET_INVOICE_FAILED:
      return { ...state, error: action.payload, loading: false };

    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.Management,
          }
        : {
            ...state,
          };
    default:
      return { ...state };
  }
};

export default Management;
