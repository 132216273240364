import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { primaryBookTableColor } from '../../theme/color';

function BooktableAlert() {
  const BkAlert = useSelector((state) => state.BooktableAlert);
  const dispatch = useDispatch();
  if (!BkAlert.show) return null;
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <div
        onClick={() => {
          if (BkAlert.cancelable)
            dispatch({ type: 'HIDEBOOKTABLEALERT' });
        }}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          zIndex: 10000,
          backgroundColor: '#00000099',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          zIndex: 10000,
          backgroundColor: '#00000000',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pointerEvents: 'none',
        }}
      >
        <div
          style={{
            width: '35vw',
            backgroundColor: primaryBookTableColor,
            borderRadius: 2,
            padding: 16,
            display: 'flex',
            flexDirection: 'column',
            pointerEvents: 'all',
          }}
        >
          <i
            style={{
              height: 35,
              margin: '10px auto 0px',
              fontSize: 35,
            }}
            className={BkAlert.iClass}
          />
          <h4 style={{ textAlign: 'center', color: 'white' }}>
            {BkAlert.title}
          </h4>
          <p
            style={{
              color: 'white',
              flexGrow: 1,
              textAlign: 'center',
              margin: '10px auto 15px',
            }}
          >
            {BkAlert.body}
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            {!BkAlert.asking && (
              <button
                style={{
                  backgroundColor: 'white',
                  borderWidth: 0,
                  padding: '5px 15px',
                  margin: '0 2px',
                  textTransform: 'uppercase',
                }}
                onClick={() => {
                  dispatch({ type: 'HIDEBOOKTABLEALERT' });
                }}
              >
                OK
              </button>
            )}

            {BkAlert.asking && (
              <>
                <button
                  onClick={async () => {
                    if (BkAlert.asking) {
                      await BkAlert.success();
                      dispatch({ type: 'HIDEBOOKTABLEALERT' });
                    }
                  }}
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 0,
                    padding: '5px 15px',
                    margin: '0 2px',
                    textTransform: 'uppercase',
                  }}
                >
                  yes
                </button>
                <button
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 0,
                    padding: '5px 15px',
                    margin: '0 2px',
                    textTransform: 'uppercase',
                  }}
                  onClick={() => {
                    BkAlert.error();
                    dispatch({ type: 'HIDEBOOKTABLEALERT' });
                  }}
                >
                  no
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { BooktableAlert };
