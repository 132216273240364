// @flow
import {
  GET_AFFILIATION_ID,
  GET_AFFILIATION_ID_SUCCESS,
  GET_AFFILIATION_ID_FAILED,
} from '../../constants/actionTypes';

export const getAffiliationIdFailed = (error) => ({
  type: GET_AFFILIATION_ID_FAILED,
  payload: error,
});

export const getAffiliationId = (pointOfSaleId) => ({
  type: GET_AFFILIATION_ID,
  payload: { pointOfSaleId },
});

export const getAffiliationIdSuccess = (data) => ({
  type: GET_AFFILIATION_ID_SUCCESS,
  payload: data,
});
