// @flow
import {
  SETSPECIALDAYS,
  CALLSPECIALDAYS,
  SAVESPECIALDAYS,
} from '../../constants/actionTypes';

import { REHYDRATE } from 'redux-persist';
const initialState = {
  SpecialDays: [],
  loading: false,
};

const SpecialDays = (state = initialState, action) => {
  switch (action.type) {
    case SETSPECIALDAYS:
      console.log(action);
      return { SpecialDays: action.payload, loading: false };
    case CALLSPECIALDAYS:
      return { ...state, loading: true };
    case SAVESPECIALDAYS:
      return { ...state, loading: true };
    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.SpecialDays,
          }
        : {
            ...state,
          };
    default:
      return state;
  }
};

export default SpecialDays;
