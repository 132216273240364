// @flow
import {
  GET_POINT_OF_SALE_CONTACT,
  GET_POINT_OF_SALE_CONTACT_SUCCESS,
  GET_POINT_OF_SALE_CONTACT_FAILED,
  ADD_POINT_OF_SALE_CONTACT,
  ADD_POINT_OF_SALE_CONTACT_SUCCESS,
  ADD_POINT_OF_SALE_CONTACT_FAILED,
  EDIT_POINT_OF_SALE_CONTACT,
  EDIT_POINT_OF_SALE_CONTACT_SUCCESS,
  EDIT_POINT_OF_SALE_CONTACT_FAILED,
} from '../../constants/actionTypes';

export const getPointOfSaleCompany = (pointOfSaleId) => ({
  type: GET_POINT_OF_SALE_CONTACT,
  payload: { pointOfSaleId },
});

export const getPointOfSaleCompanySuccess = (contacts) => ({
  type: GET_POINT_OF_SALE_CONTACT_SUCCESS,
  payload: contacts,
});

export const getPointOfSaleCompanyFailed = (error) => ({
  type: GET_POINT_OF_SALE_CONTACT_FAILED,
  payload: error,
});

export const addPointOfSaleCompany = (
  {
    pointOfSaleId,
    email,
    phone,
    role,
    companyName,
    website,
    address,
    rc,
    ice,
    client,
  },
  callback,
) => ({
  type: ADD_POINT_OF_SALE_CONTACT,
  payload: {
    pointOfSaleId,
    email,
    phone,
    role,
    companyName,
    website,
    address,
    rc,
    ice,
    client,
    callback,
  },
});

export const addPointOfSaleCompanySuccess = (contact) => ({
  type: ADD_POINT_OF_SALE_CONTACT_SUCCESS,
  payload: contact,
});

export const addPointOfSaleCompanyFailed = (error) => ({
  type: ADD_POINT_OF_SALE_CONTACT_FAILED,
  payload: error,
});

export const editPointOfSaleCompany = (
  {
    id,
    email,
    phone,
    role,
    companyName,
    website,
    address,
    rc,
    ice,
    client,
  },
  callback,
) => ({
  type: EDIT_POINT_OF_SALE_CONTACT,
  payload: {
    id,
    email,
    phone,
    role,
    companyName,
    website,
    address,
    rc,
    ice,
    client,
    callback,
  },
});

export const editPointOfSaleCompanySuccess = (contact) => ({
  type: EDIT_POINT_OF_SALE_CONTACT_SUCCESS,
  payload: contact,
});

export const editPointOfSaleCompanyFailed = (error) => ({
  type: EDIT_POINT_OF_SALE_CONTACT_FAILED,
  payload: error,
});
