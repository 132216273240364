// import { useState } from "@fortawesome/react-fontawesome";
import React, { useState } from 'react';

const Header = ({ headers, onSorting, width, textAlign="left" }) => {
  const [sortingField, setSortingField] = useState('');
  const [sortingOrder, setSortingOrder] = useState('asc');

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === 'asc'
        ? 'desc'
        : 'asc';

    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  return (
    <thead>
      <tr style={{ textAlign  }}>
        {headers.map(
          ({
            name,
            field,
            sortable,
            type,
            icon,
            width,
            style = {},
          }) => {
            {
              console.log('=====type===', type);
            }
            return (
              <th
                className={'thead-light'}
                key={name}
                style={{
                  width: `${width ? width + 'px' : ''}`,
                  ...style,
                }}
                onClick={() =>
                  sortable ? onSortingChange(field) : null
                }
              >
                {type === 'icon' ? (
                  <i className={icon}></i>
                ) : (
                  `${name}`
                )}

                {/* {sortingField && sortingField === field && (
                      <FontAwesomeIcon
                        icon={sortingOrder === "asc" ? "arrow-down" : "arrow-up"}
                      />
                    )} */}
              </th>
            );
          },
        )}
      </tr>
    </thead>
  );
};

export default Header;
