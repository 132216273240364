// @flow
import {
  GET_WEBSITE,
  GET_WEBSITE_FAILED,
  GET_WEBSITE_SUCCESS,
  UPDATE_WEBSITE_GENERAL,
  UPDATE_WEBSITE_GENERAL_SUCCESS,
  UPDATE_WEBSITE_GENERAL_FAILED,
  UPDATE_WEBSITE_NEWSLETTER,
  UPDATE_WEBSITE_NEWSLETTER_SUCCESS,
  UPDATE_WEBSITE_NEWSLETTER_FAILED,
  UPDATE_WEBSITE_MENU,
  UPDATE_WEBSITE_MENU_SUCCESS,
  UPDATE_WEBSITE_MENU_FAILED,
  UPDATE_WEBSITE_HEADER,
  UPDATE_WEBSITE_HEADER_SUCCESS,
  UPDATE_WEBSITE_HEADER_FAILED,
  UPDATE_WEBSITE_CHEF_WORD,
  UPDATE_WEBSITE_CHEF_WORD_SUCCESS,
  UPDATE_WEBSITE_CHEF_WORD_FAILED,
  UPDATE_WEBSITE_HISTORY,
  UPDATE_WEBSITE_HISTORY_SUCCESS,
  UPDATE_WEBSITE_HISTORY_FAILED,
  UPDATE_WEBSITE_GALLERY,
  UPDATE_WEBSITE_GALLERY_SUCCESS,
  UPDATE_WEBSITE_GALLERY_FAILED,
  DELETE_GALLERY_ITEM,
  DELETE_GALLERY_ITEM_SUCCESS,
  DELETE_GALLERY_ITEM_FAILED,
} from '../../constants/actionTypes';

export const updateWebsiteGeneral = (
  pointOfSaleId,
  primaryColor,
  primaryFont,
  secondaryColor,
  secondaryFont,
  facebookLink,
  instagramLink,
) => ({
  type: UPDATE_WEBSITE_GENERAL,
  payload: {
    pointOfSaleId,
    primaryColor,
    primaryFont,
    secondaryColor,
    secondaryFont,
    facebookLink,
    instagramLink,
  },
});

export const updateWebsiteGeneralSuccess = (website) => ({
  type: UPDATE_WEBSITE_GENERAL_SUCCESS,
  payload: website,
});

export const updateWebsiteGeneralFailed = (error) => ({
  type: UPDATE_WEBSITE_GENERAL_FAILED,
  payload: error,
});

export const getWebsite = (pointOfSaleId) => ({
  type: GET_WEBSITE,
  payload: { pointOfSaleId },
});

export const getWebsiteSuccess = (website) => ({
  type: GET_WEBSITE_SUCCESS,
  payload: website,
});

export const getWebsiteFailed = (error) => ({
  type: GET_WEBSITE_FAILED,
  payload: error,
});

export const updateWebsiteNewsletter = (
  pointOfSaleId,
  newsletterTitle,
) => ({
  type: UPDATE_WEBSITE_NEWSLETTER,
  payload: { pointOfSaleId, newsletterTitle },
});

export const updateWebsiteNewsletterSuccess = (website) => ({
  type: UPDATE_WEBSITE_NEWSLETTER_SUCCESS,
  payload: website,
});

export const updateWebsiteNewsletterFailed = (error) => ({
  type: UPDATE_WEBSITE_NEWSLETTER_FAILED,
  payload: error,
});

export const updateWebsiteMenu = (
  pointOfSaleId,
  menuSubTitle,
  menuTitle,
) => ({
  type: UPDATE_WEBSITE_MENU,
  payload: { pointOfSaleId, menuSubTitle, menuTitle },
});

export const updateWebsiteMenuSuccess = (website) => ({
  type: UPDATE_WEBSITE_MENU_SUCCESS,
  payload: website,
});

export const updateWebsiteMenuFailed = (error) => ({
  type: UPDATE_WEBSITE_MENU_FAILED,
  payload: error,
});

export const updateWebsiteHeader = (
  pointOfSaleId,
  backgroundImageHeader,
) => ({
  type: UPDATE_WEBSITE_HEADER,
  payload: { pointOfSaleId, backgroundImageHeader },
});

export const updateWebsiteHeaderSuccess = (website) => ({
  type: UPDATE_WEBSITE_HEADER_SUCCESS,
  payload: website,
});

export const updateWebsiteHeaderFailed = (error) => ({
  type: UPDATE_WEBSITE_HEADER_FAILED,
  payload: error,
});

export const updateWebsiteChefWord = (
  pointOfSaleId,
  chefPhoto,
  chefWord,
  chefName,
) => ({
  type: UPDATE_WEBSITE_CHEF_WORD,
  payload: { pointOfSaleId, chefPhoto, chefWord, chefName },
});

export const updateWebsiteChefWordSuccess = (website) => ({
  type: UPDATE_WEBSITE_CHEF_WORD_SUCCESS,
  payload: website,
});

export const updateWebsiteChefWordFailed = (error) => ({
  type: UPDATE_WEBSITE_CHEF_WORD_FAILED,
  payload: error,
});

export const updateWebsiteHistory = (
  pointOfSaleId,
  historyPhoto,
  historyWord,
  historyTitle,
) => ({
  type: UPDATE_WEBSITE_HISTORY,
  payload: { pointOfSaleId, historyPhoto, historyWord, historyTitle },
});

export const updateWebsiteHistorySuccess = (website) => ({
  type: UPDATE_WEBSITE_HISTORY_SUCCESS,
  payload: website,
});

export const updateWebsiteHistoryFailed = (error) => ({
  type: UPDATE_WEBSITE_HISTORY_FAILED,
  payload: error,
});

export const updateWebsiteGallery = (
  pointOfSaleId,
  galleryTitle,
  gallerySubTitle,
  gallery,
  callback,
) => ({
  type: UPDATE_WEBSITE_GALLERY,
  payload: {
    pointOfSaleId,
    galleryTitle,
    gallerySubTitle,
    gallery,
    callback,
  },
});

export const updateWebsiteGallerySuccess = (website) => ({
  type: UPDATE_WEBSITE_GALLERY_SUCCESS,
  payload: website,
});

export const updateWebsiteGalleryFailed = (error) => ({
  type: UPDATE_WEBSITE_GALLERY_FAILED,
  payload: error,
});
export const deleteGalleryItem = (pointOfSaleId, photoId) => ({
  type: DELETE_GALLERY_ITEM,
  payload: { pointOfSaleId, photoId },
});

export const deleteGalleryItemSuccess = (website) => ({
  type: DELETE_GALLERY_ITEM_SUCCESS,
  payload: website,
});

export const deleteGalleryItemFailed = (error) => ({
  type: DELETE_GALLERY_ITEM_FAILED,
  payload: error,
});
