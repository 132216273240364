// @flow
import {
  GET_RESERVATION_TICKET,
  SET_RESERVATION_TICKET,
} from '../../constants/actionTypes';

export const getReservationTicket = (id) => ({
  type: GET_RESERVATION_TICKET,
  payload: id,
});

export const setReservationTicket = (
  reservation,
  yellowReservations,
) => ({
  type: SET_RESERVATION_TICKET,
  payload: { reservation, yellowReservations },
});
