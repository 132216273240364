// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { GET_ADMIN_ALL_COMPANY } from '../../../constants/actionTypes';
import { getAllCompanyFailed, getAllCompanySuccess } from './actions';
import { getAllCompanyService } from './service';

function* getAllCompany({ payload: {} }) {
  try {
    const response = yield call(getAllCompanyService);
    yield put(getAllCompanySuccess(response));
  } catch (error) {
    console.log(error);
    yield put(getAllCompanyFailed(error));
  }
}

export function* watchGetCompany() {
  yield takeEvery(GET_ADMIN_ALL_COMPANY, getAllCompany);
}

function* SupAdminCompanySaga() {
  yield all([fork(watchGetCompany)]);
}

export default SupAdminCompanySaga;
