import { httpClient_post } from '../../helpers/api';

const ChangeTimePerBook = (
  pointOfSaleId,
  timePerBooking,
  NoShow,
  NbCommand,
  timeCommand,
  zones,
  enableSendSMS,
) => {
  return httpClient_post(`/pointofsale/change-time-per-booking`, {
    timePerBooking,
    pointOfSaleId,
    NoShow,
    NbCommand,
    timeCommand,
    zones,
    enableSendSMS,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
const getSettings = (pointOfSaleId) => {
  return httpClient_post(`/pointofsale/settings`, { pointOfSaleId })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export { ChangeTimePerBook, getSettings };
