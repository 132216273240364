// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { GET_ADMIN_ALL_CONTACT } from '../../../constants/actionTypes';
import {
  getAllContactsFailed,
  getAllContactsSuccess,
} from './actions';
import { getAllContactsService } from './service';

function* allContacts({ payload: {} }) {
  try {
    console.log('----allContacts---');
    const response = yield call(getAllContactsService);
    yield put(getAllContactsSuccess(response));
  } catch (error) {
    yield put(getAllContactsFailed(error));
  }
}

export function* watchAllContact() {
  yield takeEvery(GET_ADMIN_ALL_CONTACT, allContacts);
}

function* SupAdminContactsSaga() {
  yield all([fork(watchAllContact)]);
}

export default SupAdminContactsSaga;
