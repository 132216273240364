// @flow
import {
  ADD_BOOKING,
  ADD_BOOKING_SUCCESS,
  ADD_BOOKING_FAILED,
  GET_BOOKING,
  GET_BOOKING_SUCCESS,
  GET_BOOKING_FAILED,
  GET_RESERVATION,
  SEARCH_BOOKING,
  GET_BOOKING_C,
  GET_BOOKING_C_SUCCESS,
  GET_BOOKING_C_FAILED,
  SEARCH_BOOKING_SUCCESS,
} from '../../constants/actionTypes';

import { REHYDRATE } from 'redux-persist';
import moment from 'moment';

const initialState = {
  loading: false,
  error: false,
  bookings: [],
  searchedBookings: [],
  booking_c: {},
  showSerch: false,
};

const Booking = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BOOKING:
      return { ...state, loading: true };
    case ADD_BOOKING_SUCCESS:
      return { ...state, loading: false };
    case ADD_BOOKING_FAILED:
      return { ...state, loading: false, error: action.payload };
    case SEARCH_BOOKING:
      return { ...state, loading: true };
    case SEARCH_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        searchedBookings: action.payload.bookings,
        showSerch: action.payload.active,
      };

    case GET_BOOKING:
      return { ...state, loading: true };
    case GET_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        bookings: action.payload,
        searchedBookings: [],
        showSerch: false,
      };
    case GET_BOOKING_FAILED:
      return { ...state, loading: false, error: action.payload };

    case GET_BOOKING_C:
      return { ...state, loading: true };
    case GET_BOOKING_C_SUCCESS:
      return { ...state, loading: false, booking_c: action.payload };
    case GET_BOOKING_C_FAILED:
      return { ...state, loading: false, error: action.payload };

    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.Booking,
          }
        : {
            ...state,
          };
    default:
      return state;
  }
};

export default Booking;
