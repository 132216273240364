// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  GETTIMEPERBOOKING,
  SETTIMEPERBOOKING,
} from '../../constants/actionTypes';
import { getTimePerBookingSuccess } from './actions';
import { ChangeTimePerBook, getSettings } from './Services';

// import { ChangeTimePerBook } from "./Services";

function* changeTimePerBookSaga({
  payload: {
    pointOfSaleId,
    time,
    NoShow,
    NbCommand,
    timeCommand,
    zones,
    enableSendSMS,
  },
}) {
  try {
    const response = yield call(
      ChangeTimePerBook,
      pointOfSaleId,
      time,
      NoShow,
      NbCommand,
      timeCommand,
      zones,
      enableSendSMS,
    );
    // console.log(response.settings.timePerBooking)
    console.log('changeTimePerBookSaga');
    console.log(response.settings);
    yield put(
      getTimePerBookingSuccess(
        response.settings.pointOfSale,
        response.settings.timePerBooking,
        response.settings.NoShowTime,
        response.settings.NbCommand,
        response.settings.timePerCommand,
        response.settings.zones,
        response.settings.enableSendSMS,
      ),
    );
  } catch (error) {}
}

function* getSettingsSaga({ payload: { pointOfSaleId } }) {
  try {
    const response = yield call(getSettings, pointOfSaleId);

    // console.log('================getSettingsSaga=============');
    // console.log(response.settings);
    // yield put(setTimePerBookingFn(response.settings.timePerBooking));

    yield put(
      getTimePerBookingSuccess(
        response.settings.pointOfSale,
        response.settings.timePerBooking,
        response.settings.NoShowTime,
        response.settings.NbCommand,
        response.settings.timePerCommand,
        response.settings.zones,
        response.settings.enableSendSMS,
      ),
    );
  } catch (error) {
    // yield put(getUsersFailed(error));
  }
}

export function* WatchTimePerBook() {
  yield takeEvery(SETTIMEPERBOOKING, changeTimePerBookSaga);
}

export function* watchGetTimePerBook() {
  yield takeEvery(GETTIMEPERBOOKING, getSettingsSaga);
}

function* tablesSaga() {
  yield all([fork(WatchTimePerBook), fork(watchGetTimePerBook)]);
}

export default tablesSaga;
