// @flow
import {
  ADD_RESTAURANT,
  ADD_RESTAURANT_CLIENT,
  ADD_RESTAURANT_CLIENT_FAILED,
  ADD_RESTAURANT_CLIENT_SUCCESS,
  ADD_RESTAURANT_FAILED,
  ADD_RESTAURANT_SUCCESS,
  EDIT_RESTAURANT,
  EDIT_RESTAURANT_FAILED,
  EDIT_RESTAURANT_SUCCESS,
  GET_RESTAURANTS,
  GET_RESTAURANTS_FAILED,
  GET_RESTAURANTS_SUCCESS,
  SHOW_RESTAURANT,
  SHOW_RESTAURANT_SUCCESS,
  GET_RESTAURANTS_CLIENT,
  GET_RESTAURANTS_CLIENT_FAILED,
  GET_RESTAURANTS_CLIENT_SUCCESS,
  SET_POINT_OF_SALE_UPDATE_AT,
} from '../../constants/actionTypes';

export const getRestaurants = () => ({
  type: GET_RESTAURANTS,
  payload: {},
});

export const getRestaurantsSuccess = (list) => ({
  type: GET_RESTAURANTS_SUCCESS,
  payload: list,
});
export const getRestaurantsFailed = (error) => ({
  type: GET_RESTAURANTS_FAILED,
  payload: error,
});

export const addRestaurant = (restaurant, createAdmin, callback) => ({
  type: ADD_RESTAURANT,
  payload: { restaurant, createAdmin, callback },
});

export const addRestaurantSuccess = (restaurant) => ({
  type: ADD_RESTAURANT_SUCCESS,
  payload: restaurant,
});
export const addRestaurantFailed = (error) => ({
  type: ADD_RESTAURANT_FAILED,
  payload: error,
});

export const editRestaurant = (pointOfSale, callback) => ({
  type: EDIT_RESTAURANT,
  payload: { pointOfSale, callback },
});

export const editRestaurantSuccess = (restaurant) => ({
  type: EDIT_RESTAURANT_SUCCESS,
  payload: restaurant,
});
export const editRestaurantFailed = (error) => ({
  type: EDIT_RESTAURANT_FAILED,
  payload: error,
});

export const addRestaurantClient = (
  restaurant,
  callbackSuccess,
  affiliateId,
) => ({
  type: ADD_RESTAURANT_CLIENT,
  payload: { restaurant, affiliateId, callbackSuccess },
});

export const addRestaurantClientSuccess = (restaurant) => ({
  type: ADD_RESTAURANT_CLIENT_SUCCESS,
  payload: restaurant,
});
export const addRestaurantClientFailed = (error) => ({
  type: ADD_RESTAURANT_CLIENT_FAILED,
  payload: error,
});

export const showRestaurant = (id, show) => ({
  type: SHOW_RESTAURANT,
  payload: { id, show },
});

export const showRestaurantSuccess = (restaurant) => ({
  type: SHOW_RESTAURANT_SUCCESS,
  payload: restaurant,
});

export const getRestaurantsClient = (idSuperVisor) => ({
  type: GET_RESTAURANTS_CLIENT,
  payload: { idSuperVisor },
});

export const getRestaurantsClientSuccess = (list) => ({
  type: GET_RESTAURANTS_CLIENT_SUCCESS,
  payload: list,
});
export const getRestaurantsClientFailed = (error) => ({
  type: GET_RESTAURANTS_CLIENT_FAILED,
  payload: error,
});

export const setPointOfSaleUpdateAt = (id) => ({
  type: SET_POINT_OF_SALE_UPDATE_AT,
  payload: id,
});
