import { httpClient_get, httpClient_post } from '../../helpers/api';

const logFormData = (formData) => {
  const entries = formData.entries();
  const result = {};
  let next;
  let pair;
  while ((next = entries.next()) && next.done === false) {
    pair = next.value;
    result[pair[0]] = pair[1];
  }
  console.log(result);
};

const getMenuService = (pointOfSaleId) => {
  return httpClient_post('/pointofsale/get-menu', { pointOfSaleId })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const addMenuService = (menu, pointOfSaleId) => {
  try {
    const formData = new FormData();

    formData.append('menuData', JSON.stringify(menu.menuData));
    formData.append('pointOfSaleId', pointOfSaleId);

    for (const file of menu.categoryItemImages) {
      formData.append('categoryItemImages', file);
    }

    return httpClient_post('/pointofsale/menu', formData, {
      'Content-Type': 'multipart/form-data',
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    console.log(error);
  }
};

const deleteMenuService = (menuCategorieId, categoryItemId) => {
  const _menuCategorieId_ =
    menuCategorieId !== null && menuCategorieId;
  const _categoryItemId_ = categoryItemId !== null && categoryItemId;
  return httpClient_post('/pointofsale/menu/delete', {
    menuCategorieId,
    categoryItemId,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const primaryMenuService = (id, pointOfSaleId) => {
  return httpClient_post(`/pointofsale/menu/${id}/primary`, {
    pointOfSaleId,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getMenuClientService = (id) => {
  return httpClient_get(`/pointofsale/menu/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getSettingsMenuService = (id) => {
  return httpClient_get(`/menu-settings/get/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const saveSettingsMenuService = (id, data) => {
  return httpClient_post(`/menu-settings/set/${id}`, { ...data })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export {
  getMenuService,
  addMenuService,
  deleteMenuService,
  primaryMenuService,
  getMenuClientService,
  getSettingsMenuService,
  saveSettingsMenuService,
};
