import React, {Component, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import { EditorState, AtomicBlockUtils } from "draft-js";
import links from '../../../helpers/links.json';
import { getClearSelectionEditor } from "./draft";
import {
    Button,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Modal,
    FormGroup,
    Label,
    Input,
    Col,
    InputGroupAddon, InputGroup, FormFeedback
} from "reactstrap";
import style from "../style.module.scss"
import styled from "styled-components";
import axios from "axios"
import {useForm} from "react-hook-form";
import {ProgressBar} from "react-bootstrap";
import {getLoggedInUser} from "../../../helpers/authUtils";
import _ from 'lodash';



const ListImage = styled.div`

    display:flex;
  flex-wrap:wrap;


`
const ImageContainer = styled.div`
  background-color: #f1f1f1;
  width: 105px;
  margin: 5px;
  text-align: center;
  line-height: 75px;
  img{
    width:100px;
    height:50px;
  }
  //font-size: 30px;
`


const  ImageOption =  (props)=> {

    const user = getLoggedInUser();
    const axiosInstance = axios.create({
        baseURL: links.api,
        headers: {
            Authorization: 'Bearer ' + user?.token,
        }

    })


    const refListImage = useRef(new Set());

    const [selectedFiles, setSelectedFiles] = useState()
    const [progress, setProgress] = useState()
    const [modal, setModal] = useState(false);
    const [listImage, setListImage] = useState([]);
    const [selectedImage, setSelectedImage] = useState([]);
    const [url, setUrl] = useState();

    const file = useRef();

    const { register, handleSubmit, watch, formState: { errors } , setValue ,trigger , reset} = useForm();


    const addImage = (event) =>{
        setModal(true)
    }


    useEffect(() => {
        (async ()=>{

            const res = await axiosInstance.get("/keo-admin/upxp-management/notification/get-images");
                setListImage(res.data.listImg)
            }
        )()
    },[])

    const onSubmit = async (data) => {
           let formData = new FormData();
           formData.append("image", selectedFiles)
           const res =  await axiosInstance.post("/keo-admin/upxp-management/notification/add-image", formData, {
                headers: {"Content-Type": "multipart/form-data"},
                onUploadProgress: data => {
                    const prog =  Math.round((100 * data.loaded) / data.total);

                    setProgress(prog);

                    if (prog === 100){
                        setTimeout(() =>{
                            setProgress(null);
                            reset();

                        },2e3)
                    }

                },
            })
           setListImage((prv) => [...prv,res.data.image]  )
    }

    const onSelectImage = (elem_,index)=>(e)=>{

        // console.log("---index--",elem_,index)
        setSelectedImage(listImage[index]);

        [...refListImage.current].map((elem,i)=>{
            console.log("|--->>>", elem_._id);
            console.log("|elem->>>", elem);
            // console.log("|elem->>>", elem);
            return   !!elem && elem?.id === elem_._id ? elem?.node?.classList.add(style.selected) : elem?.node?.classList.remove(style.selected);
        })
    }

    const save =(event)=>{

        setModal(false);

        if ( !_.isEmpty(selectedImage))
        {
            event.preventDefault();

            const { editorState, onChange } = props;
            const contentStateWithEntity = editorState
                .getCurrentContent()
                .createEntity("IMAGE", "IMMUTABLE", {
                    src:`${links.api}/${selectedImage.path}`,
                    alt: "minio",
                    height: "260px",
                    width: "380px",
                    'object-fit': 'contain',

                });
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const tmpEditorState = EditorState.set(editorState, {
                currentContent: contentStateWithEntity
            });
            const newEditorState = AtomicBlockUtils.insertAtomicBlock(
                tmpEditorState,
                entityKey,
                " "
            );
            onChange(getClearSelectionEditor(newEditorState));
            setSelectedImage(null);
        }

    }

    const onToggle = ()=>{

        setSelectedImage(null);
        setModal(false);
        refListImage.current.clear()

    }

        return (
            <>
                {modal && <Modal isOpen={modal} toggle={onToggle}>
                    <ModalHeader>Modal title</ModalHeader>
                    <ModalBody>
                        <form >
                            <input
                                ref={file}
                                type="file"
                                style={{display: "none"}}
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if(file){
                                        setSelectedFiles(file);
                                        setValue('file', file.name);
                                        trigger().then(r => r);
                                    }

                                }}
                            />
                            <InputGroup>
                                <InputGroupAddon onClick={(e) => file.current.click()} addonType="append">
                                    <lable htmlFor="file" className={'btn btn-secondary'}>
                                        <i className={"fa fa-plus"} ></i>
                                    </lable>
                                </InputGroupAddon>
                                <Input
                                    disabled
                                    type="text"
                                    name="file"
                                    innerRef={register({required: true})}
                                    aria-invalid={errors.file ? "true" : "false"}
                                    invalid={!!errors.file}
                                />
                                <InputGroupAddon onClick={handleSubmit(onSubmit)} addonType={"append"}>
                                    <lable className={'btn btn-secondary'}>
                                        <i className={"fa fa-upload"} />
                                    </lable>
                                </InputGroupAddon>
                                {errors.file && <FormFeedback>Insert a image before click upload button ! </FormFeedback>}

                            </InputGroup>
                            {progress && <ProgressBar animated now={progress} label={`${progress}%`}/>}


                        </form>
                        <span>or select </span>
                        <ListImage>
                            {listImage.map((elem,index) => {
                                // console.log("|",elem)
                                return <ImageContainer onClick={onSelectImage(elem,index)}
                                                       ref={elm => !!elm && refListImage.current.add({node:elm,id:elem._id})}
                                                       key={elem._id}>
                                    <img
                                        src={`${links.api}/${elem.path}`}
                                        alt=""
                                    />
                                </ImageContainer>
                            })
                            }
                        </ListImage>


                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={save}>Select</Button>{' '}
                        <Button color="secondary" onClick={onToggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>}

                <div onClick={addImage}>
                    <div className="rdw-option-wrapper" title="Link">
                        <img
                            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTQuNzQxIDBILjI2Qy4xMTYgMCAwIC4xMzYgMCAuMzA0djEzLjM5MmMwIC4xNjguMTE2LjMwNC4yNTkuMzA0SDE0Ljc0Yy4xNDMgMCAuMjU5LS4xMzYuMjU5LS4zMDRWLjMwNEMxNSAuMTM2IDE0Ljg4NCAwIDE0Ljc0MSAwem0tLjI1OCAxMy4zOTFILjUxN1YuNjFoMTMuOTY2VjEzLjM5eiIvPjxwYXRoIGQ9Ik00LjEzOCA2LjczOGMuNzk0IDAgMS40NC0uNzYgMS40NC0xLjY5NXMtLjY0Ni0xLjY5NS0xLjQ0LTEuNjk1Yy0uNzk0IDAtMS40NC43Ni0xLjQ0IDEuNjk1IDAgLjkzNC42NDYgMS42OTUgMS40NCAxLjY5NXptMC0yLjc4MWMuNTA5IDAgLjkyMy40ODcuOTIzIDEuMDg2IDAgLjU5OC0uNDE0IDEuMDg2LS45MjMgMS4wODYtLjUwOSAwLS45MjMtLjQ4Ny0uOTIzLTEuMDg2IDAtLjU5OS40MTQtMS4wODYuOTIzLTEuMDg2ek0xLjgxIDEyLjE3NGMuMDYgMCAuMTIyLS4wMjUuMTcxLS4wNzZMNi4yIDcuNzI4bDIuNjY0IDMuMTM0YS4yMzIuMjMyIDAgMCAwIC4zNjYgMCAuMzQzLjM0MyAwIDAgMCAwLS40M0w3Ljk4NyA4Ljk2OWwyLjM3NC0zLjA2IDIuOTEyIDMuMTQyYy4xMDYuMTEzLjI3LjEwNS4zNjYtLjAyYS4zNDMuMzQzIDAgMCAwLS4wMTYtLjQzbC0zLjEwNC0zLjM0N2EuMjQ0LjI0NCAwIDAgMC0uMTg2LS4wOC4yNDUuMjQ1IDAgMCAwLS4xOC4xTDcuNjIyIDguNTM3IDYuMzk0IDcuMDk0YS4yMzIuMjMyIDAgMCAwLS4zNTQtLjAxM2wtNC40IDQuNTZhLjM0My4zNDMgMCAwIDAtLjAyNC40My4yNDMuMjQzIDAgMCAwIC4xOTQuMTAzeiIvPjwvZz48L3N2Zz4="
                            alt=""
                        />
                    </div>
                </div>

            </>
        );


    // render() {
    //     return (
    //         <div onClick={this.addImage}>
    //             <div className="rdw-option-wrapper" title="Link">
    //                 <img
    //                     src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTQuNzQxIDBILjI2Qy4xMTYgMCAwIC4xMzYgMCAuMzA0djEzLjM5MmMwIC4xNjguMTE2LjMwNC4yNTkuMzA0SDE0Ljc0Yy4xNDMgMCAuMjU5LS4xMzYuMjU5LS4zMDRWLjMwNEMxNSAuMTM2IDE0Ljg4NCAwIDE0Ljc0MSAwem0tLjI1OCAxMy4zOTFILjUxN1YuNjFoMTMuOTY2VjEzLjM5eiIvPjxwYXRoIGQ9Ik00LjEzOCA2LjczOGMuNzk0IDAgMS40NC0uNzYgMS40NC0xLjY5NXMtLjY0Ni0xLjY5NS0xLjQ0LTEuNjk1Yy0uNzk0IDAtMS40NC43Ni0xLjQ0IDEuNjk1IDAgLjkzNC42NDYgMS42OTUgMS40NCAxLjY5NXptMC0yLjc4MWMuNTA5IDAgLjkyMy40ODcuOTIzIDEuMDg2IDAgLjU5OC0uNDE0IDEuMDg2LS45MjMgMS4wODYtLjUwOSAwLS45MjMtLjQ4Ny0uOTIzLTEuMDg2IDAtLjU5OS40MTQtMS4wODYuOTIzLTEuMDg2ek0xLjgxIDEyLjE3NGMuMDYgMCAuMTIyLS4wMjUuMTcxLS4wNzZMNi4yIDcuNzI4bDIuNjY0IDMuMTM0YS4yMzIuMjMyIDAgMCAwIC4zNjYgMCAuMzQzLjM0MyAwIDAgMCAwLS40M0w3Ljk4NyA4Ljk2OWwyLjM3NC0zLjA2IDIuOTEyIDMuMTQyYy4xMDYuMTEzLjI3LjEwNS4zNjYtLjAyYS4zNDMuMzQzIDAgMCAwLS4wMTYtLjQzbC0zLjEwNC0zLjM0N2EuMjQ0LjI0NCAwIDAgMC0uMTg2LS4wOC4yNDUuMjQ1IDAgMCAwLS4xOC4xTDcuNjIyIDguNTM3IDYuMzk0IDcuMDk0YS4yMzIuMjMyIDAgMCAwLS4zNTQtLjAxM2wtNC40IDQuNTZhLjM0My4zNDMgMCAwIDAtLjAyNC40My4yNDMuMjQzIDAgMCAwIC4xOTQuMTAzeiIvPjwvZz48L3N2Zz4="
    //                     alt=""
    //                 />
    //             </div>
    //         </div>
    //     );
    // }
}

ImageOption.propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object
};

export default ImageOption;
