import React from 'react';
import PropTypes from 'prop-types';
import {withTabs} from "../../components/Tabs/TabsContext";
import {Tab, Tabs} from "../../components/Tabs/Tabs";
import Notification from "./notification/notification";
import Spots from "./spots/Spots";
import QrCodesSpots from "./QrCodesSpots";

const tabs = {
    firstTab: 'Point of Sale',
    secondTab: 'Notifications',
    thirdTab: 'QR Codes',
}

const UpxpManagement = (props) => {
    return (
        <div  >
            <Spots />
        </div>
    );
};

UpxpManagement.propTypes = {

};
UpxpManagement.defaultProps = {

};


export default withTabs(UpxpManagement);

// export default UpxpManagement;
