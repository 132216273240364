/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';

export const GET_TABLES = 'GET_TABLES';
export const GET_TABLES_SUCCESS = 'GET_TABLES_SUCCESS';
export const GET_TABLES_FAILED = 'GET_TABLES_FAILED';
export const GET_TABLES_GLOBAL = 'GET_TABLES_GLOBAL';
export const GET_TABLES_GLOBAL_SUCCESS = 'GET_TABLES_GLOBAL_SUCCESS';
export const GET_TABLES_GLOBAL_FAILED = 'GET_TABLES_GLOBAL_FAILED';
export const SAVE_TABLES = 'SAVE_TABLES';

export const EDIT_TABLE = 'EDIT_TABLE';
export const EDIT_TABLES_SUCCESS = 'EDIT_TABLES_SUCCESS';

export const ADD_BOOKING = 'ADDBOOKING';
export const ADD_BOOKING_SUCCESS = 'ADDBOOKING_SUCCESS';
export const ADD_BOOKING_FAILED = 'ADDBOOKING_FAILED';

export const GET_BOOKING = 'GET_BOOKING';
export const GET_BOOKING_SUCCESS = 'GET_BOOKING_SUCCESS';
export const GET_BOOKING_FAILED = 'GET_BOOKING_FAILED';

export const AUTH_EDIT_RESTAURANT = 'AUTH_EDIT_RESTAURANT';

export const GET_RESERVATION = 'GETRESERVATION';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';
export const GET_WEBSITE = 'GET_WEBSITE';
export const GET_WEBSITE_SUCCESS = 'GET_WEBSITE_SUCCESS';
export const GET_WEBSITE_FAILED = 'GET_WEBSITE_FAILED';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const GET_MENU = 'GET_MENU';
export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS';
export const GET_MENU_FAILED = 'GET_MENU_FAILED';

export const ADD_MENU = 'ADD_MENU';
export const ADD_MENU_SUCCESS = 'ADD_MENU_SUCCESS';
export const ADD_MENU_FAILED = 'ADD_MENU_FAILED';

export const SELECTED_MENU = 'SELECTED_MENU';

export const SEARCH_BOOKING = 'SEARCH_BOOKING';
export const SEARCH_BOOKING_SUCCESS = 'SEARCH_BOOKING_SUCCESS';
export const DELETE_MENU = 'DELETE_MENU';
export const DELETE_MENU_SUCCESS = 'DELETE_MENU_SUCCESS';
export const DELETE_MENU_FAILED = 'DELETE_MENU_FAILED';

export const DELETE_MENU_ITEM = 'DELETE_MENU_ITEM';
export const DELETE_MENU_ITEM_SUCCESS = 'DELETE_MENU_ITEM_SUCCESS';
export const DELETE_MENU_ITEM_FAILED = 'DELETE_MENU_ITEM_FAILED';

export const SETTIMEPERBOOKING = 'SETTIMEPERBOOKING';
export const GETTIMEPERBOOKING = 'GETTIMEPERBOOKING';
export const GETTIMEPERBOOKINGSUCCESS = 'GETTIMEPERBOOKINGSUCCESS';
export const SETWEEKLYSCHEDULE = 'SETWEEKLYSCHEDULE';
export const CALLWEEKLYSCHEDULE = 'CALLWEEKLYSCHEDULE';

export const SETSPECIALDAYS = 'SETSPECIALDAYS';
export const CALLSPECIALDAYS = 'CALLSPECIALDAYS';

export const SAVESPECIALDAYS = 'SAVESPECIALDAYS';
export const SETLOADINGFALSE = 'SETLOADINGFALSE';
export const GET_RESERVATION_TICKET = 'GET_RESERVATION_TICKET';
export const SET_RESERVATION_TICKET = 'SET_RESERVATION_TICKET';

export const GET_RESTAURANTS = 'GET_RESTAURANTS';
export const GET_RESTAURANTS_SUCCESS = 'GET_RESTAURANTS_SUCCESS';
export const GET_RESTAURANTS_FAILED = 'GET_RESTAURANTS_FAILED';

export const GET_RESTAURANTS_CLIENT = 'GET_RESTAURANTS_CLIENT';
export const GET_RESTAURANTS_CLIENT_SUCCESS =
  'GET_RESTAURANTS_CLIENT_SUCCESS';
export const GET_RESTAURANTS_CLIENT_FAILED =
  'GET_RESTAURANTS_CLIENT_FAILED';

export const ADD_RESTAURANT = 'ADD_RESTAURANT';
export const ADD_RESTAURANT_SUCCESS = 'ADD_RESTAURANT_SUCCESS';
export const ADD_RESTAURANT_FAILED = 'ADD_RESTAURANT_FAILED';

export const ADD_RESTAURANT_CLIENT = 'ADD_RESTAURANT_CLIENT';
export const ADD_RESTAURANT_CLIENT_SUCCESS =
  'ADD_RESTAURANT_CLIENT_SUCCESS';
export const ADD_RESTAURANT_CLIENT_FAILED =
  'ADD_RESTAURANT_CLIENT_FAILED';

export const EDIT_RESTAURANT_SUPERVISOR =
  'EDIT_RESTAURANT_SUPERVISOR';
export const EDIT_HOTEL_SUPERVISOR = 'EDIT_HOTEL_SUPERVISOR';
export const EDIT_RESTAURANT = 'EDIT_RESTAURANT';
export const EDIT_RESTAURANT_SUCCESS = 'EDIT_RESTAURANT_SUCCESS';
export const EDIT_RESTAURANT_FAILED = 'EDIT_RESTAURANT_FAILED';

export const SUP_ADMIN_SEARCH_NOTIFICATION =
  'SUP_ADMIN_SEARCH_NOTIFICATION';
export const SUP_ADMIN_SEARCH_NOTIFICATION_SUCCESS =
  'SUP_ADMIN_SEARCH_NOTIFICATION_SUCCESS';
export const SUP_ADMIN_SEARCH_NOTIFICATION_FAILED =
  'SUP_ADMIN_SEARCH_NOTIFICATION_FAILED';

export const SUP_ADMIN_GET_USERS = 'SUP_ADMIN_GET_USERS';
export const SUP_ADMIN_GET_USERS_SUCCESS =
  'SUP_ADMIN_GET_USERS_SUCCESS';
export const SUP_ADMIN_GET_USERS_FAILED =
  'SUP_ADMIN_GET_USERS_FAILED';

export const SUP_ADMIN_EDIT_USERS = 'SUP_ADMIN_EDIT_USERS';
export const SUP_ADMIN_EDIT_USERS_SUCCESS =
  'SUP_ADMIN_EDIT_USERS_SUCCESS';
export const SUP_ADMIN_EDIT_USERS_FAILED =
  'SUP_ADMIN_EDIT_USERS_FAILED';

export const CALLRESUTRANTDEVICES = 'CALLRESUTRANTDEVICES';
export const SETRESTURANTDEVICES = 'SETRESTURANTDEVICES';
export const SUP_ADMIN_ADD_SUPERVISOR = 'SUP_ADMIN_ADD_SUPERVISOR';
export const SUP_ADMIN_ADD_SUPERVISOR_SUCCESS =
  'SUP_ADMIN_ADD_SUPERVISOR_SUCCESS';
export const SUP_ADMIN_ADD_SUPERVISOR_FAILED =
  'SUP_ADMIN_ADD_SUPERVISOR_FAILED';

export const PRIMARY_MENU = 'PRIMARY_MENU';
export const PRIMARY_MENU_SUCCESS = 'PRIMARY_MENU_SUCCESS';
export const PRIMARY_MENU_FAILED = 'PRIMARY_MENU_FAILED';

export const GET_MENU_CLIENT = 'GET_MENU_CLIENT';
export const GET_MENU_CLIENT_SUCCESS = 'GET_MENU_CLIENT_SUCCESS';
export const GET_MENU_CLIENT_FAILED = 'GET_MENU_CLIENT_FAILED';

export const GET_DEVICES_NOT_RESUTRANT = 'GET_DEVICES_NOT_RESUTRANT';
export const GET_DEVICES_NOT_RESUTRANT_SUCCESS =
  'GET_DEVICES_NOT_RESUTRANT_SUCCESS';
export const GET_DEVICES_NOT_RESUTRANT_FAILED =
  'GET_DEVICES_NOT_RESUTRANT_FAILED';

export const ADD_DEVICES_TO_RESUTRANT = 'ADD_DEVICES_TO_RESUTRANT';
export const ADD_DEVICES_TO_RESUTRANT_SUCCESS =
  'ADD_DEVICES_TO_RESUTRANT_SUCCESS';
export const ADD_DEVICES_TO_RESUTRANT_FAILED =
  'ADD_DEVICES_TO_RESUTRANT_FAILED';

export const GET_RESTO = 'GET_RESTO';
export const GET_RESTO_SUCCESS = 'GET_RESTO_SUCCESS';
export const GET_RESTO_FAILED = 'GET_RESTO_FAILED';
export const SELECT_RESTO = 'SELECT_RESTO';

export const AFFECTATION_MENU_DEVICE = 'AFFECTATION_MENU_DEVICE';
export const AFFECTATION_MENU_DEVICE_SUCCESS =
  'AFFECTATION_MENU_DEVICE_SUCCESS';
export const AFFECTATION_MENU_DEVICE_FAILED =
  'AFFECTATION_MENU_DEVICE_FAILED';

export const SHOW_RESTAURANT = 'SHOW_RESTAURANT';
export const SHOW_RESTAURANT_SUCCESS = 'SHOW_RESTAURANT_SUCCESS';

export const GET_BOOKING_C = 'GET_BOOKING_C';
export const GET_BOOKING_C_SUCCESS = 'GET_BOOKING_C_SUCCESS';
export const GET_BOOKING_C_FAILED = 'GET_BOOKING_C_FAILED';

export const GET_SETTINGS_MENU = 'GET_SETTINGS_MENU';
export const GET_SETTINGS_MENU_SUCCESS = 'GET_SETTINGS_MENU_SUCCESS';
export const GET_SETTINGS_MENU_FAILED = 'GET_SETTINGS_MENU_FAILED';

export const SAVE_SETTINGS_MENU = 'SAVE_SETTINGS_MENU';
export const SAVE_SETTINGS_MENU_SUCCESS =
  'SAVE_SETTINGS_MENU_SUCCESS';
export const SAVE_SETTINGS_MENU_FAILED = 'SAVE_SETTINGS_MENU_FAILED';

export const UPDATE_WEBSITE_GENERAL = 'UPDATE_WEBSITE_GENERAL';
export const UPDATE_WEBSITE_GENERAL_SUCCESS =
  'UPDATE_WEBSITE_GENERAL_SUCCESS';
export const UPDATE_WEBSITE_GENERAL_FAILED =
  'UPDATE_WEBSITE_GENERAL_FAILED';

export const UPDATE_WEBSITE_NEWSLETTER = 'UPDATE_WEBSITE_NEWSLETTER';
export const UPDATE_WEBSITE_NEWSLETTER_SUCCESS =
  'UPDATE_WEBSITE_NEWSLETTER_SUCCESS';
export const UPDATE_WEBSITE_NEWSLETTER_FAILED =
  'UPDATE_WEBSITE_NEWSLETTER_FAILED';

export const UPDATE_WEBSITE_MENU = 'UPDATE_WEBSITE_MENU';
export const UPDATE_WEBSITE_MENU_SUCCESS =
  'UPDATE_WEBSITE_MENU_SUCCESS';
export const UPDATE_WEBSITE_MENU_FAILED =
  'UPDATE_WEBSITE_MENU_FAILED';

export const UPDATE_WEBSITE_HEADER = 'UPDATE_WEBSITE_HEADER';
export const UPDATE_WEBSITE_HEADER_SUCCESS =
  'UPDATE_WEBSITE_HEADER_SUCCESS';
export const UPDATE_WEBSITE_HEADER_FAILED =
  'UPDATE_WEBSITE_HEADER_FAILED';

export const UPDATE_WEBSITE_CHEF_WORD = 'UPDATE_WEBSITE_CHEF_WORD';
export const UPDATE_WEBSITE_CHEF_WORD_SUCCESS =
  'UPDATE_WEBSITE_CHEF_WORD_SUCCESS';
export const UPDATE_WEBSITE_CHEF_WORD_FAILED =
  'UPDATE_WEBSITE_CHEF_WORD_FAILED';

export const UPDATE_WEBSITE_HISTORY = 'UPDATE_WEBSITE_HISTORY';
export const UPDATE_WEBSITE_HISTORY_SUCCESS =
  'UPDATE_WEBSITE_HISTORY_SUCCESS';
export const UPDATE_WEBSITE_HISTORY_FAILED =
  'UPDATE_WEBSITE_HISTORY_FAILED';

export const UPDATE_WEBSITE_GALLERY = 'UPDATE_WEBSITE_GALLERY';
export const UPDATE_WEBSITE_GALLERY_SUCCESS =
  'UPDATE_WEBSITE_GALLERY_SUCCESS';
export const UPDATE_WEBSITE_GALLERY_FAILED =
  'UPDATE_WEBSITE_GALLERY_FAILED';

export const DELETE_GALLERY_ITEM = 'DELETE_GALLERY_ITEM';
export const DELETE_GALLERY_ITEM_SUCCESS =
  'DELETE_GALLERY_ITEM_SUCCESS';
export const DELETE_GALLERY_ITEM_FAILED =
  'DELETE_GALLERY_ITEM_FAILED';

export const ADD_COMMEND_MENU = 'ADD_COMMEND_MENU';
export const ADD_COMMEND_MENU_SUCCESS = 'ADD_COMMEND_MENU_SUCCESS';
export const ADD_COMMEND_MENU_FAILED = 'ADD_COMMEND_MENU_FAILED';

export const GET_TABLE_MENU = 'GET_TABLE_MENU';
export const GET_TABLE_MENU_SUCCESS = 'GET_TABLE_MENU_SUCCESS';
export const GET_TABLE_MENU_FAILED = 'GET_TABLE_MENU_FAILED';

export const ADD_QR_CODE_INFO = 'ADD_QR_CODE_INFO';
export const CLEAR_COMMEND_MENU = 'CLEAR_COMMEND_MENU';

export const COMMEND_UNIT = 'COMMEND_UNIT';

export const GET_COMMEND_ROOM = 'GET_COMMEND_ROOM';
export const GET_COMMEND_ROOM_SUCCESS = 'GET_COMMEND_ROOM_SUCCESS';
export const GET_COMMEND_ROOM_FAILED = 'GET_COMMEND_ROOM_FAILED';
export const INSERT_COMMEND_ROOM = 'INSERT_COMMEND_ROOM';

export const GET_ADMIN_ALL_CONTACT = 'GET_ADMIN_ALL_CONTACT';
export const GET_ADMIN_ALL_CONTACT_SUCCESS =
  'GET_ADMIN_ALL_CONTACT_SUCCESS';
export const GET_ADMIN_ALL_CONTACT_FAILED =
  'GET_ADMIN_ALL_CONTACT_FAILED';

export const GET_ADMIN_ALL_COMPANY = 'GET_ADMIN_ALL_COMPANY';
export const GET_ADMIN_ALL_COMPANY_SUCCESS =
  'GET_ADMIN_ALL_COMPANY_SUCCESS';
export const GET_ADMIN_ALL_COMPANY_FAILED =
  'GET_ADMIN_ALL_COMPANY_FAILED';

export const ADD_ADMIN_COMPANY = 'ADD_ADMIN_COMPANY';
export const ADD_ADMIN_CONTACT = 'ADD_ADMIN_CONTACT';

export const EDIT_ADMIN_CONTACT = 'EDIT_ADMIN_CONTACT';
export const EDIT_ADMIN_COMPANY = 'EDIT_ADMIN_COMPANY';

export const GET_CLICK_COLLECT_TIME = 'GET_CLICK_COLLECT_TIME';
export const GET_CLICK_COLLECT_TIME_SUCCESS =
  'GET_CLICK_COLLECT_TIME_SUCCESS';
export const GET_CLICK_COLLECT_TIME_FAILED =
  'GET_CLICK_COLLECT_TIME_FAILED';

export const ADD_COMMEND_CLICK_COLLECT = 'ADD_COMMEND_CLICK_COLLECT';
export const ADD_COMMEND_CLICK_COLLECT_SUCCESS =
  'ADD_COMMEND_CLICK_COLLECT_SUCCESS';
export const ADD_COMMEND_CLICK_COLLECT_FAILED =
  'ADD_COMMEND_CLICK_COLLECT_FAILED';

export const SET_RESTAURANT_SETTINGS = 'SET_RESTAURANT_SETTINGS';

export const SET_POINT_OF_SALE_UPDATE_AT =
  'SET_POINT_OF_SALE_UPDATE_AT';

export const GET_AFFILIATION_ID = 'GET_AFFILIATION_ID';
export const GET_AFFILIATION_ID_SUCCESS =
  'GET_AFFILIATION_ID_SUCCESS';
export const GET_AFFILIATION_ID_FAILED = 'GET_AFFILIATION_ID_FAILED';

export const GET_PRODUCT_CATEGORY = 'GET_PRODUCT_CATEGORY';
export const GET_PRODUCT_CATEGORY_SUCCESS =
  'GET_PRODUCT_CATEGORY_SUCCESS';
export const GET_PRODUCT_CATEGORY_FAILED =
  'GET_PRODUCT_CATEGORY_FAILED';

export const GET_CATEGORY_with_PRODUCT = 'GET_CATEGORY_with_PRODUCT';
export const GET_CATEGORY_with_PRODUCT_SUCCESS =
  'GET_CATEGORY_with_PRODUCT_SUCCESS';
export const GET_CATEGORY_with_PRODUCT_FAILED =
  'GET_CATEGORY_with_PRODUCT_FAILED';

export const GET_INGREDIENT = 'GET_INGREDIENT';
export const GET_INGREDIENT_SUCCESS = 'GET_INGREDIENT_SUCCESS';
export const GET_INGREDIENT_FAILED = 'GET_INGREDIENT_FAILED';

export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILED = 'GET_PRODUCT_FAILED';

export const CREATE_PRODUCT_CATEGORY = 'CREATE_PRODUCT_CATEGORY';
export const CREATE_PRODUCT_CATEGORY_SUCCESS =
  'CREATE_PRODUCT_CATEGORY_SUCCESS';
export const CREATE_PRODUCT_CATEGORY_FAILED =
  'CREATE_PRODUCT_CATEGORY_FAILED';

export const CREATE_INGREDIENT = 'CREATE_INGREDIENT';
export const CREATE_INGREDIENT_SUCCESS = 'CREATE_INGREDIENT_SUCCESS';
export const CREATE_INGREDIENT_FAILED = 'CREATE_INGREDIENT_FAILED';

export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILED = 'CREATE_PRODUCT_FAILED';

export const SETNEWLANGAGUE = 'SETNEWLANGAGUE';
// export const DELETE_GALLERY_ITEM_SUCCESS =
//   'DELETE_GALLERY_ITEM_SUCCESS';
// export const DELETE_GALLERY_ITEM_FAILED =
//   'DELETE_GALLERY_ITEM_FAILED';

export const GET_POINT_OF_SALE_CONTACT = 'GET_POINT_OF_SALE_CONTACT';
export const GET_POINT_OF_SALE_CONTACT_SUCCESS =
  'GET_POINT_OF_SALE_CONTACT_SUCCESS';
export const GET_POINT_OF_SALE_CONTACT_FAILED =
  'GET_POINT_OF_SALE_CONTACT_FAILED';

export const ADD_POINT_OF_SALE_CONTACT = 'ADD_POINT_OF_SALE_CONTACT';
export const ADD_POINT_OF_SALE_CONTACT_SUCCESS =
  'ADD_POINT_OF_SALE_CONTACT_SUCCESS';
export const ADD_POINT_OF_SALE_CONTACT_FAILED =
  'ADD_POINT_OF_SALE_CONTACT_FAILED';

export const EDIT_POINT_OF_SALE_CONTACT =
  'EDIT_POINT_OF_SALE_CONTACT';
export const EDIT_POINT_OF_SALE_CONTACT_SUCCESS =
  'EDIT_POINT_OF_SALE_CONTACT_SUCCESS';
export const EDIT_POINT_OF_SALE_CONTACT_FAILED =
  'EDIT_POINT_OF_SALE_CONTACT_FAILED';

export const GET_PRODUCT_RECEIPT = 'GET_PRODUCT_RECEIPT';
export const GET_PRODUCT_RECEIPT_SUCCESS =
  'GET_PRODUCT_RECEIPT_SUCCESS';
export const GET_PRODUCT_RECEIPT_API_SUCCESS =
  'GET_PRODUCT_RECEIPT_API_SUCCESS';
export const GET_PRODUCT_RECEIPT_FAILED =
  'GET_PRODUCT_RECEIPT_FAILED';

export const ADD_PRODUCT_RECEIPT = 'ADD_PRODUCT_RECEIPT';
export const ADD_PRODUCT_RECEIPT_SUCCESS =
  'ADD_PRODUCT_RECEIPT_SUCCESS';
export const ADD_PRODUCT_RECEIPT_FAILED =
  'ADD_PRODUCT_RECEIPT_FAILED';

export const GET_INVOICE = 'GET_INVOICE';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const GET_INVOICE_TABLE_SUCCESS = 'GET_INVOICE_TABLE_SUCCESS';
export const GET_INVOICE_FAILED = 'GET_INVOICE_FAILED';

export const CREATE_INVOICE = 'CREATE_INVOICE';
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS';
export const CREATE_INVOICE_FAILED = 'CREATE_INVOICE_FAILED';

export const GET_PRODUCT_SUPPLIER_COMMAND =
  'GET_PRODUCT_SUPPLIER_COMMAND';
export const GET_PRODUCT_SUPPLIER_COMMAND_SUCCESS =
  'GET_PRODUCT_SUPPLIER_COMMAND_SUCCESS';
export const GET_PRODUCT_SUPPLIER_COMMAND_FAILED =
  'GET_PRODUCT_SUPPLIER_COMMAND_FAILED';

export const ADD_PRODUCT_SUPPLIER_COMMAND =
  'ADD_PRODUCT_SUPPLIER_COMMAND';
export const ADD_PRODUCT_SUPPLIER_COMMAND_SUCCESS =
  'ADD_PRODUCT_SUPPLIER_COMMAND_SUCCESS';
export const ADD_PRODUCT_SUPPLIER_COMMAND_FAILED =
  'ADD_PRODUCT_SUPPLIER_COMMAND_FAILED';

export const GET_PRODUCT_CUSTOMER_COMMAND =
  'GET_PRODUCT_CUSTOMER_COMMAND';
export const GET_PRODUCT_CUSTOMER_COMMAND_SUCCESS =
  'GET_PRODUCT_CUSTOMER_COMMAND_SUCCESS';
export const GET_PRODUCT_CUSTOMER_COMMAND_FAILED =
  'GET_PRODUCT_CUSTOMER_COMMAND_FAILED';

export const ADD_PRODUCT_CUSTOMER_COMMAND =
  'ADD_PRODUCT_CUSTOMER_COMMAND';
export const ADD_PRODUCT_CUSTOMER_COMMAND_SUCCESS =
  'ADD_PRODUCT_CUSTOMER_COMMAND_SUCCESS';
export const ADD_PRODUCT_CUSTOMER_COMMAND_FAILED =
  'ADD_PRODUCT_CUSTOMER_COMMAND_FAILED';

export const ADD_PRODUCT_DELIVERY = 'ADD_PRODUCT_DELIVERY';
export const ADD_PRODUCT_DELIVERY_SUCCESS =
  'ADD_PRODUCT_DELIVERY_SUCCESS';
export const ADD_PRODUCT_DELIVERY_FAILED =
  'ADD_PRODUCT_DELIVERY_FAILED';

export const GET_PRODUCT_DELIVERY = 'GET_PRODUCT_DELIVERY';
export const GET_PRODUCT_DELIVERY_SUCCESS =
  'GET_PRODUCT_DELIVERY_SUCCESS';
export const GET_PRODUCT_DELIVERY_FAILED =
  'GET_PRODUCT_DELIVERY_FAILED';

export const EDIT_PRODUCT_CATEGORY = 'EDIT_PRODUCT_CATEGORY';
export const EDIT_PRODUCT_CATEGORY_SUCCESS =
  'EDIT_PRODUCT_CATEGORY_SUCCESS';
export const EDIT_PRODUCT_CATEGORY_FAILED =
  'EDIT_PRODUCT_CATEGORY_FAILED';

export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS';
export const EDIT_PRODUCT_FAILED = 'EDIT_PRODUCT_FAILED';

export const EDIT_INGREDIENT = 'EDIT_INGREDIENT';
export const EDIT_INGREDIENT_SUCCESS = 'EDIT_INGREDIENT_SUCCESS';
export const EDIT_INGREDIENT_FAILED = 'EDIT_INGREDIENT_FAILED';

export const EDIT_PRODUCT_SUPPLIER_COMMAND =
  'EDIT_PRODUCT_SUPPLIER_COMMAND';
export const EDIT_PRODUCT_SUPPLIER_COMMAND_SUCCESS =
  'EDIT_PRODUCT_SUPPLIER_COMMAND_SUCCESS';
export const EDIT_PRODUCT_SUPPLIER_COMMAND_FAILED =
  'EDIT_PRODUCT_SUPPLIER_COMMAND_FAILED';

export const EDIT_PRODUCT_CUSTOMER_COMMAND =
  'EDIT_PRODUCT_CUSTOMER_COMMAND';
export const EDIT_PRODUCT_CUSTOMER_COMMAND_SUCCESS =
  'EDIT_PRODUCT_CUSTOMER_COMMAND_SUCCESS';
export const EDIT_PRODUCT_CUSTOMER_COMMAND_FAILED =
  'EDIT_PRODUCT_CUSTOMER_COMMAND_FAILED';

export const EDIT_PRODUCT_SHOW_IN_POS_STATUS =
  'EDIT_PRODUCT_SHOW_IN_POS_STATUS';
export const EDIT_PRODUCT_SHOW_IN_POS_STATUS_SUCCESS =
  'EDIT_PRODUCT_SHOW_IN_POS_STATUS_SUCCESS';
export const EDIT_PRODUCT_SHOW_IN_POS_STATUS_FAILED =
  'EDIT_PRODUCT_SHOW_IN_POS_STATUS_FAILED';
