import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import bookingSaga from './Booking/saga';
import ClickCollectSaga from './ClickCollect/saga';
import DeviceSaga from './Device/saga';
import menuSaga from './menu/saga';
import CommendMenuSaga from './menuCommend/saga';
import RestaurantSaga from './PointOfSale/saga';
import ReservationSaga from './Reservation/saga';
import SpecialDaysSaga from './SpecialDays/saga';
import SupAdminCompanySaga from './SupAdmin/company/saga';
import SupAdminContactsSaga from './SupAdmin/contact/saga';
import SupAdminUsersSaga from './SupAdmin/users/saga';
import supervisorSaga from './supervisor/saga';
import TimeSettings from './TimeSettings/saga';
import tablesSaga from './units/saga';
import usersSaga from './users/saga';
import websiteSaga from './website/saga';
import WeeklyScheduleSaga from './WeeklySchedule/saga';
import affiliateSettingSaga from './AffiliationSettings/saga';
import CaisseSaga from './caisse/saga';
import PointOfSaleCompanySaga from './pointOfSaleCompany/saga';
import ManagementSaga from './management/saga';

export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    tablesSaga(),
    usersSaga(),
    menuSaga(),
    TimeSettings(),
    WeeklyScheduleSaga(),
    bookingSaga(),
    SpecialDaysSaga(),
    ReservationSaga(),
    RestaurantSaga(),
    SupAdminUsersSaga(),
    DeviceSaga(),
    supervisorSaga(),
    websiteSaga(),
    CommendMenuSaga(),
    SupAdminCompanySaga(),
    SupAdminContactsSaga(),
    ClickCollectSaga(),
    affiliateSettingSaga(),
    CaisseSaga(),
    PointOfSaleCompanySaga(),
    ManagementSaga(),
  ]);
}
