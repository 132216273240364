// @flow
import {
  GET_RESERVATION_TICKET,
  SET_RESERVATION_TICKET,
} from '../../constants/actionTypes';
import { REHYDRATE } from 'redux-persist';
const INIT_STATE = {
  reservation: {},
  yellowReservations: [],
  loading: false,
};

const ReservationTicket = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RESERVATION_TICKET:
      return { ...state, loading: true };
    case SET_RESERVATION_TICKET:
      return {
        ...state,
        reservation: action.payload.reservation,
        yellowReservations: action.payload.yellowReservations,
        loading: false,
      };
    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.ReservationTicket,
          }
        : {
            ...state,
          };
    default:
      return { ...state };
  }
};

export default ReservationTicket;
