// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  GET_WEBSITE,
  UPDATE_WEBSITE_GENERAL,
  UPDATE_WEBSITE_NEWSLETTER,
  UPDATE_WEBSITE_MENU,
  UPDATE_WEBSITE_HEADER,
  UPDATE_WEBSITE_CHEF_WORD,
  UPDATE_WEBSITE_HISTORY,
  UPDATE_WEBSITE_GALLERY,
  DELETE_GALLERY_ITEM,
} from '../../constants/actionTypes';

import {
  getWebsiteSuccess,
  getWebsiteFailed,
  updateWebsiteGeneralSuccess,
  updateWebsiteGeneralFailed,
  updateWebsiteNewsletterSuccess,
  updateWebsiteNewsletterFailed,
  updateWebsiteMenuSuccess,
  updateWebsiteMenuFailed,
  updateWebsiteHeaderSuccess,
  updateWebsiteHeaderFailed,
  updateWebsiteChefWordSuccess,
  updateWebsiteChefWordFailed,
  updateWebsiteHistorySuccess,
  updateWebsiteHistoryFailed,
  updateWebsiteGallerySuccess,
  updateWebsiteGalleryFailed,
  deleteGalleryItemSuccess,
  deleteGalleryItemFailed,
} from './actions';
import {
  getWebsiteService,
  updateWebsiteGeneralService,
  updateWebsiteNewsletterService,
  updateWebsiteMenuService,
  updateWebsiteHeaderService,
  updateWebsiteChefWordService,
  updateWebsiteHistoryService,
  updateWebsiteGalleryService,
  deleteGalleryItemService,
} from './service';

/**
 *
 */
function* getWebsite({ payload: { pointOfSaleId } }) {
  try {
    const response = yield call(getWebsiteService, pointOfSaleId);
    yield put(getWebsiteSuccess(response.pointOfSale));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(getWebsiteFailed(error));
  }
}

function* updateWebsiteGeneral({
  payload: {
    pointOfSaleId,
    primaryColor,
    primaryFont,
    secondaryColor,
    secondaryFont,
    facebookLink,
    instagramLink,
  },
}) {
  try {
    const response = yield call(updateWebsiteGeneralService, {
      pointOfSaleId,
      primaryColor,
      primaryFont,
      secondaryColor,
      secondaryFont,
      facebookLink,
      instagramLink,
    });
    yield put(
      updateWebsiteGeneralSuccess(response.pointOfSale.website),
    );
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(updateWebsiteGeneralFailed(error));
  }
}

function* updateWebsiteNewsletter({
  payload: { pointOfSaleId, newsletterTitle },
}) {
  try {
    const response = yield call(updateWebsiteNewsletterService, {
      pointOfSaleId,
      newsletterTitle,
    });
    yield put(
      updateWebsiteNewsletterSuccess(response.pointOfSale.website),
    );
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(updateWebsiteNewsletterFailed(error));
  }
}

function* updateWebsiteMenu({
  payload: { pointOfSaleId, menuSubTitle, menuTitle },
}) {
  try {
    const response = yield call(updateWebsiteMenuService, {
      pointOfSaleId,
      menuSubTitle,
      menuTitle,
    });
    yield put(updateWebsiteMenuSuccess(response.pointOfSale.website));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(updateWebsiteMenuFailed(error));
  }
}
function* updateWebsiteHeader({
  payload: { pointOfSaleId, backgroundImageHeader },
}) {
  try {
    const response = yield call(updateWebsiteHeaderService, {
      pointOfSaleId,
      backgroundImageHeader,
    });
    yield put(
      updateWebsiteHeaderSuccess(response.pointOfSale.website),
    );
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(updateWebsiteHeaderFailed(error));
  }
}
function* updateWebsiteChefWord({
  payload: { pointOfSaleId, chefPhoto, chefWord, chefName },
}) {
  try {
    const response = yield call(updateWebsiteChefWordService, {
      pointOfSaleId,
      chefPhoto,
      chefWord,
      chefName,
    });
    yield put(
      updateWebsiteChefWordSuccess(response.pointOfSale.website),
    );
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(updateWebsiteChefWordFailed(error));
  }
}
function* updateWebsiteHistory({
  payload: { pointOfSaleId, historyPhoto, historyWord, historyTitle },
}) {
  try {
    const response = yield call(updateWebsiteHistoryService, {
      pointOfSaleId,
      historyPhoto,
      historyWord,
      historyTitle,
    });
    yield put(
      updateWebsiteHistorySuccess(response.pointOfSale.website),
    );
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(updateWebsiteHistoryFailed(error));
  }
}
function* updateWebsiteGallery({
  payload: {
    pointOfSaleId,
    galleryTitle,
    gallerySubTitle,
    gallery,
    callback,
  },
}) {
  try {
    const response = yield call(updateWebsiteGalleryService, {
      pointOfSaleId,
      galleryTitle,
      gallerySubTitle,
      gallery,
    });
    yield put(
      updateWebsiteGallerySuccess(response.pointOfSale.website),
    );
    callback();
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(updateWebsiteGalleryFailed(error));
  }
}

function* deleteGalleryItem({ payload: { pointOfSaleId, photoId } }) {
  try {
    const response = yield call(deleteGalleryItemService, {
      pointOfSaleId,
      photoId,
    });
    yield put(deleteGalleryItemSuccess(response.pointOfSale.website));
    callback();
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(deleteGalleryItemFailed(error));
  }
}

export function* watchUpdateWebsiteGallery() {
  yield takeEvery(UPDATE_WEBSITE_GALLERY, updateWebsiteGallery);
}

export function* watchDeleteGalleryItem() {
  yield takeEvery(DELETE_GALLERY_ITEM, deleteGalleryItem);
}
export function* watchUpdateWebsiteHistory() {
  yield takeEvery(UPDATE_WEBSITE_HISTORY, updateWebsiteHistory);
}
export function* watchUpdateWebsiteChefWord() {
  yield takeEvery(UPDATE_WEBSITE_CHEF_WORD, updateWebsiteChefWord);
}

export function* watchUpdateWebsiteHeader() {
  yield takeEvery(UPDATE_WEBSITE_HEADER, updateWebsiteHeader);
}

export function* watchUpdateWebsiteMenu() {
  yield takeEvery(UPDATE_WEBSITE_MENU, updateWebsiteMenu);
}

export function* watchGetWebsite() {
  yield takeEvery(GET_WEBSITE, getWebsite);
}
export function* watchUpdateWebsiteGeneral() {
  yield takeEvery(UPDATE_WEBSITE_GENERAL, updateWebsiteGeneral);
}
export function* watchUpdateWebsiteNewsletter() {
  yield takeEvery(UPDATE_WEBSITE_NEWSLETTER, updateWebsiteNewsletter);
}

function* websiteSaga() {
  yield all([fork(watchGetWebsite)]);
  yield all([fork(watchUpdateWebsiteGeneral)]);
  yield all([fork(watchUpdateWebsiteNewsletter)]);
  yield all([fork(watchUpdateWebsiteMenu)]);
  yield all([fork(watchUpdateWebsiteHeader)]);
  yield all([fork(watchUpdateWebsiteChefWord)]);
  yield all([fork(watchUpdateWebsiteHistory)]);
  yield all([fork(watchUpdateWebsiteGallery)]);
  yield all([fork(watchDeleteGalleryItem)]);
}

export default websiteSaga;
