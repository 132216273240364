import React, {Component, useState} from "react";
import PropTypes from "prop-types";
import { EditorState, RichUtils } from "draft-js";

import {
    isOneBlockSelected,
    isLinkInSelection,
    getClearSelectionEditor
} from "./draft";
import {
    Button, Col,
    FormFeedback, FormGroup,
    Input,
    InputGroup,
    InputGroupAddon, Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import {ProgressBar} from "react-bootstrap";
import links from "../../../helpers/links.json";

const  LinkOption  = (props)=> {

    const [modal, setModal] = useState(false);
    const [url, setUrl] = useState("");
    const [valid, setValid] = useState(true);

   const  addLink = (event) => {

       setModal(true);


    }

    const _isEnabled = () =>{
        const { editorState } = props;
        const isCollapsed = editorState.getSelection().isCollapsed();
        const isOneBlock = isCollapsed ? false : isOneBlockSelected(editorState);
        const isLink = isOneBlock ? isLinkInSelection(editorState) : false;
        return !isCollapsed && isOneBlock && !isLink;
    }

    const onToggle= ()=>{
       setModal(false);
    }

    const validateUrl = (value) => {
        const urlRegex = /^((https:\/\/|www\.|[^\s:=]+@www\.).*?[a-z_\/0-9\-\#=&])(?=(\.|,|;|\?|\!)?("|'|«|»|\[|\s|\r|\n|$))/gi;

        const test = urlRegex.test(value);

        setValid(test);

        return test;
    }


    const save= ()=>{
       if (validateUrl(url)) {

           setModal(false);
            if (_isEnabled()) {
                const { editorState, onChange } = props;
                const contentStateWithEntity = editorState
                    .getCurrentContent()
                    .createEntity("LINK", "MUTABLE", {
                        url,
                        target:'_blank'
                    });
                const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                const tmpEditorState = EditorState.set(editorState, {
                    currentContent: contentStateWithEntity
                });
                const newEditorState = RichUtils.toggleLink(
                    tmpEditorState,
                    tmpEditorState.getSelection(),
                    entityKey
                );
                onChange(getClearSelectionEditor(newEditorState));
                setUrl("")
            }

       }
    }

        const classes = _isEnabled()
            ? "rdw-option-wrapper"
            : "rdw-option-wrapper rdw-option-disabled";

        return (
            <>
                {modal && <Modal isOpen={modal} toggle={onToggle}>
                    <ModalHeader>Modal title</ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label
                                for="examplePassword"
                                sm={1}
                            >
                                Link
                            </Label>
                            <Col sm={11}>
                                <Input
                                    type={"text"}
                                    placeholder="https://example.com"
                                    value={url}
                                    onChange={({target:{value}})=> {
                                        setValid(true);
                                        setUrl(value)
                                    }}
                                />
                                {!valid &&<FormFeedback  style={{display: 'block'}} >
                                    insert a valid url
                                </FormFeedback>}
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={save}>Save</Button>{' '}
                        <Button color="secondary" onClick={onToggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>}
                <div onClick={addLink}>
                    <div className={classes} title="Link">
                        <img
                            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjk2Ny45NUEzLjIyNiAzLjIyNiAwIDAgMCAxMS42Ny4wMDJjLS44NyAwLTEuNjg2LjMzNy0yLjI5Ny45NDhMNy4xMDUgMy4yMThBMy4yNDcgMy4yNDcgMCAwIDAgNi4yNCA2LjI0YTMuMjI1IDMuMjI1IDAgMCAwLTMuMDIyLjg2NUwuOTUgOS4zNzNhMy4yNTMgMy4yNTMgMCAwIDAgMCA0LjU5NCAzLjIyNiAzLjIyNiAwIDAgMCAyLjI5Ny45NDhjLjg3IDAgMS42ODYtLjMzNiAyLjI5OC0uOTQ4TDcuODEyIDExLjdhMy4yNDcgMy4yNDcgMCAwIDAgLjg2NS0zLjAyMyAzLjIyNSAzLjIyNSAwIDAgMCAzLjAyMi0uODY1bDIuMjY4LTIuMjY3YTMuMjUyIDMuMjUyIDAgMCAwIDAtNC41OTV6TTcuMTA1IDEwLjk5M0w0LjgzNyAxMy4yNmEyLjIzMyAyLjIzMyAwIDAgMS0xLjU5LjY1NSAyLjIzMyAyLjIzMyAwIDAgMS0xLjU5LS42NTUgMi4yNTIgMi4yNTIgMCAwIDEgMC0zLjE4bDIuMjY4LTIuMjY4YTIuMjMyIDIuMjMyIDAgMCAxIDEuNTktLjY1NWMuNDMgMCAuODQxLjEyIDEuMTk1LjM0M0w0Ljc3MiA5LjQzOGEuNS41IDAgMSAwIC43MDcuNzA3bDEuOTM5LTEuOTM4Yy41NDUuODY4LjQ0MiAyLjAzLS4zMTMgMi43ODV6bTYuMTU1LTYuMTU1bC0yLjI2OCAyLjI2N2EyLjIzMyAyLjIzMyAwIDAgMS0xLjU5LjY1NWMtLjQzMSAwLS44NDEtLjEyLTEuMTk1LS4zNDNsMS45MzgtMS45MzhhLjUuNSAwIDEgMC0uNzA3LS43MDdMNy40OTkgNi43MWEyLjI1MiAyLjI1MiAwIDAgMSAuMzEzLTIuNzg1bDIuMjY3LTIuMjY4YTIuMjMzIDIuMjMzIDAgMCAxIDEuNTktLjY1NSAyLjIzMyAyLjIzMyAwIDAgMSAyLjI0NiAyLjI0NWMwIC42MDMtLjIzMiAxLjE2OC0uNjU1IDEuNTl6IiBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4="
                            alt=""
                        />
                    </div>
                </div>
            </>
        );

}

LinkOption.propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object
};

export default LinkOption;
