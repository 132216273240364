import React, { Suspense, useState } from 'react';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
// import profilePic from "../assets/images/users/user-1.jpg";
import TopBarSuper from '../TopBarSuper';

const loading = () => <div className="text-center"></div>;
// const RightSidebarContent = (props) => {
//   return (
//     <div className="user-box">
//       <div className="user-img">
//         <img
//           src={profilePic}
//           alt="user-img"
//           title="Nik Patel"
//           className="rounded-circle img-fluid"
//         />
//         <a href="/" className="user-edit">
//           <i className="mdi mdi-pencil"></i>
//         </a>
//       </div>

//       <h5>{props.user && <a href="/">{props.user.username}</a>}</h5>
//       <p className="text-muted mb-0">
//         <small>Founder</small>
//       </p>
//     </div>
//   );
// };

const getAccessRoot = (user) => {
  let listRoot = null;

  switch (user.role) {
    case 'ROLE_ASSISTANCE':
      listRoot = [
        {
          root: '/admin/restaurants',
          access: true,
        },
        {
          root: '/admin/contacts',
          access: true,
        },

        {
          root: '/admin/devices',
          access: false,
        },

        {
          root: '/admin/users',
          access: false,
        },
        {
          root: '/admin/company',
          access: false,
        },
        {
          root: '/admin/dashboard',
          access: false,
        },
        {
          root: '/admin/task',
          access: false,
        },
        {
          root: '/',
          access: false,
        },
        {
          root: '/admin/upxp-management',
          access: false,
        },
      ];
      break;

    case 'ROLE_SUPER_ADMIN':
      listRoot = [
        {
          root: '/admin/restaurants',
          access: true,
        },
        {
          root: '/admin/contacts',
          access: true,
        },

        {
          root: '/admin/devices',
          access: true,
        },

        {
          root: '/admin/users',
          access: true,
        },
        {
          root: '/admin/company',
          access: true,
        },
        {
          root: '/admin/dashboard',
          access: true,
        },
        {
          root: '/admin/task',
          access: true,
        },
        {
          root: '/',
          access: true,
        }
      ];

      break;

    default:
      break;
  }

  return listRoot;
};

const SuperAdminLayout = (props) => {
  const [isCondensed, setIsCondensed] = useState(false);
  const user = useSelector((state) => state.Auth.user);

  /**
   * toggle Menu
   */
  const toggleMenu = (e) => {
    e.preventDefault();
    setIsCondensed(!isCondensed);
  };

  /**
   * Toggle right side bar
   */
  const toggleRightSidebar = () => {
    document.body.classList.toggle('right-bar-enabled');
  };

  const listRoot = getAccessRoot(user);

  // get the child view which we would like to render
  const children = props.children || null;

  return (
    <div className="app">
      <header id="topnav">
        <Suspense fallback={loading()}>
          <TopBarSuper
            i18n={props.i18n}
            rightSidebarToggle={toggleRightSidebar}
            menuToggle={toggleMenu}
            isMenuOpened={isCondensed}
            {...props}
            listRoot={listRoot}
            showMenu={true}
          />
          {/* <Navbar {...props} showContent={true} /> */}
        </Suspense>
      </header>
      <div
        className="wrapper wrapper_2"
        style={{ backgroundColor: '#fff' }}
      >
        <Suspense fallback={loading()}>{children}</Suspense>
      </div>
    </div>
  );
};

export default withTranslation()(SuperAdminLayout);
