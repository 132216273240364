import { httpClient_get, httpClient_post } from '../../helpers/api';

const getSupervisorRestaurantsService = () => {
  return httpClient_get('/pointofsale/restaurant-supervisor')
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export { getSupervisorRestaurantsService };
