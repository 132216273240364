// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  SUP_ADMIN_ADD_SUPERVISOR,
  SUP_ADMIN_EDIT_USERS,
  SUP_ADMIN_GET_USERS,
  SUP_ADMIN_SEARCH_NOTIFICATION,
} from '../../../constants/actionTypes';

import {
  UpdateUserSuccess,
  UpdateUserFailed,
  getUsersSuccess,
  getUsersFailed,
  addSupervisorSuccess,
  addSupervisorFailed,
  searchNotificationSuccess,
  searchNotificationFailed,
} from './actions';
import {
  updateUserService,
  getUsersSuperAdminService,
  addSupervisorService,
  searchNotificationService,
} from './service';

/**
 *
 */
// function* addUser({ payload: { user } }) {

//     try {
//         const response = yield call(addUserService,user);
//         yield put(addUserSuccess(response));
//     } catch (error) {
//         let message;
//         switch (error.status) {
//             case 500: message = 'Internal Server Error'; break;
//             case 401: message = 'Invalid credentials'; break;
//             default: message = error;
//         }
//         yield put(addUserFailed(error));
//     }
// }
/**
 *
 */
function* updateUser({ payload: { user, callback } }) {
  try {
    const response = yield call(updateUserService, user);
    yield put(UpdateUserSuccess(response));
    callback();
  } catch (error) {
    yield put(UpdateUserFailed(error.response.data));
  }
}

function* addSupervisorSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(addSupervisorService, data);

    yield put(addSupervisorSuccess(response.userSupervisor));
    callback && callback();
  } catch (error) {
    yield put(addSupervisorFailed(error.response.data));
  }
}

function* searchNotificationSaga({ payload: { data } }) {
  try {
    console.log(`data=========>`, data);
    const response = yield call(searchNotificationService, data);

    yield put(searchNotificationSuccess(response.notificationTokens));
  } catch (error) {
    yield put(searchNotificationFailed(error.response.data));
  }
}

// function* deleteUser({ payload: { user } }) {

//     try {
//         const response = yield call(deleteUserService,user);
//         yield put(deleteUserSuccess(response));
//     } catch (error) {
//         yield put(deleteUserFailed(error));
//     }
// }

function* getUsers({ payload: {} }) {
  try {
    const response = yield call(getUsersSuperAdminService);
    yield put(getUsersSuccess(response));
  } catch (error) {
    yield put(getUsersFailed(error));
  }
}

// export function* watchAddUser() {
//     yield takeEvery(ADD_USER, addUser);
// }

export function* watchUpdateUser() {
  yield takeEvery(SUP_ADMIN_EDIT_USERS, updateUser);
}

// export function* watchDeleteUser() {
//     yield takeEvery(DELETE_USER, deleteUser);
// }

export function* watchGetUsers() {
  yield takeEvery(SUP_ADMIN_GET_USERS, getUsers);
}

export function* watchSearchNotification() {
  yield takeEvery(
    SUP_ADMIN_SEARCH_NOTIFICATION,
    searchNotificationSaga,
  );
}

export function* watchAddSupervisor() {
  yield takeEvery(SUP_ADMIN_ADD_SUPERVISOR, addSupervisorSaga);
}

function* SupAdminUsersSaga() {
  yield all([
    // fork(watchAddUser),
    fork(watchUpdateUser),
    fork(watchSearchNotification),
    // fork(watchDeleteUser),
    fork(watchGetUsers),
    fork(watchAddSupervisor),
  ]);
}

export default SupAdminUsersSaga;
