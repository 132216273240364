import {
  httpClient_get,
  httpClient_post,
  httpClient_put,
  httpClient_delete,
} from '../../../helpers/api';

const getUsersSuperAdminService = () => {
  return httpClient_get('/users/')
    .then((response) => {
      return response.data.users;
    })
    .catch((error) => {
      throw error;
    });
};

const addUserService = (user) => {
  const { email, firstName, lastName, phone, password } = user;
  return httpClient_post('/users/staff', {
    email,
    firstName,
    lastName,
    phone,
    password,
  })
    .then((response) => {
      console.log(response.data);
      return response.data.userStaff;
    })
    .catch((error) => {
      throw error;
    });
};

const addSupervisorService = (data) => {
  const {
    email,
    firstName,
    lastName,
    phone,
    password,
    pointOfSales,
    typeAccount,
    enableRoomService = false,
  } = data;
  return httpClient_post('/users/supervisor', {
    email,
    firstName,
    lastName,
    phone,
    password,
    pointOfSales,
    typeAccount,
    enableRoomService,
  })
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const searchNotificationService = (data) => {
  const { distance, latitude, longitude } = data;
  return httpClient_post(
    '/superadmin/get-booked-devices-by-location',
    {
      distance,
      latitude,
      longitude,
    },
  )
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const updateUserService = (user) => {
  const { _id } = user;

  return httpClient_put(`/users/${_id}`, user)
    .then((response) => {
      return response.data.userAdmin;
    })
    .catch((error) => {
      throw error;
    });
};

const deleteUserService = (user) => {
  return httpClient_delete(`/users/${user._id}/staff`)
    .then((response) => {
      return response.data.userStaff;
    })
    .catch((error) => {
      throw error;
    });
};

export {
  addUserService,
  updateUserService,
  deleteUserService,
  getUsersSuperAdminService,
  addSupervisorService,
  searchNotificationService,
};
