// @flow
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  AUTH_EDIT_RESTAURANT,
  USER_UPDATE_SUCCESS,
  SETNEWLANGAGUE,
} from '../../constants/actionTypes';

export const loginUser = (username, password, callback) => ({
  type: LOGIN_USER,
  payload: { username, password, callback },
});

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const UserUpdateSuccess = ({
  firstName,
  lastName,
  phone,
  avatar,
}) => ({
  type: USER_UPDATE_SUCCESS,
  payload: {
    firstName,
    lastName,
    phone,
    avatar,
  },
});

export const loginUserFailed = (error) => ({
  type: LOGIN_USER_FAILED,
  payload: error,
});

export const registerUser = (fullname, email, password) => ({
  type: REGISTER_USER,
  payload: { fullname, email, password },
});

export const authEditRestaurant = (restaurant) => ({
  type: AUTH_EDIT_RESTAURANT,
  payload: restaurant,
});

export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});

export const registerUserFailed = (error) => ({
  type: REGISTER_USER_FAILED,
  payload: error,
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});

export const forgetPassword = (username) => ({
  type: FORGET_PASSWORD,
  payload: { username },
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error) => ({
  type: FORGET_PASSWORD_FAILED,
  payload: error,
});

export const resetPassword = (
  password,
  r_password,
  id_user,
  token,
) => ({
  type: RESET_PASSWORD,
  payload: { password, r_password, id_user, token },
});

export const resetPasswordSuccess = (user) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: user,
});

export const resetPasswordFailed = (error) => ({
  type: REGISTER_USER_FAILED,
  payload: error,
});
export const setLanguage = (lang) => ({
  type: SETNEWLANGAGUE,
  payload: lang,
});
