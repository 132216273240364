import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import QrCodeSvg from "../../components/QrCodeSvg";
import { Pagination, TableHeader } from "../../components/DataTable";
import { useForm } from "react-hook-form";
import { httpClient_get, httpClient_post } from "../../helpers/api";
import _ from "lodash";

const addUPartnerService = (partner, add = true) => {
    const url = add ? 'add' : "edit";
    return httpClient_post(`/partners/${url}`, partner)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error.response;
        });
};

const getPartnersService = (user, pointOfSaleId) => {
    return httpClient_get('/partners')
        .then((response) => {
            return response.data.partners;
        })
        .catch((error) => {
            throw error.response;
        });
};


const Partner = (props) => {

    const [editPartner_, setEditPartner_] = useState({});
    const { register, handleSubmit } = useForm();
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [result, setResult] = useState()

    const _onSubmit = async (data) => {
        try {

            const is_new = _.isEmpty(editPartner_);

            const res = await addUPartnerService({ ...editPartner_, ...data }, is_new);
            if (is_new) {
                setResult((prv) => [...prv, res.partner]);
            }
            else {
                setResult((prv) => prv.map((item) => {

                    if (item._id === editPartner_._id) return { ...item, ...data }

                    return item
                }));
            }
            setEditPartner_({});
            toggle();
            console.log("----res", res)
        } catch (e) {
            console.log("-----e----", e)
        }
    }





    useEffect(() => {
        let active = true
        load()
        return () => { active = false }
        async function load() {
            setResult(undefined) // this is optional
            const res = await getPartnersService()
            if (!active) { return }
            setResult(res)
        }

    }, []);

    const editPartner = (partner) => (e) => {
        setEditPartner_(partner);
        toggle();
    }



    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} >
                <ModalHeader toggle={toggle}>Partner</ModalHeader>
                <ModalBody style={{
                    display: "flex",
                    justifyContent: "center"
                }}  >

                    <form onSubmit={handleSubmit(_onSubmit)}>
                        <div className="form-row">
                            <FormGroup className="col-md-6">
                                <Label for="exampleEmail">Name :</Label>
                                <Input
                                    type="text"
                                    placeholder="name"
                                    name="name"
                                    defaultValue={editPartner_?.name}
                                    innerRef={register({ required: false })}
                                />
                            </FormGroup>
                            <FormGroup className="col-md-6">
                                <Label for="examplePassword">Email:</Label>
                                <Input
                                    type="text"
                                    placeholder="email"
                                    name="email"
                                    defaultValue={editPartner_?.email}
                                    innerRef={register({ required: false })}
                                />
                            </FormGroup>
                            <FormGroup className="col-md-6">
                                <Label for="examplePassword">Site Web :</Label>
                                <Input
                                    type="text"
                                    placeholder="site web"
                                    name="website"
                                    defaultValue={editPartner_?.website}
                                    innerRef={register({ required: false })}
                                />
                            </FormGroup>
                            <FormGroup className="col-md-6">
                                <Label for="passwordlabel">Password :</Label>
                                <Input
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    defaultValue={editPartner_?.password}
                                    innerRef={register({ required: false })}
                                />
                            </FormGroup>
                        </div>
                        <div className="form-row">
                            <Button type="submit" variant="primary">
                                Sauvegarder
                            </Button>
                        </div>
                    </form>

                </ModalBody>
            </Modal>

            <Row style={{ justifyContent: "flex-end", padding: '10px 35px' }}>
                <Button variant="primary" type="submit" onClick={() => setModal(true)} >
                    Add Partner
                </Button>
            </Row>

            <DataTable editPartner={editPartner} data={result} qrCodes={[]} />

        </div>
    );
};


const DataTable = ({ data, editPartner }) => {
    const [comments, setComments] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [sorting, setSorting] = useState({ field: '', order: '' });
    const [showEdit, setShowEdit] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [contact, setContact] = useState({});
    const [showInfo, setShowInfo] = useState(false);
    const dispatch = useDispatch();

    const ITEMS_PER_PAGE = 50;

    const headers = [
        { name: 'Name', field: 'name', sortable: false },
        { name: 'Email', field: 'email', sortable: false },
        { name: 'Affiliate Id', field: 'affiliateId', sortable: false },
        { name: 'Web Site', field: '', sortable: false },
        { name: 'Number Of Point Of Sale', field: '', sortable: false },
        { name: 'Action', field: '', sortable: false },

    ];

    const QrCodeDownload = ({ link, id, number }) => {
        const qrCodeRef = useRef([]);
        const [modal, setModal] = useState(false);

        const toggle = () => setModal(!modal);
        return (
            <>
                <Modal isOpen={modal} toggle={toggle} >
                    <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                    <ModalBody style={{
                        display: "flex",
                        justifyContent: "center"
                    }}  >
                        <QrCodeSvg
                            ref={(el) => (qrCodeRef.current = el)}
                            show={true}
                            // location={links.menu}
                            link={link}
                            width={'200px'}
                            relativeLink={false}
                            size={600}
                            id={id}
                            number={number}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>

                <div style={{ display: 'none' }} >
                    <QrCodeSvg
                        ref={(el) => (qrCodeRef.current = el)}
                        show={true}
                        // location={links.menu}
                        link={link}
                        width={'200px'}
                        relativeLink={false}
                        size={600}
                        id={id}
                        number={number}
                    />
                </div>

                <Button
                    className="mr-1 btn-not-background"
                    onClick={toggle}
                >
                    <i className="fas fa-eye"></i>{' '}
                </Button>
                <Button
                    className="mr-1 btn-not-background"
                    onClick={() => {
                        qrCodeRef.current.downloadQR();
                    }}
                >
                    <i className="fas fa-download"></i>{' '}
                </Button>


            </>

        )
    }

    const ListContacts = useMemo(() => {
        if (!data) return [];
        let list = data;

        if (search) {
            list = list.filter(
                (comment) =>
                    comment.number
                        ?.toLowerCase()
                        .includes(search.toLowerCase()) ||
                    comment.hash
                        ?.toLowerCase()
                        .includes(search.toLowerCase()) ||
                    comment.pointOfSale?.title
                        ?.toLowerCase()
                        .includes(search.toLowerCase())
            );
        }

        setTotalItems(list.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === 'asc' ? 1 : -1;
            list = list.sort(
                (a, b) =>
                    reversed *
                    a[sorting.field]?.localeCompare(b[sorting.field]),
            );
        }

        list = list.filter((el) => el !== undefined);

        //Current Page slice
        return list.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE,
        );
    }, [data, currentPage, search, sorting]);




    return (
        <>
            <div className="row w-100">
                <div className="col mb-3 col-12 text-center">
                    {/*<div className="row  mb-2">*/}
                    {/*    <div className="col-md-6">*/}
                    {/*        <Search*/}
                    {/*            onSearch={(value) => {*/}
                    {/*                setSearch(value);*/}
                    {/*                setCurrentPage(1);*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <div className="col-md-6 d-flex flex-row-reverse">*/}
                    {/*        <Button*/}
                    {/*            variant="warning"*/}
                    {/*            onClick={() => setShowAdd(true)}*/}
                    {/*        >*/}
                    {/*            ADD{' '}*/}
                    {/*        </Button>*/}

                    {/*        /!* <ExportPDF data={commentsData} /> *!/*/}
                    {/*        /!* <ExportCSV csvData={commentsData} fileName={"testExel"}>Export Exle</ExportCSV> *!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <Table>
                        <TableHeader
                            headers={headers}
                            textAlign={"center"}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                        <tbody>
                            {ListContacts.map((comment, index) => (
                                <tr key={index}>
                                    <td  >
                                        {comment.name}
                                    </td>
                                    <td  >
                                        {comment.email}
                                    </td>

                                    <td  >
                                        {comment.affiliateId}
                                    </td>

                                    <td  >
                                        {comment.website}
                                    </td>
                                    <td  >
                                        {comment.numOfAffiliate || 0}
                                    </td>
                                    <td  >
                                        <Button onClick={editPartner(comment)}>
                                            edit
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


Partner.propTypes = {};
Partner.defaultProps = {};

export default Partner;
