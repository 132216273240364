// @flow

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CALLSPECIALDAYS,
  SAVESPECIALDAYS,
} from '../../constants/actionTypes';

import { setSpecialDays } from './actions';
import { RetriveSpecialDays, saveSpecialDays } from './Services';

function* retriveSpecialDays({ payload: { pointOfSaleId } }) {
  try {
    const { data } = yield call(RetriveSpecialDays, pointOfSaleId);
    console.log('Response+-+-+-+-+-+- ::: ', data);
    yield put(setSpecialDays(data.result));
  } catch (err) {
    console.log('errr', err);
  }
}

function* SaveSpecialDays({
  payload: { spacialDays, pointOfSaleId },
}) {
  try {
    const { data } = yield call(
      saveSpecialDays,
      spacialDays,
      pointOfSaleId,
    );
    console.log('Response+-+-+-+-+-+- ::: ', data);
    yield put(setSpecialDays(data.SpecialDays));
  } catch (err) {
    console.log('errr', err);
  }
}
export function* WatchCallSpecialDays() {
  yield takeEvery(CALLSPECIALDAYS, retriveSpecialDays);
}

export function* WatchSaveSpecialDays() {
  yield takeEvery(SAVESPECIALDAYS, SaveSpecialDays);
}

function* WeeklyScheduleSaga() {
  yield all([fork(WatchCallSpecialDays), fork(WatchSaveSpecialDays)]);
}

export default WeeklyScheduleSaga;
