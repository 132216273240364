// @flow
import {
  ADD_USER,
  ADD_USER_FAILED,
  ADD_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESS,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,
} from '../../constants/actionTypes';
import Users from './reducers';

export const addUser = (user, pointOfSaleId, callback, onError) => ({
  type: ADD_USER,
  payload: { user, pointOfSaleId, callback, onError },
});

export const addUserSuccess = (user) => ({
  type: ADD_USER_SUCCESS,
  payload: user,
});

export const addUserFailed = (error) => ({
  type: ADD_USER_FAILED,
  payload: error,
});

export const UpdateUser = (user, pointOfSaleId) => ({
  type: UPDATE_USER,
  payload: { user, pointOfSaleId },
});

export const UpdateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
});

export const UpdateUserFailed = (error) => ({
  type: UPDATE_USER_FAILED,
  payload: error,
});

export const deleteUser = (user) => ({
  type: DELETE_USER,
  payload: { user },
});

export const deleteUserSuccess = (user) => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
});

export const deleteUserFailed = (error) => ({
  type: DELETE_USER_FAILED,
  payload: error,
});

export const getUsers = (pointOfSaleId) => ({
  type: GET_USERS,
  payload: { pointOfSaleId },
});

export const getUsersSuccess = (users) => ({
  type: GET_USERS_SUCCESS,
  payload: users,
});

export const getUsersFailed = (error) => ({
  type: GET_USERS_FAILED,
  payload: error,
});
