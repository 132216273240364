// @flow
import {
  CALLWEEKLYSCHEDULE,
  SETWEEKLYSCHEDULE,
} from '../../constants/actionTypes';

export const CallWeeklyDays = (
  pointOfSaleId,
  callback = () => null,
) => ({
  type: CALLWEEKLYSCHEDULE,
  payload: { pointOfSaleId, callback },
});

export const setWeeklyDays = (days, pointOfSaleId) => ({
  type: SETWEEKLYSCHEDULE,
  payload: { days, pointOfSaleId },
});
