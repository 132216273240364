import React from 'react';
import PropTypes from 'prop-types';
import TutorialsList from '../../../components/UsersList';

function TableUsers(props) {
  return (
    <div>
      <TutorialsList />
    </div>
  );
}

TableUsers.propTypes = {};
TableUsers.defaultProps = {};

export default TableUsers;
