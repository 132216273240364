import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import iconNotif from '../../src/assets/images/Untitled-10.png';
const notificationContainerStyle = {
  maxHeight: '230px',
};

const NotificationDropdown = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const getRedirectUrl = (item) => {
    return `/notification/${item.id}`;
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className="notification-list"
    >
      <DropdownToggle
        data-toggle="dropdown"
        tag="button"
        className="nav-link dropdown-toggle  waves-effect waves-light btn btn-link"
        onClick={toggleDropdown}
        aria-expanded={dropdownOpen}
      >
        {/* <i className="fe-bell noti-icon"></i> */}
        <img
          // className={style.notif}
          src={iconNotif}
          width={30}
          height={30}
        />
        <span className="badge badge-danger rounded-circle noti-icon-badge">
          9
        </span>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-lg">
        <div onClick={toggleDropdown}>
          <div className="dropdown-item noti-title">
            <h5 className="m-0">
              <span className="float-right">
                <Link to="/notifications" className="text-dark">
                  <small>Clear All</small>
                </Link>
              </span>
              Notification
            </h5>
          </div>
          <PerfectScrollbar style={notificationContainerStyle}>
            {props.notifications.map((item, i) => {
              return (
                <Link
                  to={getRedirectUrl(item)}
                  className="dropdown-item notify-item"
                  key={i + '-noti'}
                >
                  <div className={`notify-icon bg-${item.bgColor}`}>
                    <i className={item.icon}></i>
                  </div>
                  <p className="notify-details">
                    {item.text}
                    <small className="text-muted">
                      {item.subText}
                    </small>
                  </p>
                </Link>
              );
            })}
          </PerfectScrollbar>

          <Link
            to="/"
            className="dropdown-item text-center text-primary notify-item notify-all"
          >
            View All
          </Link>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};
NotificationDropdown.defaultProps = {
  notifications: [],
};

export default NotificationDropdown;
