import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';

// import * as serviceWorker from "./serviceWorker";
import { PersistGate } from 'redux-persist/integration/react';

import { Provider } from 'react-redux';
import store from './redux/store';
import { I18nextProvider } from 'react-i18next';
import { i18n } from './helpers/i18n';
import sagas from './redux/sagas';

store.sagaMiddleware.run(sagas);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<I18nextProvider i18n={i18n}>
    <Provider store={store.store}>
        <PersistGate persistor={store.persistor}>
            <App />
        </PersistGate>
    </Provider>
</I18nextProvider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
