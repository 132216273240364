import { REHYDRATE } from 'redux-persist';

// @flow
import {
  GETTIMEPERBOOKING,
  GETTIMEPERBOOKINGSUCCESS,
  SETTIMEPERBOOKING,
} from '../../constants/actionTypes';

const initialState = {
  TmePerBooking: 2,
  NoShowTime: 15,
  NbCommand: 5,
  timePerCommand: 0.5,
  loading: false,
};

const TimeSettings = (state = initialState, action) => {
  switch (action.type) {
    case SETTIMEPERBOOKING:
      return {
        TmePerBooking: action.payload.time,
        NoShowTime: action.payload.NoShow,
        NbCommand: action.payload.NbCommand,
        timePerCommand: action.payload.timeCommand,
        loading: true,
      };
    case GETTIMEPERBOOKINGSUCCESS:
      return {
        TmePerBooking: action.payload.time,
        pointOfSaleId: action.payload.pointOfSaleId,
        NoShowTime: action.payload.NoShow,
        NbCommand: action.payload.NbCommand,
        timePerCommand: action.payload.timePerCommand,
        zones: action.payload.zones,
        enableSendSMS: action.payload.enableSendSMS,
        loading: false,
      };
    case GETTIMEPERBOOKING:
      return { ...state, loading: false };
    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.TmePerBooking,
          }
        : {
            ...state,
          };
    default:
      return state;
  }
};

export default TimeSettings;
