import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  CREATE_INGREDIENT,
  CREATE_PRODUCT,
  CREATE_PRODUCT_CATEGORY,
  EDIT_INGREDIENT,
  EDIT_PRODUCT,
  EDIT_PRODUCT_CATEGORY,
  EDIT_PRODUCT_SHOW_IN_POS_STATUS,
  GET_CATEGORY_with_PRODUCT,
  GET_INGREDIENT,
  GET_PRODUCT,
  GET_PRODUCT_CATEGORY,
} from '../../constants/actionTypes';
import {
  createIngredientFailed,
  createIngredientSuccess,
  createProductCategoryFailed,
  createProductCategorySuccess,
  createProductFailed,
  createProductSuccess,
  editIngredientFailed,
  editIngredientSuccess,
  editProductCategoryFailed,
  editProductCategorySuccess,
  editProductFailed,
  editProductSuccess,
  getCategoryWithProductFailed,
  getCategoryWithProductSuccess,
  getIngredientFailed,
  getIngredientSuccess,
  getProductCategoryFailed,
  getProductCategorySuccess,
  getProductsFailed,
  getProductsSuccess,
  setCategoryProductPosShowStatusFailed,
  setCategoryProductPosShowStatusSuccess,
} from './actions';
import {
  createIngredientService,
  createProductCategoryService,
  createProductService,
  editIngredientService,
  editProductCategoryService,
  editProductService,
  getCategoryWithProductService,
  getIngredientService,
  getProductCategoryService,
  getProductsService,
  set_product_visibility_in_pos,
} from './service';

function* getProductCategory({ payload: { pointOfSaleId } }) {
  try {
    const response = yield call(
      getProductCategoryService,
      pointOfSaleId,
    );
    yield put(getProductCategorySuccess(response.productCategory));
  } catch (error) {
    yield put(getProductCategoryFailed(error));
  }
}

function* getCategoryWithProduct({ payload: { pointOfSaleId } }) {
  try {
    const response = yield call(
      getCategoryWithProductService,
      pointOfSaleId,
    );
    yield put(
      getCategoryWithProductSuccess(response.productCategory),
    );
  } catch (error) {
    console.log('0------>error', error);
    yield put(getCategoryWithProductFailed(error));
  }
}

function* getIngredient({ payload: { pointOfSaleId } }) {
  try {
    const response = yield call(getIngredientService, pointOfSaleId);
    yield put(getIngredientSuccess(response.ingredients));
  } catch (error) {
    yield put(getIngredientFailed(error));
  }
}

function* getProducts({ payload: { pointOfSaleId } }) {
  try {
    const response = yield call(getProductsService, pointOfSaleId);
    yield put(getProductsSuccess(response.products));
  } catch (error) {
    yield put(getProductsFailed(error));
  }
}

function* editProductCategory({
  payload: {
    productCategoryId,
    categoryName,
    categoryType,
    unit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
    handleClose,
  },
}) {
  try {
    const response = yield call(editProductCategoryService, {
      productCategoryId,
      categoryName,
      categoryType,
      unit,
      VATPurchase,
      VATSales,
      VATTakeaway,
      VATSaleToDeliver,
    });
    yield put(editProductCategorySuccess(response.productCategory));
    handleClose();
  } catch (error) {
    yield put(editProductCategoryFailed(error));
  }
}

function* createProductCategory({
  payload: {
    pointOfSaleId,
    categoryName,
    categoryType,
    unit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
    handleClose,
    subCategories,
  },
}) {
  try {
    const response = yield call(createProductCategoryService, {
      pointOfSaleId,
      categoryName,
      categoryType,
      unit,
      VATPurchase,
      VATSales,
      VATTakeaway,
      VATSaleToDeliver,
      subCategories,
    });
    yield put(createProductCategorySuccess(response.productCategory));
    handleClose();
  } catch (error) {
    yield put(createProductCategoryFailed(error));
  }
}

function* createIngredient({
  payload: {
    pointOfSaleId,
    ingredientName,
    ingredientType,
    sellUnit,
    purchaseUnit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
    sellPrice,
    buyingPrice,
    image,
    productCategory,
    suppliers,
    handleClose,
    stockInitial,
  },
}) {
  try {
    const response = yield call(createIngredientService, {
      pointOfSaleId,
      ingredientName,
      ingredientType,
      sellUnit,
      purchaseUnit,
      VATPurchase,
      VATSales,
      VATTakeaway,
      VATSaleToDeliver,
      sellPrice,
      buyingPrice,
      image,
      productCategory,
      suppliers,
      stockInitial,
    });
    yield put(createIngredientSuccess(response.ingredient));
    handleClose();
  } catch (error) {
    yield put(createIngredientFailed(error));
  }
}

function* editProduct({
  payload: {
    productId,
    Itemtype,
    unit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
    desc,
    details,
    extras,
    itemName,
    ingredients,
    purchaseTypes,
    preparationTime,
    price,
    productCategory,
    productNature,
    productType,
    image,
    suppliers,
    content,
    handleClose,
  },
}) {
  try {
    const response = yield call(editProductService, {
      productId,
      Itemtype,
      unit,
      VATPurchase,
      VATSales,
      VATTakeaway,
      VATSaleToDeliver,
      desc,
      details,
      extras,
      ingredients,
      preparationTime,
      purchaseTypes,
      price,
      itemName,
      productCategory,
      productNature,
      productType,
      suppliers,
      content,
      image,
    });
    console.log(`response_______-`, response);
    yield put(editProductSuccess(response.product));

    handleClose();
  } catch (error) {
    yield put(editProductFailed(error));
  }
}

function* editIngredient({
  payload: {
    ingredientId,
    ingredientName,
    ingredientType,
    sellUnit,
    purchaseUnit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
    sellPrice,
    buyingPrice,
    image,
    productCategory,
    suppliers,
    handleClose,
  },
}) {
  try {
    const response = yield call(editIngredientService, {
      ingredientId,
      ingredientName,
      ingredientType,
      sellUnit,
      purchaseUnit,
      VATPurchase,
      VATSales,
      VATTakeaway,
      VATSaleToDeliver,
      sellPrice,
      buyingPrice,
      image,
      productCategory,
      suppliers,
    });
    console.log(`response_______-`, response);
    yield put(editIngredientSuccess(response.ingredient));

    handleClose();
  } catch (error) {
    yield put(editIngredientFailed(error));
  }
}

function* createProduct({
  payload: {
    pointOfSaleId,
    Itemtype,
    unit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
    desc,
    details,
    extras,
    itemName,
    ingredients,
    purchaseTypes,
    preparationTime,
    price,
    productCategory,
    productNature,
    productType,
    image,
    suppliers,
    content,
    handleClose,
    stockInitial,
  },
}) {
  try {
    const response = yield call(createProductService, {
      pointOfSaleId,
      Itemtype,
      unit,
      VATPurchase,
      VATSales,
      VATTakeaway,
      VATSaleToDeliver,
      desc,
      details,
      extras,
      ingredients,
      preparationTime,
      purchaseTypes,
      price,
      itemName,
      productCategory,
      productNature,
      productType,
      suppliers,
      content,
      image,
      stockInitial,
    });
    console.log(`response_______-`, response);
    yield put(createProductSuccess(response.product));

    handleClose();
  } catch (error) {
    yield put(createProductFailed(error));
  }
}

function* setShowProductCategoryInPos({
  payload: { productCategory },
}) {
  try {
    const response = yield call(
      set_product_visibility_in_pos,
      productCategory,
    );
    yield put(
      setCategoryProductPosShowStatusSuccess(
        response.productCategory,
      ),
    );
  } catch (error) {
    console.log(JSON.stringify({ errrrrr: error }, '', '\t'));
    yield put(setCategoryProductPosShowStatusFailed(error));
  }
}

export function* watchCreateProduct() {
  yield takeEvery(CREATE_PRODUCT, createProduct);
}

export function* watchEditProduct() {
  yield takeEvery(EDIT_PRODUCT, editProduct);
}

export function* watchEditIngredient() {
  yield takeEvery(EDIT_INGREDIENT, editIngredient);
}

export function* watchCreateIngredient() {
  yield takeEvery(CREATE_INGREDIENT, createIngredient);
}

export function* watchCreateProductCategory() {
  yield takeEvery(CREATE_PRODUCT_CATEGORY, createProductCategory);
}

export function* watchEditProductCategory() {
  yield takeEvery(EDIT_PRODUCT_CATEGORY, editProductCategory);
}

export function* watchGetProducts() {
  yield takeEvery(GET_PRODUCT, getProducts);
}

export function* watchGetProductCategory() {
  yield takeEvery(GET_PRODUCT_CATEGORY, getProductCategory);
}

export function* watchGetIngredient() {
  yield takeEvery(GET_INGREDIENT, getIngredient);
}

export function* watchGetCategoryWithProduct() {
  yield takeEvery(GET_CATEGORY_with_PRODUCT, getCategoryWithProduct);
}

export function* watchSetShowCategoryProductInPos() {
  yield takeEvery(
    EDIT_PRODUCT_SHOW_IN_POS_STATUS,
    setShowProductCategoryInPos,
  );
}
function* caisseSaga() {
  yield all([
    fork(watchGetProductCategory),
    fork(watchGetIngredient),
    fork(watchGetProducts),
    fork(watchCreateProductCategory),
    fork(watchCreateIngredient),
    fork(watchCreateProduct),
    fork(watchEditProductCategory),
    fork(watchEditProduct),
    fork(watchEditIngredient),
    fork(watchGetCategoryWithProduct),
    fork(watchSetShowCategoryProductInPos),
  ]);
}

export default caisseSaga;
