import { REHYDRATE } from 'redux-persist';

// @flow
import {
  GET_AFFILIATION_ID,
  GET_AFFILIATION_ID_SUCCESS,
  GET_AFFILIATION_ID_FAILED,
} from '../../constants/actionTypes';

const initialState = {
  affiliateId: null,
  rewardAffiliated: 0,
  numberOfAffiliated: 0,
  nbClickAffiliationLink: 0,
  loading: false,
};

const AffiliationSettings = (state = initialState, action) => {
  switch (action.type) {
    case GET_AFFILIATION_ID_FAILED:
      return {
        error: action.payload,
        loading: false,
      };
    case GET_AFFILIATION_ID_SUCCESS:
      return {
        affiliateId: action.payload.affiliateId,
        rewardAffiliated: action.payload.rewardAffiliated,
        numberOfAffiliated: action.payload.numberOfAffiliated,
        nbClickAffiliationLink: action.payload.nbClickAffiliationLink,
        loading: false,
      };
    case GET_AFFILIATION_ID:
      return { ...state, loading: true };
    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.AffiliationSettings,
          }
        : {
            ...state,
          };
    default:
      return state;
  }
};

export default AffiliationSettings;
