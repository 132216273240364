import { REHYDRATE } from 'redux-persist';

import {
  GET_CLICK_COLLECT_TIME,
  GET_CLICK_COLLECT_TIME_FAILED,
  GET_CLICK_COLLECT_TIME_SUCCESS,
} from '../../constants/actionTypes';

const INIT_STATE = {
  times: [],
  loading: false,
  error: false,
};

const ClickCollect = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLICK_COLLECT_TIME:
      return { ...state, loading: true };
    case GET_CLICK_COLLECT_TIME_SUCCESS:
      return {
        ...state,
        times: action.payload,
        loading: false,
      };
    case GET_CLICK_COLLECT_TIME_FAILED:
      return { ...state, loading: false, error: action.payload };

    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.CommendMenu,
          }
        : {
            ...state,
          };
    default:
      return { ...state };
  }
};

export default ClickCollect;
