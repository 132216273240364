import React, {useEffect, useMemo, useRef, useState} from "react";
import {httpClient_get, httpClient_post} from "../../helpers/api";
import {default as Loader} from "../../components/Loader";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "reactstrap";
import {useDispatch} from "react-redux";
import QrCodeSvg from "../../components/QrCodeSvg";
import {Pagination, TableHeader} from "../../components/DataTable";


const QrCodesSpots = ({show, setShow}) => {


    const [qrCodes, setQrCodes] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setShow(false)
    };


    useEffect(() => {
        setLoading(true);
        httpClient_get(`/qr-code-spot/`)
            .then((response) => {
                setQrCodes(response?.data?.qrCodes)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }, []);


    function generateQrCode() {
        setLoading(true);
        httpClient_post(`/qr-code-spot/generateCode`)
            .then((response) => {
                setQrCodes([...qrCodes, response.data.qrCode])
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });

    }

    return (
        <div >
            {loading && <Loader/>}

            <Row style={{justifyContent: "flex-end"}}>
                <Button variant="primary" type="submit" onClick={generateQrCode}>
                    Generate New QR CODE
                </Button>
            </Row>

            <br />
            <div>

                <DataTable qrCodes={qrCodes}/>


            </div>
        </div>
    );
};


const DataTable = ({qrCodes}) => {
    const [comments, setComments] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [sorting, setSorting] = useState({field: '', order: ''});
    const [showEdit, setShowEdit] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [contact, setContact] = useState({});
    const [showInfo, setShowInfo] = useState(false);
    const dispatch = useDispatch();


    const ITEMS_PER_PAGE = 50;

    const headers = [
        {name: 'Number', field: 'number', sortable: false},
        {name: 'Hash', field: 'hash', sortable: false},
        {name: 'Spot', field: 'pointOfSale', sortable: false},
        {name: 'Qr Code', field: 'owner.lastName', sortable: false},
    ];

    const QrCodeDownload = ({link, id , number}) => {
        const qrCodeRef = useRef([]);
        const [modal, setModal] = useState(false);

        const toggle = () => setModal(!modal);
        return (
            <>
                <Modal isOpen={modal} toggle={toggle} >
                    <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                    <ModalBody style={{
                        display: "flex",
                        justifyContent: "center"
                    }}  >
                        <QrCodeSvg
                            ref={(el) => (qrCodeRef.current = el)}
                            show={true}
                            // location={links.menu}
                            link={link}
                            width={'200px'}
                            relativeLink={false}
                            size={600}
                            id={id}
                            number={number}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>

                <div style={{display: 'none'}} >
                    <QrCodeSvg
                        ref={(el) => (qrCodeRef.current = el)}
                        show={true}
                        // location={links.menu}
                        link={link}
                        width={'200px'}
                        relativeLink={false}
                        size={600}
                        id={id}
                        number={number}
                    />
                </div>

                <Button
                    className="mr-1 btn-not-background"
                    onClick={toggle}
                >
                    <i className="fas fa-eye"></i>{' '}
                </Button>
                <Button
                    className="mr-1 btn-not-background"
                    onClick={() => {
                        qrCodeRef.current.downloadQR();
                    }}
                >
                    <i className="fas fa-download"></i>{' '}
                </Button>


            </>

        )
    }

    const ListContacts = useMemo(() => {
        let list = qrCodes;

        if (search) {
            list = list.filter(
                (comment) =>
                    comment.number
                        ?.toLowerCase()
                        .includes(search.toLowerCase()) ||
                    comment.hash
                        ?.toLowerCase()
                        .includes(search.toLowerCase()) ||
                    comment.pointOfSale?.title
                        ?.toLowerCase()
                        .includes(search.toLowerCase())
            );
        }

        setTotalItems(list.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === 'asc' ? 1 : -1;
            list = list.sort(
                (a, b) =>
                    reversed *
                    a[sorting.field]?.localeCompare(b[sorting.field]),
            );
        }

        list = list.filter((el) => el !== undefined);

        //Current Page slice
        return list.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE,
        );
    }, [qrCodes, currentPage, search, sorting]);

    return (
        <>

            <div className="row w-100">
                <div className="col mb-3 col-12 text-center">
                    {/*<div className="row  mb-2">*/}
                    {/*    <div className="col-md-6">*/}
                    {/*        <Search*/}
                    {/*            onSearch={(value) => {*/}
                    {/*                setSearch(value);*/}
                    {/*                setCurrentPage(1);*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <div className="col-md-6 d-flex flex-row-reverse">*/}
                    {/*        <Button*/}
                    {/*            variant="warning"*/}
                    {/*            onClick={() => setShowAdd(true)}*/}
                    {/*        >*/}
                    {/*            ADD{' '}*/}
                    {/*        </Button>*/}

                    {/*        /!* <ExportPDF data={commentsData} /> *!/*/}
                    {/*        /!* <ExportCSV csvData={commentsData} fileName={"testExel"}>Export Exle</ExportCSV> *!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <Table>
                        <TableHeader
                            headers={headers}
                            textAlign={"center"}
                            onSorting={(field, order) =>
                                setSorting({field, order})
                            }
                        />
                        <tbody>
                        {ListContacts.map((comment, index) => (
                            <tr key={index}>
                                <td >
                                    {comment.number || '_'}
                                </td>
                                <td >
                                    <a href={"https://welcome.openflow.pro/redirect/" + comment.hash}
                                       target={"_blank"}>  {comment.hash} </a>
                                </td>

                                <td  >{comment.pointOfSale?.title}</td>

                                <td  >
                                    <div
                                        style={{
                                            justifyContent: 'center',
                                            alignContent: 'center',
                                            display: 'flex',
                                        }}
                                    >
                                        <QrCodeDownload
                                            link={"https://welcome.openflow.pro/redirect/" + comment.hash}
                                            id={`${comment.hash}_${comment.number}`}
                                            number={index +1}
                                        />

                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QrCodesSpots
