// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
  GET_USERS,
} from '../../constants/actionTypes';

import {
  addUserSuccess,
  addUserFailed,
  UpdateUserSuccess,
  UpdateUserFailed,
  deleteUserSuccess,
  deleteUserFailed,
  getUsersSuccess,
  getUsersFailed,
} from './actions';
import {
  addUserService,
  updateUserService,
  deleteUserService,
  getUsersService,
} from './service';

/**
 *
 */
function* addUser({
  payload: { user, pointOfSaleId, callback, onError },
}) {
  try {
    const response = yield call(addUserService, user, pointOfSaleId);
    yield put(addUserSuccess(response));
    callback();
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(addUserFailed(error));
    onError(error);
  }
}
/**
 *
 */
function* updateUser({ payload: { user, pointOfSaleId } }) {
  try {
    const response = yield call(
      updateUserService,
      user,
      pointOfSaleId,
    );
    yield put(UpdateUserSuccess(response));
  } catch (error) {
    yield put(UpdateUserFailed(error));
  }
}

function* deleteUser({ payload: { user } }) {
  try {
    const response = yield call(deleteUserService, user);
    yield put(deleteUserSuccess(response));
  } catch (error) {
    console.log('error', error);
    yield put(deleteUserFailed(error));
  }
}

function* getUsers({ payload: { pointOfSaleId } }) {
  try {
    const response = yield call(getUsersService, pointOfSaleId);
    yield put(getUsersSuccess(response));
  } catch (error) {
    yield put(getUsersFailed(error));
  }
}

export function* watchAddUser() {
  yield takeEvery(ADD_USER, addUser);
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUser);
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUser);
}

export function* watchGetUsers() {
  yield takeEvery(GET_USERS, getUsers);
}

function* usersSaga() {
  yield all([
    fork(watchAddUser),
    fork(watchUpdateUser),
    fork(watchDeleteUser),
    fork(watchGetUsers),
  ]);
}

export default usersSaga;
