import React, {Component, useImperativeHandle, useState} from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import createImagePlugin from "draft-js-image-plugin";

import ImageOption from "./ImageOption";
import LinkOption from "./LinkOption";
import UnlinkOption from "./UnlinkOption";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js-image-plugin/lib/plugin.css";
import "./style.scss";
import {Button} from "reactstrap";

const imagePlugin = createImagePlugin();

const TextEditor  =  React.forwardRef(({onSave}, ref) => {

    const [editorState, setEditorState] = useState(EditorState.createEmpty());


    useImperativeHandle(ref, () => ({
        reset() {
            resetContent()
        }
    }))


    const resetContent = ()=>{
        setEditorState(EditorState.createEmpty())

    }

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);

    }

    const onSave_ =()=>{
        onSave(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }

        return (
            <div>
                <Editor
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
                        options: [
                            "inline",
                            "blockType",
                            "fontSize",
                            "fontFamily",
                            "list",
                            "textAlign",
                            "remove",
                            "history",
                        ],
                    }}
                    stripPastedStyles={true}
                    toolbarCustomButtons={[
                        <ImageOption />,
                        <LinkOption />,
                        <UnlinkOption />
                    ]}
                    plugins={[imagePlugin]}
                />
                {/*<textarea*/}
                {/*    disabled*/}
                {/*    value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}*/}
                {/*    className="textarea"*/}
                {/*/>*/}

                <Button onClick={onSave_} >
                    Send
                </Button>
            </div>
        );

})
export default TextEditor;
