import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';
import store from '../redux/store';
import { httpClient_post } from './api';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = (props) => {
  const user = getLoggedInUser();
  if (!user) {
    return false;
  }
  const decoded = jwtDecode(user.token);
  const loggedReduxUserLang = store?.store?.getState()?.Auth?.user
    ?.lang;
  if (
    props != undefined &&
    props.i18n.language != loggedReduxUserLang
  ) {
    props?.i18n?.changeLanguage(loggedReduxUserLang);
  }

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    console.warn('access token expired');
    return false;
  } else {
    return true;
  }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  const cookies = new Cookies();
  const user = cookies.get('user');
  return user
    ? typeof user == 'object'
      ? user
      : JSON.parse(user)
    : null;
};

/**
 * Returns the user
 */
const getUser = async () => {
  const user = getLoggedInUser();
  try {
    const rest = await httpClient_post('/auth', {
      email: user.email,
      password: user.password,
    });

    return rest.data.user;
  } catch (error) {}
};

/**
 * Returns the logged in user
 */
const getCredentialsUser = () => {
  const credentials = localStorage.getItem('credentials');
  return credentials
    ? typeof credentials == 'object'
      ? credentials
      : JSON.parse(credentials)
    : null;
};

function authHeader(headers = null) {
  const user = getLoggedInUser();

  if (user) {
    return {
      ...{
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + user.token,
      },
      ...headers,
    };
  } else {
    return {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
  }
}

// const logautUSer=()=>{

// }

export {
  isUserAuthenticated,
  getLoggedInUser,
  getCredentialsUser,
  authHeader,
  getUser,
};
