import MetisMenu from 'metismenujs/dist/metismenujs';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import i18n from 'i18n-js';
import icon9 from '../../assets/images/contact.png';
import frIcon from '../../assets/images/france-flag-icon-free-download.jpg';
import enIcon from '../../assets/images/the-united-states-flag-icon-free-download.jpg';
import icon1 from '../../assets/images/Untitled-1.png';
import icon2 from '../../assets/images/Untitled-3.png';
import icon4 from '../../assets/images/Untitled-4.png';
import icon5 from '../../assets/images/Untitled-5.png';
import icon6 from '../../assets/images/Untitled-6.png';
import icon7 from '../../assets/images/Untitled-7.png';
import icon8 from '../../assets/images/Untitled-8.png';
import profilePic from '../../assets/images/users/user-1.jpg';
import icon10 from '../../assets/images/visitor.png';
import { ability } from '../../helpers/ability';
import { setLanguage } from '../../redux/actions';
import NotificationDropdown from '../NotificationDropdown';
import ProfileDropdown from '../ProfileDropdown';
import style from './style.module.scss';

const Link_ = (props) => {
  return (
    <div
      className={[
        style.nav_link_ref,
        !props.access ? style.nav_link_dis : '',
      ].join(' ')}
    >
      {props.access ? (
        <Link
          to={props.link}
          className={['waves-effect ', style.nav_link].join(' ')}
        >
          {props.children}
        </Link>
      ) : (
        <div
          className={['waves-effect ', style.nav_link_dis].join(' ')}
        >
          {props.children}
        </div>
      )}
    </div>
  );
};

const Navigation = (props) => {
  let menuNodeRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleOtherClick, false);
    return () => {
      document.removeEventListener(
        'mousedown',
        handleOtherClick,
        false,
      );
    };
  });
  useEffect(() => {
    initMenu();
  }, []);

  useEffect(() => {
    if (!props.isCondensed) {
      document.body.classList.remove('sidebar-enable');
      document.body.classList.remove('enlarged');
    } else {
      document.body.classList.add('sidebar-enable');
      const isSmallScreen = window.innerWidth < 768;
      if (!isSmallScreen) {
        document.body.classList.add('enlarged');
      }
    }

    // initMenu();
  }, [props.isCondensed]);

  /**
   * Handle the click anywhere in doc
   */
  const handleOtherClick = (e) => {
    if (menuNodeRef !== null && menuNodeRef !== undefined) return;
    document.body.classList.remove('sidebar-enable');
  };

  /**
   * Init the menu
   */
  const initMenu = () => {
    // render menu
    if (props.showMenu && props.listRoot !== null) {
      new MetisMenu('#side-menu');
      var links = document.getElementsByClassName('nav_link_ref');
      var matchingMenuItem = null;
      for (var i = 0; i < links.length; i++) {
        if (props.location.pathname === links[i].pathname) {
          matchingMenuItem = links[i];
          break;
        }
      }
      if (matchingMenuItem) {
        // matchingMenuItem.classList.add(style.active_link);
        var parent = matchingMenuItem.parentElement;
        if (parent) {
          parent.classList.add(style.active_link);
        }
      }
    }
  };

  const isCondensed = props.isCondensed || false;

  return (
    <>
      {props.showMenu && props.listRoot !== null && (
        <nav id="side-menu" ref={(node) => (menuNodeRef = node)}>

          <Link_
              link={"/admin/upxp-management" ?? '/'}
              access={true}
          >
            <i
                className="mdi mdi-store"
                style={{
                  color: 'white',
                  fontSize: '30px',
                }}
            ></i>
          </Link_>
          <Link_
              link={"/admin/users" ?? '/'}
              access={true}
          >
            <i
                className="mdi mdi-account"
                style={{
                  color: 'white',
                  fontSize: '30px',

                }}
            ></i>
          </Link_>
          <Link_
             link={"/admin/qr-code" ?? '/'}
              access={true}
          >
         <i
                className="mdi mdi-qrcode"
              style={{
                 color: 'white',
                 fontSize: '30px',
                }}
            ></i>
          </Link_>
          <Link_
              link={"/admin/notifications" ?? '/'}
              access={true}
          >
            <i
                className="mdi mdi-notification-clear-all"
                style={{
                  color: 'white',
                  fontSize: '30px',
                }}
            ></i>
          </Link_>
          <Link_
              link={"/admin/partner" ?? '/'}
              access={true}
          >

            <i
                className="mdi mdi-gesture-tap"
                style={{
                  color: 'white',
                  fontSize: '30px',
                }}
            ></i>
          </Link_>


          {/* <Link_ link={props.listRoot[5].root || '/'} access={false}>
            <img src={icon2} width={40} />
          </Link_>

          <Link_ link={props.listRoot[6].root || '/'} access={false}>
            <img src={icon6} width={40} />
          </Link_> */}
        </nav>
      )}
    </>
  );
};

const Notifications = [
  {
    id: 1,
    text: 'Caleb Flakelar commented on Admin',
    subText: '1 min ago',
    icon: 'mdi mdi-comment-account-outline',
    bgColor: 'primary',
  },
  {
    id: 2,
    text: 'New user registered.',
    subText: '5 min ago',
    icon: 'mdi mdi-account-plus',
    bgColor: 'info',
  },
  {
    id: 3,
    text: 'Cristina Pride',
    subText: 'Hi, How are you? What about our next meeting',
    icon: 'mdi mdi-comment-account-outline',
    bgColor: 'success',
  },
  {
    id: 4,
    text: 'Caleb Flakelar commented on Admin',
    subText: '2 days ago',
    icon: 'mdi mdi-comment-account-outline',
    bgColor: 'danger',
  },
  {
    id: 5,
    text: 'Caleb Flakelar commented on Admin',
    subText: '1 min ago',
    icon: 'mdi mdi-comment-account-outline',
    bgColor: 'primary',
  },
  {
    id: 6,
    text: 'New user registered.',
    subText: '5 min ago',
    icon: 'mdi mdi-account-plus',
    bgColor: 'info',
  },
  {
    id: 7,
    text: 'Cristina Pride',
    subText: 'Hi, How are you? What about our next meeting',
    icon: 'mdi mdi-comment-account-outline',
    bgColor: 'success',
  },
  {
    id: 8,
    text: 'Caleb Flakelar commented on Admin',
    subText: '2 days ago',
    icon: 'mdi mdi-comment-account-outline',
    bgColor: 'danger',
  },
];

const ProfileMenus = [
  {
    label: 'Settings',
    icon: 'fe-settings',
    redirectTo: '/supervisor/Settings',
    can: {
      do: 'show',
      on: 'settings',
    },
  },
  {
    label: 'Logout',
    icon: 'fe-log-out',
    redirectTo: '/logout',
    hasDivider: true,
  },
];

const FlagDropdown = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const selectFlag = (lang) => {
    dispatch(setLanguage(lang));
    props.i18n.changeLanguage(lang);
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className="notification-list"
    >
      <DropdownToggle
        data-toggle="dropdown"
        tag="button"
        className="nav-link dropdown-toggle  waves-effect waves-light btn btn-link"
        // onClick={toggleDropdown}
        aria-expanded={dropdownOpen}
      >
        <img src={frIcon} width={16} height={11} />
      </DropdownToggle>
      <DropdownMenu left="true" className={style.dropdown_lx}>
        <div onClick={() => selectFlag('fr')}>
          <img src={frIcon} width={16} height={11} className="mr-1" />
          Français
        </div>
        <div onClick={() => selectFlag('en')} className="mt-1">
          <img src={enIcon} width={16} height={11} className="mr-1" />
          English
        </div>
      </DropdownMenu>
      <DropdownMenu left="true" className={style.dropdown_lx}>
        <div onClick={() => selectFlag('fr')}>
          <img src={frIcon} width={16} height={11} className="mr-1" />
          Français
        </div>
        <div onClick={() => selectFlag('en')} className="mt-1">
          <img src={enIcon} width={16} height={11} className="mr-1" />
          English
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};
const ProfileMenusAdmin = [
  {
    label: 'Settings',
    icon: 'fe-settings',
    redirectTo: '/Settings',
    can: {
      do: 'show',
      on: 'settings',
    },
  },
  {
    label: 'Logout',
    icon: 'fe-log-out',
    redirectTo: '/logout',
    hasDivider: true,
  },
];

const TopBarSuper = (props) => {
  return (
    <>
      <div
        className={['navbar-custom', style.navbar_custom_].join(' ')}
      >
        <div className={style.navbar_custom_b1}>
          <p>{props.title}</p>
          {props.selectedPointOfSale && (
            <Link to={'/supervisor/'} className="">
              <i className="fas fa-arrow-left fa-2x"></i>
            </Link>
          )}
        </div>
        <div className={style.navbar_custom_b2}>
          <Navigation {...props} />
        </div>
        <div className={style.navbar_custom_b3}>
          <ul className="list-unstyled topnav-menu float-right mb-0">
            <li>
              <FlagDropdown notifications={[]} i18n={props.i18n} />
            </li>
            <li>
              <NotificationDropdown notifications={Notifications} />
            </li>
            <li>
              <ProfileDropdown
                profilePic={profilePic}
                menuItems={ProfileMenus}
                menuItemsAdmin={ProfileMenusAdmin}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
//tes
export default TopBarSuper;
