// @flow
import {
  ADD_ADMIN_CONTACT,
  EDIT_ADMIN_CONTACT,
  GET_ADMIN_ALL_CONTACT,
  GET_ADMIN_ALL_CONTACT_FAILED,
  GET_ADMIN_ALL_CONTACT_SUCCESS,
} from '../../../constants/actionTypes';

export const getAllContacts = () => ({
  type: GET_ADMIN_ALL_CONTACT,
  payload: {},
});

export const getAllContactsSuccess = (contacts) => ({
  type: GET_ADMIN_ALL_CONTACT_SUCCESS,
  payload: contacts,
});

export const getAllContactsFailed = (error) => ({
  type: GET_ADMIN_ALL_CONTACT_FAILED,
  payload: error,
});

export const addContactAction = (contact) => ({
  type: ADD_ADMIN_CONTACT,
  payload: contact,
});

export const editContactAction = (contact) => ({
  type: EDIT_ADMIN_CONTACT,
  payload: contact,
});
