import { httpClient_get, httpClient_post } from '../../helpers/api';

const retriveDevices = (id) => {
  return httpClient_post(`/devices/retiveDevices/${id}`, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const devicesNotResutrantService = () => {
  return httpClient_get(`/devices/devicesNotResutrant`)
    .then((response) => {
      return response.data.devices;
    })
    .catch((error) => {
      throw error;
    });
};

const addDevicesToResutrantService = (idDevice, idResto) => {
  console.log('--------->>>', idDevice, idResto);
  return httpClient_post(`/devices/addDevicesToResutrant`, {
    idDevice,
    idResto,
  })
    .then((response) => {
      return response.data.device;
    })
    .catch((error) => {
      throw error;
    });
};

const affectationMenuToDeviceService = (id, idMenu) => {
  return httpClient_post(`/devices/${id}/menu`, { idMenu })
    .then((response) => {
      return response.data.device;
    })
    .catch((error) => {
      throw error;
    });
};

export {
  retriveDevices,
  devicesNotResutrantService,
  addDevicesToResutrantService,
  affectationMenuToDeviceService,
};
