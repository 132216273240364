// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  CALLWEEKLYSCHEDULE,
  SETWEEKLYSCHEDULE,
} from '../../constants/actionTypes';
import { setWeeklyDays } from './actions';
import { changeDaysWeekly, RetriveDaysWeekly } from './Services';

function* retriveScheduleDays({
  payload: { pointOfSaleId, callback },
}) {
  try {
    const Response = yield call(RetriveDaysWeekly, pointOfSaleId);
    console.log('Response -- retriveScheduleDays - -', Response);
    callback();
    yield put(setWeeklyDays(Response.data.result));
  } catch (err) {
    //console.log("err", err)
  }
}
function* changeWeeklyScheduleDays({
  payload: { days, pointOfSaleId },
}) {
  // console.log('data', payload)
  try {
    const data = yield call(changeDaysWeekly, days, pointOfSaleId);
    // console.log("Response", data.result)
    yield put(setWeeklyDays(data.result));
  } catch (err) {
    console.log('err', err);
  }
}

export function* WatchSetWeekLyDays() {
  yield takeEvery(CALLWEEKLYSCHEDULE, retriveScheduleDays);
}

export function* WatchChangeWeekLyDays() {
  yield takeEvery(SETWEEKLYSCHEDULE, changeWeeklyScheduleDays);
}

function* WeeklyScheduleSaga() {
  yield all([fork(WatchSetWeekLyDays), fork(WatchChangeWeekLyDays)]);
}

export default WeeklyScheduleSaga;
