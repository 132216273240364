import { EditorState } from "draft-js";

export function getSelectedBlocks(editorState) {
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    return _getSelectedBlocks(content, startKey, endKey);
}

export function isOneBlockSelected(editorState) {
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    return startKey === endKey;
}

export function isLinkInSelection(editorState) {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
        const content = editorState.getCurrentContent();
        const startOffset = selection.getStartOffset();
        const endOffset = selection.getEndOffset();
        const startKey = selection.getStartKey();
        const endKey = selection.getEndKey();
        const blocks = _getSelectedBlocks(content, startKey, endKey);
        let nLinks = 0;
        blocks.forEach(block => {
            block.findEntityRanges(
                character => {
                    if (character.getEntity() !== null) {
                        const entity = content.getEntity(character.getEntity());
                        if (entity.getType() === "LINK") {
                            return true;
                        }
                    }
                    return false;
                },
                (startEntity, endEntity) => {
                    if (
                        (startOffset <= startEntity && endOffset >= endEntity) ||
                        (startOffset >= startEntity && endOffset <= endEntity) ||
                        (startOffset > startEntity && startOffset < endEntity) ||
                        (endOffset > startEntity && endOffset < endEntity)
                    ) {
                        nLinks += 1;
                    }
                }
            );
        });
        return nLinks > 0;
    }
    return false;
}

export function getLinkExpandedSelection(editorState) {
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) {
        return selection;
    }
    const content = editorState.getCurrentContent();
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    const startingBlock = content.getBlockForKey(startKey);
    const endingBlock = content.getBlockForKey(endKey);
    const blocks =
        startKey === endKey ? [startingBlock] : [startingBlock, endingBlock];
    let startOffset = selection.getStartOffset();
    let endOffset = selection.getEndOffset();
    blocks.forEach(block => {
        block.findEntityRanges(
            character => {
                if (character.getEntity() !== null) {
                    const entity = content.getEntity(character.getEntity());
                    if (entity.getType() === "LINK") {
                        return true;
                    }
                }
                return false;
            },
            (startEntity, endEntity) => {
                if (startEntity < startOffset) {
                    startOffset = startEntity;
                }
                if (endEntity > endOffset) {
                    endOffset = endEntity;
                }
            }
        );
    });
    return _getSelection(editorState, startOffset, endOffset);
}

export function getClearSelectionEditor(editorState) {
    const selection = editorState.getSelection();
    const end = selection.getEndOffset();
    const newSelection = _getSelection(editorState, end, end);
    return EditorState.forceSelection(editorState, newSelection);
}

function _getSelection(editorState, startOffset, endOffset) {
    const selection = editorState.getSelection();
    var newSelection = selection.merge({
        anchorOffset: startOffset,
        focusOffset: endOffset
    });
    return newSelection;
}

function _getSelectedBlocks(content, startKey, endKey) {
    const startingBlock = content.getBlockForKey(startKey);
    const selectedBlocks = [startingBlock];
    if (startKey !== endKey) {
        let blockKey = startKey;
        while (blockKey !== endKey) {
            const nextBlock = content.getBlockAfter(blockKey);
            selectedBlocks.push(nextBlock);
            blockKey = nextBlock.getKey();
        }
    }
    return selectedBlocks;
}
