import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  ADD_RESTAURANT,
  ADD_RESTAURANT_CLIENT,
  EDIT_RESTAURANT,
  GET_RESTAURANTS,
  GET_RESTAURANTS_CLIENT,
  SHOW_RESTAURANT,
} from '../../constants/actionTypes';
import {
  addRestaurantClientFailed,
  addRestaurantClientSuccess,
  addRestaurantFailed,
  addRestaurantSuccess,
  editRestaurantFailed,
  editRestaurantSuccess,
  getRestaurantsClientFailed,
  getRestaurantsClientSuccess,
  getRestaurantsFailed,
  getRestaurantsSuccess,
  showRestaurantSuccess,
} from './actions';
import {
  addRestaurantsClientService,
  addRestaurantsService,
  editRestaurantsService,
  getRestaurantsClientService,
  getRestaurantsService,
  showRestaurantsService,
} from './service';

function* getRestaurants() {
  try {
    const { pointOfSales } = yield call(getRestaurantsService);

    yield put(getRestaurantsSuccess(pointOfSales));
    // yield put(getBookingsSuccess(response.bookings));
  } catch (error) {
    yield put(getRestaurantsFailed(error));
  }
}

function* addRestaurant({
  payload: { restaurant, createAdmin, callback },
}) {
  try {
    const resp = yield call(
      addRestaurantsService,
      restaurant,
      createAdmin,
    );

    console.log('----resp.PointOfSale----', resp.pointOfSale);
    callback();
    yield put(addRestaurantSuccess(resp.pointOfSale));
    // yield put(getBookingsSuccess(response.bookings));
  } catch (error) {
    let error__;

    if (error.codeError === 11000) {
      error__ = { message: 'duplicate email restaurant' };
    } else if (error.codeError === 'Restaurant validation failed') {
      error__ = { message: 'duplicate Restaurant' };
    } else {
      error__ = { message: 'duplicate email admin' };
    }

    yield put(addRestaurantFailed(error__));
  }
}
function* addRestaurantClient({
  payload: { restaurant, callbackSuccess, affiliateId },
}) {
  try {
    const resp = yield call(
      addRestaurantsClientService,
      restaurant,
      affiliateId,
    );
    yield put(addRestaurantClientSuccess(resp));
    callbackSuccess(resp.user);
  } catch (error) {
    console.log(error);
    let error__;
    if (error.codeError === 11000) {
      error__ = { message: 'duplicate email restaurant' };
    } else {
      error__ = { message: 'duplicate email admin' };
    }
    yield put(addRestaurantClientFailed(error__));
  }
}

function* editRestaurant({ payload: { pointOfSale, callback } }) {
  try {
    const resp = yield call(editRestaurantsService, pointOfSale);

    yield put(editRestaurantSuccess(resp.pointOfSale));
    console.log('edit resto');
    callback();
    // yield put(getBookingsSuccess(response.bookings));
  } catch (error) {
    console.log(error);
    callback();
    yield put(editRestaurantFailed(error));
  }
}

function* showRestaurant({ payload: { id, show } }) {
  try {
    const resp = yield call(showRestaurantsService, id, show);

    yield put(showRestaurantSuccess(resp.pointOfSale));

    // yield put(getBookingsSuccess(response.bookings));
  } catch (error) {
    // yield put(editRestaurantFailed(error));
  }
}

function* getRestaurantsClient({ payload: { idSuperVisor } }) {
  try {
    const resp = yield call(
      getRestaurantsClientService,
      idSuperVisor,
    );

    yield put(getRestaurantsClientSuccess(resp.pointOfSales));

    // yield put(getBookingsSuccess(response.bookings));
  } catch (error) {
    yield put(getRestaurantsClientFailed(error));
  }
}

export function* watchCallGetRestaurants() {
  yield takeEvery(GET_RESTAURANTS, getRestaurants);
}
export function* watchCallAddRestaurant() {
  yield takeEvery(ADD_RESTAURANT, addRestaurant);
}
export function* watchCallEditRestaurant() {
  yield takeEvery(EDIT_RESTAURANT, editRestaurant);
}
export function* watchCallAddRestaurantClient() {
  yield takeEvery(ADD_RESTAURANT_CLIENT, addRestaurantClient);
}
export function* watchCallShowRestaurant() {
  yield takeEvery(SHOW_RESTAURANT, showRestaurant);
}

export function* watchCallGetRestaurantsClient() {
  yield takeEvery(GET_RESTAURANTS_CLIENT, getRestaurantsClient);
}

function* RestaurantSaga() {
  yield all([
    fork(watchCallGetRestaurants),
    fork(watchCallAddRestaurant),
    fork(watchCallEditRestaurant),
    fork(watchCallAddRestaurantClient),
    fork(watchCallShowRestaurant),
    fork(watchCallGetRestaurantsClient),
  ]);
}

export default RestaurantSaga;
