// @flow
import {
  SETWEEKLYSCHEDULE,
  CALLWEEKLYSCHEDULE,
} from '../../constants/actionTypes';
import { REHYDRATE } from 'redux-persist';

const initialState = [];

const WeeklySchedule = (state = initialState, action) => {
  // console.log("action", action);
  switch (action.type) {
    case SETWEEKLYSCHEDULE:
      if (action.payload.days != undefined)
        return [...action.payload.days];
      return state;
    case CALLWEEKLYSCHEDULE:
      return state;
    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.WeeklySchedule,
          }
        : {
            ...state,
          };
    default:
      return state;
  }
};

export default WeeklySchedule;
