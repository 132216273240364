import { httpClient_get, httpClient_post } from '../../helpers/api';

const bookTableService = (data) => {
  return httpClient_post(`/bookings/book-table`, data)
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      throw error.response.data.message;
    });
};
const searchBookingsService = (word, pointOfSaleId) => {
  return httpClient_post(`/bookings/search`, { word, pointOfSaleId })
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
const getBookingsService = ({ date, pointOfSaleId }) => {
  // alert(pointOfSaleId)
  return httpClient_post(`/bookings`, { date, pointOfSaleId })
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getBookingService = (id) => {
  return httpClient_get(`/bookings/client/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export {
  bookTableService,
  getBookingsService,
  searchBookingsService,
  getBookingService,
};
