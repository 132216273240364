import {
  httpClient_get,
  httpClient_post,
  httpClient_put,
  httpClient_delete,
} from '../../helpers/api';

const getUsersService = (pointOfSaleId) => {
  return httpClient_post('/users/employees', { pointOfSaleId })
    .then((response) => {
      return response.data.staffs;
    })
    .catch((error) => {
      throw error;
    });
};

const addUserService = (user, pointOfSaleId) => {
  const { email, firstName, lastName, phone, password, role } = user;
  return httpClient_post('/users/employee', {
    email,
    firstName,
    lastName,
    phone,
    password,
    pointOfSaleId,
    role: role.value,
  })
    .then((response) => {
      console.log(response.data);
      return response.data.userEmployee;
    })
    .catch((error) => {
      throw error.response;
    });
};
const updateUserService = (user, pointOfSaleId) => {
  const { _id, email, firstName, lastName, phone, role } = user;

  return httpClient_put(`/users/${_id}/employee`, {
    email,
    firstName,
    lastName,
    phone,
    pointOfSaleId,
    role: role.value,
  })
    .then((response) => {
      return response.data.userStaff;
    })
    .catch((error) => {
      throw error;
    });
};

const deleteUserService = (user) => {
  return httpClient_delete(`/users/${user._id}/employee`)
    .then((response) => {
      return response.data.user;
    })
    .catch((error) => {
      throw error;
    });
};

export {
  addUserService,
  updateUserService,
  deleteUserService,
  getUsersService,
};
