import {
  httpClient_get,
  httpClient_post,
  httpClient_put,
} from '../../helpers/api';

const getProductCategoryService = (pointOfSaleId) => {
  return httpClient_post('/stock/product-category/get', {
    pointOfSaleId,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getCategoryWithProductService = (pointOfSaleId) => {
  return httpClient_post('/stock/category-with-product/get', {
    pointOfSaleId,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getIngredientService = (pointOfSaleId) => {
  return httpClient_post('/stock/ingredients/get', { pointOfSaleId })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getProductsService = (pointOfSaleId) => {
  return httpClient_post('/stock/products/get', { pointOfSaleId })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const createProductCategoryService = ({
  pointOfSaleId,
  categoryName,
  categoryType,
  unit,
  VATPurchase,
  VATSales,
  VATTakeaway,
  VATSaleToDeliver,
  subCategories,
}) => {
  return httpClient_post('/stock/product-category/create', {
    pointOfSaleId,
    categoryName,
    categoryType,
    unit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
    subCategories,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const editProductCategoryService = ({
  productCategoryId,
  categoryName,
  categoryType,
  unit,
  VATPurchase,
  VATSales,
  VATTakeaway,
  VATSaleToDeliver,
}) => {
  return httpClient_put('/stock/product-category/edit', {
    productCategoryId,
    categoryName,
    categoryType,
    unit,
    VATPurchase,
    VATSales,
    VATTakeaway,
    VATSaleToDeliver,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const createIngredientService = ({
  pointOfSaleId,
  ingredientName,
  ingredientType,
  sellUnit,
  purchaseUnit,
  VATPurchase,
  VATSales,
  VATTakeaway,
  VATSaleToDeliver,
  sellPrice,
  buyingPrice,
  productCategory,
  suppliers,
  image,
  stockInitial,
}) => {
  const formData = new FormData();

  formData.append('suppliers', JSON.stringify(suppliers));

  formData.append('ingredientName', ingredientName);
  formData.append('ingredientType', ingredientType);
  formData.append('sellUnit', sellUnit);
  formData.append('purchaseUnit', purchaseUnit);
  formData.append('VATPurchase', VATPurchase);
  formData.append('VATSales', VATSales);
  formData.append('VATTakeaway', VATTakeaway);
  formData.append('VATSaleToDeliver', VATSaleToDeliver);
  formData.append('sellPrice', sellPrice);
  formData.append('productCategory', productCategory);
  formData.append('buyingPrice', buyingPrice);
  formData.append('stockInitial', stockInitial);

  if (image) {
    formData.append('image', image);
  }
  if (pointOfSaleId) {
    formData.append('pointOfSaleId', pointOfSaleId);
  }

  return httpClient_post('/stock/ingredients/create', formData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const editProductService = ({
  productId,
  Itemtype,
  unit,
  VATPurchase,
  VATSales,
  VATTakeaway,
  VATSaleToDeliver,
  desc,
  details,
  extras,
  ingredients,
  purchaseTypes,
  preparationTime,
  price,
  productCategory,
  productNature,
  productType,
  image,
  suppliers,
  content,
  itemName,
}) => {
  const formData = new FormData();

  if (Itemtype && Itemtype !== null) {
    for (const it of Itemtype) {
      formData.append('Itemtype[]', it);
    }
  }

  formData.append('ingredients', JSON.stringify(ingredients));
  formData.append('purchaseTypes', JSON.stringify(purchaseTypes));
  formData.append('suppliers', JSON.stringify(suppliers));

  formData.append('productType', productType);
  formData.append('itemName', itemName);
  formData.append('unit', unit);
  formData.append('VATPurchase', VATPurchase);
  formData.append('VATSales', VATSales);
  formData.append('VATTakeaway', VATTakeaway);
  formData.append('VATSaleToDeliver', VATSaleToDeliver);
  formData.append('price', price);
  formData.append('productCategory', productCategory);
  formData.append('productNature', productNature);
  formData.append('preparationTime', preparationTime);
  formData.append('desc', desc);
  formData.append('details', details);
  formData.append('extras', extras);
  formData.append('content', content);

  if (image) {
    formData.append('image', image);
  }
  if (productId) {
    formData.append('productId', productId);
  }

  return httpClient_put('/stock/products/edit', formData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const editIngredientService = ({
  ingredientId,
  ingredientName,
  ingredientType,
  sellUnit,
  purchaseUnit,
  VATPurchase,
  VATSales,
  VATTakeaway,
  VATSaleToDeliver,
  sellPrice,
  buyingPrice,
  image,
  productCategory,
  suppliers,
}) => {
  const formData = new FormData();
  formData.append('suppliers', JSON.stringify(suppliers));

  formData.append('ingredientName', ingredientName);
  formData.append('ingredientType', ingredientType);
  formData.append('sellUnit', sellUnit);
  formData.append('purchaseUnit', purchaseUnit);
  formData.append('VATPurchase', VATPurchase);
  formData.append('VATSales', VATSales);
  formData.append('VATTakeaway', VATTakeaway);
  formData.append('VATSaleToDeliver', VATSaleToDeliver);
  formData.append('sellPrice', sellPrice);
  formData.append('productCategory', productCategory);
  formData.append('buyingPrice', buyingPrice);

  if (image) {
    formData.append('image', image);
  }
  if (ingredientId) {
    formData.append('ingredientId', ingredientId);
  }

  return httpClient_put('/stock/ingredients/edit', formData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const createProductService = ({
  pointOfSaleId,
  Itemtype,
  unit,
  VATPurchase,
  VATSales,
  VATTakeaway,
  VATSaleToDeliver,
  desc,
  details,
  extras,
  ingredients,
  purchaseTypes,
  preparationTime,
  price,
  productCategory,
  productNature,
  productType,
  image,
  suppliers,
  content,
  itemName,
  stockInitial,
}) => {
  const formData = new FormData();

  if (Itemtype && Itemtype !== null) {
    for (const it of Itemtype) {
      formData.append('Itemtype[]', it);
    }
  }

  formData.append('ingredients', JSON.stringify(ingredients));
  formData.append('purchaseTypes', JSON.stringify(purchaseTypes));
  formData.append('suppliers', JSON.stringify(suppliers));

  formData.append('productType', productType);
  formData.append('itemName', itemName);
  formData.append('unit', unit);
  formData.append('VATPurchase', VATPurchase);
  formData.append('VATSales', VATSales);
  formData.append('VATTakeaway', VATTakeaway);
  formData.append('VATSaleToDeliver', VATSaleToDeliver);
  formData.append('price', price);
  formData.append('productCategory', productCategory);
  formData.append('productNature', productNature);
  formData.append('preparationTime', preparationTime);
  formData.append('desc', desc);
  formData.append('details', details);
  formData.append('extras', extras);
  formData.append('content', content);
  formData.append('stockInitial', stockInitial);

  if (image) {
    formData.append('image', image);
  }
  if (pointOfSaleId) {
    formData.append('pointOfSaleId', pointOfSaleId);
  }

  return httpClient_post('/stock/products/create', formData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const set_product_visibility_in_pos = (productCategory) => {
  return httpClient_post('/stock/products-show-in-pos', {
    ...productCategory,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export {
  getProductCategoryService,
  getIngredientService,
  getProductsService,
  createProductCategoryService,
  createIngredientService,
  createProductService,
  editProductCategoryService,
  editProductService,
  editIngredientService,
  getCategoryWithProductService,
  set_product_visibility_in_pos,
};
