import { httpClient_get, httpClient_post } from '../../helpers/api';

const RetriveDaysWeekly = async (pointOfSaleId) => {
  const result = await httpClient_post(
    '/pointofsale/Get-weekly-schedule',
    {
      pointOfSaleId,
    },
  );
  return result;
};

const changeDaysWeekly = async (data, pointOfSaleId) => {
  const weeklyScheduleMonday = data?.find(
    (e) => e.dayName == 'monday',
  );
  const weeklyScheduleTuesday = data?.find(
    (e) => e.dayName == 'tuesday',
  );
  const weeklyScheduleWednesday = data?.find(
    (e) => e.dayName == 'wednesday',
  );
  const weeklyScheduleThursday = data?.find(
    (e) => e.dayName == 'thursday',
  );
  const weeklyScheduleFriday = data?.find(
    (e) => e.dayName == 'friday',
  );
  const weeklyScheduleSaturday = data?.find(
    (e) => e.dayName == 'saturday',
  );
  const weeklyScheduleSunday = data?.find(
    (e) => e.dayName == 'sunday',
  );
  // console.log('data sent to api', {
  //     weeklyScheduleMonday,
  //     weeklyScheduleTuesday,
  //     weeklyScheduleWednesday,
  //     weeklyScheduleFriday,
  //     weeklyScheduleSaturday,
  //     weeklyScheduleSunday
  // })
  const result = await httpClient_post(
    '/pointofsale/weekly-schedule',
    {
      weeklyScheduleMonday,
      weeklyScheduleTuesday,
      weeklyScheduleWednesday,
      weeklyScheduleThursday,
      weeklyScheduleFriday,
      weeklyScheduleSaturday,
      weeklyScheduleSunday,
      pointOfSaleId,
    },
  );
  return result;
};

export { RetriveDaysWeekly, changeDaysWeekly };
