import { REHYDRATE } from 'redux-persist';
import {
  SUP_ADMIN_EDIT_USERS,
  SUP_ADMIN_EDIT_USERS_FAILED,
  SUP_ADMIN_EDIT_USERS_SUCCESS,
  SUP_ADMIN_GET_USERS,
  SUP_ADMIN_GET_USERS_FAILED,
  SUP_ADMIN_GET_USERS_SUCCESS,
  SUP_ADMIN_ADD_SUPERVISOR,
  SUP_ADMIN_ADD_SUPERVISOR_SUCCESS,
  SUP_ADMIN_ADD_SUPERVISOR_FAILED,
  SUP_ADMIN_SEARCH_NOTIFICATION,
  SUP_ADMIN_SEARCH_NOTIFICATION_SUCCESS,
  SUP_ADMIN_SEARCH_NOTIFICATION_FAILED,
} from '../../../constants/actionTypes';
const INIT_STATE = {
  user: {},
  loading: false,
  error: false,
  users: [],
  notifications: [],
};

const SupAdminUsers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUP_ADMIN_GET_USERS:
      return { ...state, loading: true };
    case SUP_ADMIN_GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        error: null,
      };
    case SUP_ADMIN_GET_USERS_FAILED:
      return { ...state, error: action.payload, loading: false };

    // case ADD_USER:
    //   return { ...state, loading: true };
    // case ADD_USER_SUCCESS:
    //   return {
    //     ...state,
    //     users: [...state.users, action.payload],
    //     loading: false,
    //     error: null,
    //   };
    // case ADD_USER_FAILED:
    //   return { ...state, error: action.payload, loading: false };

    case SUP_ADMIN_EDIT_USERS:
      return { ...state, loading: true };
    case SUP_ADMIN_EDIT_USERS_SUCCESS:
      const newList = state.users.map((item) =>
        item._id === action.payload._id ? action.payload : item,
      );
      return {
        ...state,
        users: newList,
        loading: false,
        error: null,
      };
    case SUP_ADMIN_EDIT_USERS_FAILED:
      return { ...state, error: action.payload, loading: false };

    // case DELETE_USER:
    //   return { ...state, loading: true };
    // case DELETE_USER_SUCCESS:
    //   const List = state.users.filter(
    //     (item) => item._id !== action.payload._id
    //   );
    //   return { ...state, users: List, loading: false, error: null };
    // case DELETE_USER_FAILED:
    //   return { ...state, error: action.payload, loading: false };

    case SUP_ADMIN_ADD_SUPERVISOR:
      return { ...state, loading: true };
    case SUP_ADMIN_ADD_SUPERVISOR_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
        loading: false,
        error: null,
      };
    case SUP_ADMIN_ADD_SUPERVISOR_FAILED:
      return { ...state, error: action.payload, loading: false };

    case SUP_ADMIN_SEARCH_NOTIFICATION:
      return { ...state, loading: true };
    case SUP_ADMIN_SEARCH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        loading: false,
        error: null,
      };
    case SUP_ADMIN_SEARCH_NOTIFICATION_FAILED:
      return { ...state, error: action.payload, loading: false };

    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.Users,
          }
        : {
            ...state,
          };
    default:
      return { ...state };
  }
};

export default SupAdminUsers;
