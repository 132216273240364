import React, { useEffect, useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
// import swal from 'sweetalert';
import Select from 'react-select';
import Switch from 'react-switch';
import { reactFormatter } from 'react-tabulator';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import isEmail from 'validator/lib/isEmail';
import Loader from '../../components/Loader';
import TableList, { formatDate } from '../../components/TableList';
import {
  baseURL,
  httpClient_get,
  httpClient_post,
} from '../../helpers/api';
import { getRestaurants } from '../../redux/PointOfSale/actions';
import {
  addSupervisor,
  getUsersSuperAdmin,
  UpdateUser,
} from '../../redux/SupAdmin/users/actions';
import { primaryBookTableColor } from '../../theme/color';
import style from './style.module.scss';

import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

// const data  = [];
export const EditUser = ({
  show,
  handleClose,
  onEdite,
  user = {},
}) => {
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    control,
  } = useForm();
  const dispatch = useDispatch();
  const handleClose_ = () => handleClose(false);
  const [startDate, setStartDate] = useState(new Date());

  const { error, loading } = useSelector(
    (state) => state.SupAdminUsers,
  );
  const onSubmit = (data) => {
    dispatch(
      UpdateUser({ ...data, _id: user._id }, () => {
        handleClose(false);
      }),
    );
  };

  useEffect(() => {
    setValue('lastName', user.lastName);
    setValue('firstName', user.firstName);
    setValue('email', user.email);
    setValue('phone', user.phone);
    setValue('enabled', user.enabled);
  }, [user]);

  return (
    <Modal show={show} onHide={handleClose_}>
      <Modal.Header closeButton>
        <Modal.Title>Modification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <Loader />}
        <Form onSubmit={handleSubmit(onSubmit)} id="addUSerF">
          {error?.codeError === 'DUPLICATE_PHONE' && (
            <Alert color="danger">Phone is duplicated</Alert>
          )}
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label for="exampleEmail">FirstName :</Label>
              <Input
                type="text"
                placeholder="firstName"
                name="firstName"
                innerRef={register({ required: true })}
                {...(errors.firstName ? { invalid: true } : {})}
              />
              <FormFeedback
                {...(errors.firstName ? { invalid: 'true' } : {})}
              >
                {errors.firstName && 'firstName is required'}
              </FormFeedback>
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label for="examplePassword">LastName:</Label>
              <Input
                type="text"
                placeholder="lastName"
                name="lastName"
                innerRef={register({ required: true })}
                {...(errors.lastName ? { invalid: true } : {})}
              />
              <FormFeedback
                {...(errors.lastName ? { invalid: 'true' } : {})}
              >
                {errors.lastName && 'lastName is required'}
              </FormFeedback>
            </FormGroup>
          </div>

          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label for="exampleEmail">Email :</Label>
              <Input
                type="text"
                placeholder="email"
                name="email"
                innerRef={register({ required: true })}
                {...(errors.email ? { invalid: true } : {})}
              />
              <FormFeedback
                {...(errors.email ? { invalid: 'true' } : {})}
              >
                {errors.email && 'email  is required'}
              </FormFeedback>
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label for="examplePassword">Phone:</Label>

              <Controller
                control={control}
                name="phone"
                render={({ onChange, onBlur, value }) => (
                  <PhoneInput
                    // className="form-control "
                    style={{
                      display: 'flex',
                      flex: 3,
                      marginTop: 5,
                    }}
                    enableSearch
                    inputClass={'widthFull'}
                    country={'us'}
                    preferredCountries={['ma', 'us', 'fr']}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />

              <FormFeedback
                {...(errors.phone ? { invalid: 'true' } : {})}
              >
                {errors.phone && 'Phone is required'}
              </FormFeedback>
            </FormGroup>
          </div>
          <FormGroup check>
            <Input
              type="checkbox"
              name="enabled"
              innerRef={register({})}
            />
            <Label for="exampleCheck" check>
              enabled
            </Label>
          </FormGroup>
          {/* <div className="form-row">
              <FormGroup className="col-md-6" >
                <Label for="exampleEmail">password :</Label>
                <Input type={`${showPass_ ? '' : "password" }`}  placeholder="password"  name="password" innerRef={register({ required: true })} {...(errors.password ? {invalid:true} : {})}  />
                <FormFeedback {...(errors.password ? {invalid:'true'} : {})}>{errors.password && "password admin is required"}</FormFeedback>
                <i className="fas fa-eye" style={{position: 'absolute',right: '11px',marginTop: '-31px',padding:'6px'}} onClick={showPass}  ></i>

              </FormGroup>

            </div> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleClose(false);
          }}
        >
          Fermer
        </Button>
        <Button type="submit" form="addUSerF" variant="primary">
          Sauvegarder
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Users = () => {
  const loading = useSelector((state) => state.Users.loading);
  // const users_ = useSelector((state) => state.Users.users);

  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [show_edit, setShowEdit] = useState(false);
  const curUser = useState()[0];

  const editUser = (user) => {
    // const newList = users.map((item)=>
    //   item.key === user.key ?   user : item
    // )
    // setUsers(newList)
  };

  return (
    <>
      <div className="">
        {loading && <Loader />}
        <Row>
          <Col>
            <Card>
              <CardBody className="p-0">
                <DataTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export const Show = ({ show, handleClose, onEdite, user = {} }) => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const dispatch = useDispatch();
  const handleClose_ = () => handleClose(false);
  const [startDate, setStartDate] = useState(new Date());
  const [userState, setUserState] = useState(user);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user._id) {
      console.log('::::::____:::::', user);
      setLoading(true);
      httpClient_get(`/admin/users/${user._id}`)
        .then((res) => {
          setLoading(false);

          setUserState(res.data.user);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [user]);

  return (
    <Modal show={show} onHide={handleClose_}>
      {loading && <Loader />}

      <Modal.Header closeButton>
        <Modal.Title>Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="addUSerF">
          <FormGroup
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Label for="examplePassword">Avatar</Label>

            {userState?.avatar?.path ? (
              <img
                style={{
                  width: 60,
                  height: 60,
                  borderRadius: 60,
                }}
                src={baseURL + '/' + userState?.avatar?.path}
              />
            ) : (
              <Label for="examplePassword"> No Avatar</Label>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">
              country : {userState.country}
            </Label>
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">
              state : {userState.state}
            </Label>
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">
              city : {userState.city}{' '}
            </Label>
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">
              address : {userState.address}{' '}
            </Label>
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">
              facebook link :{' '}
              <a
                href={`https://facebook.com/${userState.facebookLink}`}
                target={'_blank'}
              >
                {userState.facebookLink}
              </a>
            </Label>
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">
              twitter link :{' '}
              <a
                href={`https://twitter.com/${userState.twitterLink}`}
                target={'_blank'}
              >
                {userState.twitterLink}
              </a>
            </Label>
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">
              instagram link :{' '}
              <a
                href={`https://instagram.com/${userState.instagramLink}`}
                target={'_blank'}
              >
                {userState.instagramLink}
              </a>
            </Label>
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">
              youtube link :{' '}
              <a
                href={`https://youtube.com/channel/${userState.youtubeLink}`}
                target={'_blank'}
              >
                {userState.youtubeLink}
              </a>
            </Label>
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">
              telegram link :{' '}
              <a
                href={`https://t.me/${userState.telegramLink}`}
                target={'_blank'}
              >
                {userState.telegramLink}
              </a>
            </Label>
          </FormGroup>

          <FormGroup>
            <Label for="examplePassword">
              email recovery : {userState.secondEmail}{' '}
            </Label>
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleClose(false);
          }}
        >
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AddAssistance = (props) => {
  const { show, handleClose } = props;
  const { register, handleSubmit, errors, control } = useForm();
  const dispatch = useDispatch();

  const [showPass_, setshowPass_] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const showPass = () => {
    setshowPass_(!showPass_);
  };

  const onSubmit = (data) => {
    setErrorEmail(false);

    if (isEmail(data.email)) {
      setLoading(true);
      const { email, firstName, lastName, phone, password } = data;
      console.log('data', {
        email,
        firstName,
        lastName,
        phone,
        password,
      });

      httpClient_post('/users/assistance', {
        email,
        firstName,
        lastName,
        phone,
        password,
      })
        .then((response) => {
          if (response.data.success) {
            setLoading(false);
            handleClose(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(`error`, error.response);
          setError(error?.response?.data?.message);
        });
    } else {
      setErrorEmail(true);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create an assistance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <Loader />}
        <Form onSubmit={handleSubmit(onSubmit)} id="addSupervisor">
          {error && (
            <Alert color="danger" isOpen={error ? true : false}>
              <div>error : {error}</div>
            </Alert>
          )}
          {errorEmail && (
            <Alert color="danger" isOpen={errorEmail ? true : false}>
              <div>Please enter a valid email</div>
            </Alert>
          )}
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label for="exampleEmail">first name :</Label>
              <Input
                type="text"
                placeholder="firstName"
                name="firstName"
                innerRef={register({ required: true })}
                {...(errors.firstName ? { invalid: true } : {})}
              />
              <FormFeedback
                {...(errors.firstName ? { invalid: 'true' } : {})}
              >
                {errors.firstName && 'firstName is required'}
              </FormFeedback>
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label for="examplePassword">last name:</Label>
              <Input
                type="text"
                placeholder="lastName"
                name="lastName"
                innerRef={register({ required: true })}
                {...(errors.lastName ? { invalid: true } : {})}
              />
              <FormFeedback
                {...(errors.lastName ? { invalid: 'true' } : {})}
              >
                {errors.lastName && 'last name is required'}
              </FormFeedback>
            </FormGroup>
          </div>

          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label for="exampleEmail">Email :</Label>
              <Input
                type="text"
                placeholder="email"
                name="email"
                innerRef={register({ required: true })}
                {...(errors.email ? { invalid: true } : {})}
              />
              <FormFeedback
                {...(errors.email ? { invalid: 'true' } : {})}
              >
                {errors.email && 'email is required'}
              </FormFeedback>
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label for="examplePassword">Phone:</Label>

              <Controller
                control={control}
                name="phone"
                render={({ onChange, onBlur, value }) => (
                  <PhoneInput
                    // className="form-control "
                    style={{
                      display: 'flex',
                      flex: 3,
                      marginTop: 5,
                    }}
                    enableSearch
                    inputClass={'widthFull'}
                    country={'us'}
                    preferredCountries={['ma', 'us', 'fr']}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />

              <FormFeedback
                {...(errors.phone ? { invalid: 'true' } : {})}
              >
                {errors.phone && 'Phone is required'}
              </FormFeedback>
            </FormGroup>
          </div>
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label for="exampleEmail">password :</Label>
              <Input
                type={`${showPass_ ? '' : 'password'}`}
                placeholder="password"
                name="password"
                innerRef={register({ required: true })}
                {...(errors.password ? { invalid: true } : {})}
              />
              <FormFeedback
                {...(errors.password ? { invalid: 'true' } : {})}
              >
                {errors.password && 'password  is required'}
              </FormFeedback>
              <i
                className="fas fa-eye"
                style={{
                  position: 'absolute',
                  right: '11px',
                  marginTop: '-31px',
                  padding: '6px',
                }}
                onClick={showPass}
              ></i>
            </FormGroup>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleClose(false);
          }}
        >
          Fermer
        </Button>

        <Button type="submit" form="addSupervisor" variant="primary">
          Sauvegarder
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AddSupervisor = (props) => {
  const { show, handleClose } = props;
  const { register, handleSubmit, errors, control } = useForm();
  const dispatch = useDispatch();

  const [showPass_, setshowPass_] = useState(false);
  const [enableRoomService, setEnableRoomService] = useState(true);
  const [showRoomService, setShowRoomService] = useState(false);
  const [options, setOptions] = useState([]);
  const [optionsRoomService, setOptionsRoomService] = useState([]);

  const pointOfSales = useSelector(
    (state) => state.PointOfSale.pointOfSales,
  );
  const SupAdminUsers = useSelector((state) => state.SupAdminUsers);

  useEffect(() => {
    dispatch(getRestaurants());
  }, []);

  useEffect(() => {
    if (pointOfSales.length > 0) {
      const optionsData = pointOfSales
        ?.filter((r) => r.category === 'restaurant')
        .map((r) => {
          return { value: r._id, label: `${r.title} (${r.email})` };
        });

      setOptions(optionsData);
      const optionsDataRoomService = pointOfSales
        ?.filter((r) => r.category === 'hotel')
        .map((r) => {
          return { value: r._id, label: `${r.title} (${r.email})` };
        });

      setOptionsRoomService(optionsDataRoomService);
    }
  }, [pointOfSales]);

  const showPass = () => {
    setshowPass_(!showPass_);
  };

  const onSubmit = (data) => {
    console.log('data', data, enableRoomService);
    const ids = [...data.pointOfSales?.map((r) => r.value)];
    if (data.room_service?.value) ids.push(data.room_service?.value);
    console.log('ids', ids);

    if (
      data.typeAccount.value === 'hotel' &&
      data.room_service === ''
    ) {
      dispatch({
        type: 'SHOWBOOKTABLEALERT',
        payload: {
          title: 'warning',
          body: ' room service is required',
          iClass: '',
          cancelable: false,
          asking: false,
          success: () => {
            onPress(index);
          },
          error: () => null,
        },
      });
      return;
    }
    if (ids.length > 0) {
      dispatch(
        addSupervisor(
          {
            pointOfSales: ids,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            password: data.password,
            typeAccount: data.typeAccount.value,
            enableRoomService,
          },
          handleClose,
        ),
      );
    } else {
      dispatch({
        type: 'SHOWBOOKTABLEALERT',
        payload: {
          title: 'warning',
          body:
            'Must choose at least one restaurant or room service ',
          iClass: '',
          cancelable: false,
          asking: false,
          success: () => {
            onPress(index);
          },
          error: () => null,
        },
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create an supervisor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {SupAdminUsers.loading && <Loader />}
        <Form onSubmit={handleSubmit(onSubmit)} id="addSupervisor">
          {SupAdminUsers.error && (
            <Alert
              color="danger"
              isOpen={SupAdminUsers.error ? true : false}
            >
              <div>error : {SupAdminUsers.error.message}</div>
            </Alert>
          )}
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label for="exampleEmail">first name :</Label>
              <Input
                type="text"
                placeholder="firstName"
                name="firstName"
                innerRef={register({ required: true })}
                {...(errors.firstName ? { invalid: true } : {})}
              />
              <FormFeedback
                {...(errors.firstName ? { invalid: 'true' } : {})}
              >
                {errors.firstName && 'firstName is required'}
              </FormFeedback>
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label for="examplePassword">last name:</Label>
              <Input
                type="text"
                placeholder="lastName"
                name="lastName"
                innerRef={register({ required: true })}
                {...(errors.lastName ? { invalid: true } : {})}
              />
              <FormFeedback
                {...(errors.lastName ? { invalid: 'true' } : {})}
              >
                {errors.lastName && 'last name is required'}
              </FormFeedback>
            </FormGroup>
          </div>

          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label for="exampleEmail">Email :</Label>
              <Input
                type="text"
                placeholder="email"
                name="email"
                innerRef={register({ required: true })}
                {...(errors.email ? { invalid: true } : {})}
              />
              <FormFeedback
                {...(errors.email ? { invalid: 'true' } : {})}
              >
                {errors.email && 'email is required'}
              </FormFeedback>
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label for="examplePassword">Phone:</Label>

              <Controller
                control={control}
                name="phone"
                render={({ onChange, onBlur, value }) => (
                  <PhoneInput
                    // className="form-control "
                    style={{
                      display: 'flex',
                      flex: 3,
                      marginTop: 5,
                    }}
                    enableSearch
                    inputClass={'widthFull'}
                    country={'us'}
                    preferredCountries={['ma', 'us', 'fr']}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />

              <FormFeedback
                {...(errors.phone ? { invalid: 'true' } : {})}
              >
                {errors.phone && 'Phone is required'}
              </FormFeedback>
            </FormGroup>
          </div>
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label for="exampleEmail">password :</Label>
              <Input
                type={`${showPass_ ? '' : 'password'}`}
                placeholder="password"
                name="password"
                innerRef={register({ required: true })}
                {...(errors.password ? { invalid: true } : {})}
              />
              <FormFeedback
                {...(errors.password ? { invalid: 'true' } : {})}
              >
                {errors.password && 'password  is required'}
              </FormFeedback>
              <i
                className="fas fa-eye"
                style={{
                  position: 'absolute',
                  right: '11px',
                  marginTop: '-31px',
                  padding: '6px',
                }}
                onClick={showPass}
              ></i>
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label for="exampleEmail">account type :</Label>
              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <Select
                    options={[
                      {
                        value: 'restaurant',
                        label: 'restaurant',
                      },
                      {
                        value: 'hotel',
                        label: 'hotel',
                      },
                      {
                        value: 'spa',
                        label: 'spa',
                      },
                    ]}
                    // isMulti
                    name="typeAccount"
                    // className="basic-multi-select"
                    classNamePrefix="select"
                    value={value}
                    onChange={(val) => {
                      if (val.value === 'hotel')
                        setShowRoomService(true);
                      else setShowRoomService(false);
                      onChange(val);
                    }}
                  />
                )}
                name={'typeAccount'}
                rules={{ required: false }}
                defaultValue={{
                  value: 'restaurant',
                  label: 'restaurant',
                }}
              />
            </FormGroup>
          </div>

          <div
            style={{
              borderTop: '1px solid #dee2e6',
              textAlign: 'center',
              fontSize: '27px',
            }}
          >
            Restaurants
          </div>
          <FormGroup className="col-md-12">
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <Select
                  options={options}
                  isMulti
                  name="pointOfSales"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={value}
                  onChange={onChange}
                />
              )}
              name={'pointOfSales'}
              rules={{ required: false }}
              defaultValue={[]}
            />
            {errors.pointOfSales && (
              <span
                style={{
                  color: 'red',
                }}
              >
                pointOfSales is required
              </span>
            )}
          </FormGroup>

          {showRoomService && (
            <>
              <div
                style={{
                  borderTop: '1px solid #dee2e6',
                  textAlign: 'center',
                  fontSize: '27px',
                }}
              >
                Room Service
              </div>
              <FormGroup className="col-md-12">
                <Controller
                  control={control}
                  render={({ onChange, onBlur, value }) => (
                    <Select
                      options={optionsRoomService}
                      // isMulti
                      name="pointOfSales"
                      // className="basic-multi-select"
                      classNamePrefix="select"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  name={'room_service'}
                  rules={{ required: false }}
                  defaultValue={''}
                />
              </FormGroup>
              <div className="form-row">
                <FormGroup className="col-md-6">
                  <Label for="exampleEmail">
                    activer room service :
                  </Label>
                  <Switch
                    onColor={primaryBookTableColor}
                    offColor="#000000"
                    name={'enableRoomService'}
                    onChange={setEnableRoomService}
                    checked={enableRoomService}
                  />
                </FormGroup>
              </div>
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleClose(false);
          }}
        >
          Fermer
        </Button>

        <Button type="submit" form="addSupervisor" variant="primary">
          Sauvegarder
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Menu = ({
  cell,
  setShowInfo = () => null,
  setShowEdit = () => null,
  setUser = () => null,
  sedEmail = () => null,
}) => {
  const rowData = cell._cell.row.data;

  if (cell._cell.element)
    cell._cell.element.style.overflow = 'visible';

  return (
    <Dropdown drop={'left'}>
      <Dropdown.Toggle className="btn-not-background">
        <i className=" fas fa-cogs"></i>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          eventKey="1"
          onClick={() => {
            setUser(rowData);
            setShowEdit(true);
          }}
        >
          <i className="fas fa-edit"></i> edit
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="2"
          onClick={() => {
            setUser(rowData);
            setShowInfo(true);
          }}
        >
          <i className="far fa-eye"></i> show
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="2"
          onClick={() => {
            sedEmail(rowData.email);
          }}
        >
          <i className="far  fa-paper-plane"></i> reset password
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const ListRsto = ({ cell, getREsto }) => {
  const rowData = cell._cell.row.data;

  const res = getREsto(rowData);

  return <>{res}</>;
};

const AffiliateUserLine = ({ cell }) => {
  const rowData = cell._cell.row.data;

  if (rowData?.affiliateUser) {
    return <>{rowData?.affiliateUser?.email}</>;
  } else {
    return <>-</>;
  }
};

const LastLoginLine = ({ cell }) => {
  const rowData = cell._cell.row.data;
  const lastLogin = rowData?.lastLogin;

  if (lastLogin.length > 0) {
    return (
      <>
        {moment(lastLogin[lastLogin.length - 1]?.createdAt).format(
          'DD-MM-YYYY HH:mm',
        )}
      </>
    );
  } else {
    return <>-</>;
  }
};

const ShowFlagCountry = ({ cell }) => {
  const rowData = cell._cell.row.data.createdFromCountry
    ? cell._cell.row.data.createdFromCountry.toLowerCase()
    : false;

  if (rowData) {
    return (
      <img
        src={`https://flagcdn.com/w80/${rowData}.png`}
        srcSet={'https://flagcdn.com/w160/' + rowData + '.png 2x'}
        width="40"
        height="25"
        alt={rowData}
      />
    );
  } else {
    return (
      <img
        src={`https://flagcdn.com/w80/un.png`}
        srcSet={'https://flagcdn.com/w160/un.png 2x'}
        width="40"
        height="25"
        alt="un"
      />
    );
  }
};

function customHeaderFilter(
  headerValue,
  rowValue,
  rowData,
  filterParams,
) {
  const res = rowValue.filter((elem) =>
    elem.title.toLowerCase().includes(headerValue.toLowerCase()),
  );

  console.log('-----rowValue-----', res);

  // return rowValue.includes(elm=>elm.title?.toString().includes(headerValue.toString()));
  // return rowData.name == filterParams.name && rowValue < headerValue; //must return a boolean, true if it passes the filter.

  return res.length > 0;
}

const DataTable = () => {
  // const [comments, setComments] = useState([]);
  // const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAdd, setShowAdd] = useState(false);
  const [showAddAssistance, setShowAddAssistance] = useState(false);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [showEdit, setShowEdit] = useState(false);
  const [user, setUser] = useState({});
  const [selectedRole, setSelectedRole] = useState('ALL');
  const [showInfo, setShowInfo] = useState(false);
  const users = useSelector((state) => state.SupAdminUsers.users);
  // const pointOfSales = useSelector((state) => state.PointOfSale.pointOfSales);

  const dispatch = useDispatch();
  const ITEMS_PER_PAGE = 50;

  const getNameRole = (name) => {
    const list = {
      ROLE_ADMIN: 'admin',
      ROLE_SUPERVISOR: 'supervisor',
      ROLE_STAFF: 'staff',
      ROLE_MANAGER: 'manager',
      ROLE_SUPER_ADMIN: 'super admin',
      ROLE_USER: 'user',
      ROLE_ASSISTANCE: 'assistance',
    };

    return list[name];
  };

  const getREsto = (user, text = false) => {
    let list = [];

    if (user.role === 'ROLE_ADMIN') {
      list = user.pointofsale?.map((elem, index) => {
        return elem.title;
      });
    } else if (user.role === 'ROLE_SUPERVISOR') {
      list = user.pointofsale?.map((elem, index) => {
        return elem.title;
      });
    } else if (user.role === 'ROLE_STAFF') {
      list = user.pointofsale?.map((elem, index) => {
        return elem.title;
      });
    } else if (user.role === 'ROLE_MANAGER') {
      list = user.pointofsale?.map((elem, index) => {
        return elem.title;
      });
    }

    if (!text)
      return list?.map((elm) => {
        return (
          <span className={[style.label_resto, 'badge'].join(' ')}>
            {elm}
          </span>
        );
      });

    return list?.join('');
  };

  const sedEmail = (email) => {
    httpClient_post(`/users/reset-password-for-admin`, {
      email: email,
    });
  };

  const headers = [
    {
      title: '',
      field: 'lastName',
      width: 150,
      headerHozAlign: 'left',
      headerFilter: 'input',
      headerFilterPlaceholder: 'lastName',
    },
    {
      title: '',
      field: 'firstName',
      headerHozAlign: 'left',
      headerFilter: 'input',
      headerFilterPlaceholder: 'firstName',
    },
    {
      title: '',
      field: 'email',
      headerHozAlign: 'left',
      headerFilter: 'input',
      headerFilterPlaceholder: 'email',
    },
    {
      title: '',
      field: 'phone',
      headerHozAlign: 'left',
      headerFilter: 'input',
      headerFilterPlaceholder: 'phone',
    },
    {
      title: '',
      field: 'role',
      headerHozAlign: 'left',
      editor: 'select',
      // editorParams:{values:{"male":"Male", "female":"Female"}},
      headerFilterPlaceholder: 'role',
      headerFilter: true,
      headerFilterParams: {
        values: {
          ROLE_ADMIN: 'admin',
          ROLE_SUPERVISOR: 'supervisor',
          ROLE_STAFF: 'staff',
          ROLE_MANAGER: 'manager',
          ROLE_SUPER_ADMIN: 'super admin',
          ROLE_USER: 'user',
          ROLE_ASSISTANCE: 'assistance',
        },
      },
    },
    // {
    //     title: '',
    //     field: 'pointofsale',
    //     headerHozAlign: 'left',
    //     headerFilter: 'input',
    //     headerFilterPlaceholder: 'rest',
    //     headerFilterFunc: customHeaderFilter,
    //     formatter: reactFormatter(<ListRsto getREsto={getREsto}/>),
    // },
    {
      title: '',
      field: 'country',
      headerHozAlign: 'left',
      headerFilter: 'input',
      headerFilterPlaceholder: 'country',
    },
    {
      title: '',
      field: 'createdFromCountry',
      headerHozAlign: 'left',
      headerFilter: 'input',
      headerFilterPlaceholder: 'createdFromCountry',
      formatter: reactFormatter(<ShowFlagCountry />),
    },
    // {
    //     title: '',
    //     field: 'nbr_booking',
    //     headerHozAlign: 'left',
    //     headerFilter: 'input',
    //     headerFilterPlaceholder: 'nbr_booking',
    // },
    {
      title: '',
      field: 'createAt',
      headerHozAlign: 'left',
      formatter: formatDate,
      headerFilter: 'input',
      headerFilterPlaceholder: 'createAt',
    },
    {
      title: '',
      field: 'rewardAffiliated',
      headerHozAlign: 'left',
      // formatter: formatDate,
      headerFilter: 'input',
      headerFilterPlaceholder: 'rewardAffiliated',
    },
    {
      title: '',
      field: 'stellarPubAddress',
      headerHozAlign: 'left',
      // formatter: formatDate,
      headerFilter: 'input',
      headerFilterPlaceholder: 'stellarPubAddress',
    },

    {
      title: '',
      field: 'createdFromAddressIP',
      headerHozAlign: 'left',
      headerFilter: 'input',
      headerFilterPlaceholder: 'Address IP',
    },
    // {
    //     title: '',
    //     field: 'affiliateUser',
    //     headerHozAlign: 'left',
    //     headerFilter: 'input',
    //     headerFilterPlaceholder: 'Affiliate User',
    //     // headerFilterFunc: customHeaderFilter,
    //     formatter: reactFormatter(<AffiliateUserLine />),
    // },

    // {
    //     title: '',
    //     field: 'lastLogin',
    //     headerHozAlign: 'left',
    //     formatter: reactFormatter(<LastLoginLine />),
    //     headerFilter: 'input',
    //     headerFilterPlaceholder: 'lastLogin',
    // },

    // {
    //     title: '',
    //     field: 'lastLogin',
    //     headerHozAlign: 'left',
    //     headerFilter: 'input',
    //     headerFilterPlaceholder: 'lastLogin',
    //     // formatter: formatDate,
    //     formatter: reactFormatter(<LastLoginLine />),
    // },
    {
      title: 'Action',
      field: '',
      width: '86',
      formatter: reactFormatter(
        <Menu
          setShowInfo={setShowInfo}
          setShowEdit={setShowEdit}
          setUser={setUser}
          sedEmail={sedEmail}
        />,
      ),
      headerHozAlign: 'left',
    },
  ];

  useEffect(() => {
    dispatch(getUsersSuperAdmin());
  }, []);

  return (
    <>
      {showEdit && (
        <EditUser
          show={showEdit}
          handleClose={() => {
            setShowEdit(false);
          }}
          onEdite={() => {}}
          user={user}
        />
      )}
      <Show
        show={showInfo}
        handleClose={() => {
          setShowInfo(false);
        }}
        onEdite={() => {}}
        user={user}
      />

      <AddSupervisor
        show={showAdd}
        handleClose={() => {
          setShowAdd(false);
        }}
      />

      <AddAssistance
        show={showAddAssistance}
        handleClose={() => {
          setShowAddAssistance(false);
        }}
      />

      <div className="row w-100">
        <div className="col mb-5 col-12 text-center">
          <div className="row  mb-2">
            <div className="col-md-6 d-flex justify-content-start align-items-center">
              {/* <label className="ml-2">Total : {totalItems}</label> */}
            </div>

            <div className="col-md-6 d-flex justify-content-end ">
              <div className="mr-2">
                <Button
                  color="primary"
                  variant="warning"
                  onClick={() => setShowAdd(true)}
                >
                  <i className="fas fa-user-plus">
                    {' '}
                    create supervisor
                  </i>
                </Button>
              </div>
              <div className="mr-2">
                <Button
                  color="secondary"
                  variant="warning"
                  onClick={() => setShowAddAssistance(true)}
                >
                  <i className="fas fa-user-plus">
                    {' '}
                    create assistance
                  </i>
                </Button>
              </div>
            </div>
          </div>
          <div
            className="col mb-3 col-12 text-center"
            style={{ height: 'calc(100vh - 200px)' }}
          >
            <TableList columns_p={headers} data_p={users} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
