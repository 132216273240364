// @flow
import {
  GET_ADMIN_ALL_COMPANY,
  ADD_ADMIN_COMPANY,
  GET_ADMIN_ALL_COMPANY_FAILED,
  GET_ADMIN_ALL_COMPANY_SUCCESS,
} from '../../../constants/actionTypes';

export const getAllCompany = () => ({
  type: GET_ADMIN_ALL_COMPANY,
  payload: {},
});

export const getAllCompanySuccess = (list) => ({
  type: GET_ADMIN_ALL_COMPANY_SUCCESS,
  payload: list,
});

export const getAllCompanyFailed = (error) => ({
  type: GET_ADMIN_ALL_COMPANY_FAILED,
  payload: error,
});

export const addComapnyAction = (company) => ({
  type: ADD_ADMIN_COMPANY,
  payload: company,
});
