import {
  ADD_COMMEND_CLICK_COLLECT,
  ADD_COMMEND_CLICK_COLLECT_FAILED,
  ADD_COMMEND_CLICK_COLLECT_SUCCESS,
  GET_CLICK_COLLECT_TIME,
  GET_CLICK_COLLECT_TIME_FAILED,
  GET_CLICK_COLLECT_TIME_SUCCESS,
} from '../../constants/actionTypes';

export const getTime = (pointOfSaleId) => ({
  type: GET_CLICK_COLLECT_TIME,
  payload: { pointOfSaleId },
});

export const getTimeSuccess = (list) => ({
  type: GET_CLICK_COLLECT_TIME_SUCCESS,
  payload: list,
});

export const getTimeFailed = (error) => ({
  type: GET_CLICK_COLLECT_TIME_FAILED,
  payload: error,
});

export const addCommendClickCollect = () => ({
  type: ADD_COMMEND_CLICK_COLLECT,
  payload: {},
});

export const addCommendClickCollectSuccess = (command) => ({
  type: ADD_COMMEND_CLICK_COLLECT_SUCCESS,
  payload: command,
});

export const addCommendClickCollectFailed = (error) => ({
  type: ADD_COMMEND_CLICK_COLLECT_FAILED,
  payload: error,
});
